import { Col, Skeleton, Row, Select, Form, Input, Button, Divider, Typography, Empty, Image, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import CAxios from "./CAxios";
import Modal from "antd/lib/modal/Modal";
import cookie from "react-cookies";

import { toAddOrUpdateArticle, toGetArticlesList, toGetArticlesName, toGetSearchArticlesList, toUpdateArticleName } from "../Pages/Gestions/Api";
import { customFilter, errorMsg, successMsg, toFormatArticle, toFormatArticleClassifications } from "./Utils";
import ArticleDetail from "../Pages/Gestions/ArticleDetail";
import ls from "localstorage-ttl";
import ArticleModal from "../Pages/Gestions/ArticleModal";
import Article from "../Models/Article";
import { DataExistsException } from "../DataExitsException";
import TecdocLiaison from "../Pages/Gestions/TecdocLiaison";
import ArticleSearch from "../Components/ArticleSearch";

const Tecdoc = ({ achat, displayTitle, addFromTecdoc, addItem, reference }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [detailVisble, setDetailVisible] = useState(false);
  const [articleDisplay, setArticleDisplay] = useState("");

  const [tecdocLiaison, setTecdocLiaison] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [articleToUpdate, setarticleToUpdate] = useState(null);

  const [articlesTecdoc, setArticlesTecdoc] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesTecdocAll, setArticlesTecdocAll] = useState([]);
  const [articlesAll, setArticlesAll] = useState([]);
  const [fromTecdoc, setFromTecdoc] = useState();
  const [loading, setLoading] = useState(false);
  const [displayBigResult, setDisplayBigResult] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [brands, setBrands] = useState([]);
  const [groups, setGroups] = useState([]);
  const [origines, setOrigines] = useState([]);
  const [details, setDetails] = useState([]);
  const [article, setArticle] = useState(null);
  const [image, setImage] = useState("");
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [page, setPage] = useState(0);
  const [focused, setFocused] = useState(0);
  const [pageTecdoc, setPageTecdoc] = useState(1);
  const [articlesNames, setArticleNames] = useState([]);
  const [articlesNamesAll, setArticleNamesAll] = useState([]);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const onSearchTecdoc = (value) => {
    form.setFieldsValue({ searchWord: "" });
    setFromTecdoc(true);

    setLoading(true);
    setArticlesTecdoc([]);
    setArticlesTecdocAll([]);
    toGetArticlesTecdoc(value, "", 1);
    setDisplayBigResult(false);
  };

  useEffect(() => {
    if (displayTitle) {
      if (null != ls.get("articles")) {
        var articles = ls.get("articles");
        setArticles(articles);
        setArticlesAll(articles);
      }
      toGetArticlesList(0).then((data) => {
        setFromTecdoc(false);
        setArticles(data);
        setArticlesAll(data);
        setArticlesTecdocAll([]);
        setArticlesTecdoc([]);
        setDisplayBigResult(false);
      });
    }

    if (null != ls.get("articles_name")) {
      setArticleNames(ls.get("articles_name"));
      setArticleNamesAll(ls.get("articles_name"));
    }
    toGetArticlesName().then((res) => {
      setArticleNames(res);
      setArticleNamesAll(res);
    });
  }, [reference]);

  const onSearch = (value, ref) => {
    if (ref) {
      form.setFieldsValue({ searchWord: "" });
    } else {
      form.setFieldsValue({ searchAvancée: "" });
    }
    if (form.getFieldValue("searchWord") == "Tout") {
      toGetArticlesList(0).then((data) => {
        setPage(0);
        setFromTecdoc(false);
        setArticles(data);
        setArticlesAll(data);
        setArticlesTecdocAll([]);
        setArticlesTecdoc([]);
        setDisplayBigResult(false);
        setFirstSearch(false);
        setLoading(false);
      });
      return;
    }
    form.setFieldsValue({ searchWordTecdoc: "" });
    setFromTecdoc(false);
    setArticlesTecdocAll([]);
    setDisplayBigResult(false);
    setArticlesTecdoc([]);
    if (value.length == 1) {
      errorMsg("Veuillez entrer 2 caractères merci!");
    } else {
      setLoading(true);
      toGetArticles(value, ref);
    }
  };

  const toCloseModal = () => {
    setDetailModalVisible(false);
    setArticle(null);
    setOrigines([]);
    setDetails([]);
    setImage("");
    setLoadingModal(false);
  };
  const toGetArticleDetails = async (article) => {
    setLoadingModal(true);
    setDetailModalVisible(true);
    const { data } = await CAxios.post(`/api/tecdoc/detail`, {
      code: article.code,
    });
    var details = toFormatArticleClassifications(article);
    setArticle(article);
    console.log(details);
    setDetails(details);

    setOrigines(data);
    // setDetails(data.details);
    // setImage(data.docId);
    setLoadingModal(false);
  };

  const toGetArticles = async (search, ref) => {
    console.log(search);
    if (search == "") {
      //todo change this in react
      toGetArticlesList(0).then((data) => {
        setPage(0);
        setFromTecdoc(false);
        setArticles(data);
        setArticlesAll(data);
        setArticlesTecdocAll([]);
        setArticlesTecdoc([]);
        setDisplayBigResult(false);
        setFirstSearch(false);
        setLoading(false);
      });
    } else {
      console.log(search, ref);
      toGetSearchArticlesList(search, ref).then((res) => {
        setArticles(res);
        setArticlesAll(res);
        setFirstSearch(true);
        setLoading(false);
      });
    }
  };

  const afterBind = (j, json) => {
    const index = articles.findIndex((a) => a.id === json.id);
    let newArticles = [...articles];
    newArticles[index].data = json.info;
    newArticles[index].titre = json.name;
    newArticles[index].b__tecdoc = json.referenceTecdoc == "" ? false : true;
    newArticles[index].code = json.code;
    setArticles(newArticles);

    setTecdocLiaison(false);
  };

  const goNext = () => {
    setLoading(true);

    toGetArticlesList(page + 1).then((data) => {
      setPage(page + 1);
      setFromTecdoc(false);
      setArticles(data);
      setArticlesAll(data);
      setArticlesTecdocAll([]);
      setArticlesTecdoc([]);
      setDisplayBigResult(false);
      setFirstSearch(false);
      setLoading(false);
    });
  };

  const goBack = () => {
    setLoading(true);

    toGetArticlesList(page - 1).then((data) => {
      setPage(page - 1);
      setFromTecdoc(false);
      setArticles(data);
      setArticlesAll(data);
      setArticlesTecdocAll([]);
      setArticlesTecdoc([]);
      setDisplayBigResult(false);
      setFirstSearch(false);
      setLoading(false);
    });
  };

  const searchAvancer = (e) => {
    var search = e.target.value;
    if (search == "") {
      //todo change this in react
      setArticles(articlesAll);
    } else {
      const updatedList = customFilter(search, articlesAll, ["s__secondTitle", "s__reference", "s__barCode"]);

      setArticles(updatedList);
    }
  };

  const toGetArticlesTecdoc = async (search, brandId, pagenb) => {
    setFromTecdoc(true);
    setLoading(true);

    const { data } = await CAxios.post(`/api/tecdoc/all`, {
      search: search,
      page: pagenb,
      brandId: brandId,
    });
    if (brandId == "") {
      form.setFieldsValue({ marque: "Tout", groupe: "Tout" });
    }
    if (data.status && data.status == 413) {
      setDisplayBigResult(true);
    }
    setArticlesTecdoc(data.articles);
    setArticlesTecdocAll(data.articles);
    setPageTecdoc(pagenb);

    if (data.brands.length > 0 && brandId == "") {
      setBrands(data.brands);
      //setGroups(data.groups);
    }
    setLoading(false);
  };

  const onChangeGroup = (e) => {
    if (e == "Tout") {
      setArticlesTecdoc(articlesTecdocAll);
    } else {
      var newArray = articlesTecdocAll.filter(function (item) {
        return item.articleName == e;
      });

      setArticlesTecdoc(newArray);
    }
  };

  const onChangeMarque = (e) => {
    if (e == "Tout") {
      toGetArticlesTecdoc(form.getFieldValue("searchWordTecdoc"), "", 1);
    } else {
      var newArray = brands.filter(function (item) {
        return item.name == e;
      });

      toGetArticlesTecdoc(form.getFieldValue("searchWordTecdoc"), newArray[0].query.query.value, 1);
    }
  };

  const onClickOrigine = (value) => {
    onSearchTecdoc(value);
    form.setFieldsValue({ searchWordTecdoc: value });
    toCloseModal();
  };

  const toDisplayArticle = (article) => {
    setArticleDisplay({
      id: article.id,
      titre: article.s__firstTitle,
      titre2: article.s__secondTitle,
      reference: article.s__reference,
      code: article.s__barCode,
      type: article.s__type,
      unite: article.unite,
      marque: article.marqueTitle,
      categorie: article.categoryTitle,
      emplacement: article.emplacement,
      seuil: article.l__seuil,
      tva: article.tva,
      prixAchatHT: article.l__prixAchatHT,
      prixAchatTTC: article.l__prixAchatTTC,

      marge: article.l__marge,
      margeValue: article.l__margeValue,
      prixVenteHT: article.l__prixVenteHT,
      prixVenteTTC: article.l__prixVenteTTC,

      margeGros: article.l__margeGros,
      margeGrosValue: article.l__margeGrosValue,
      prixVenteGrosHT: article.l__prixVenteGrosHT,
      prixVenteGrosTTC: article.l__prixVenteGrosTTC,
      data: article.data,
      b__tecdoc: article.b__tecdoc,
    });
    setDetailVisible(true);
  };

  const toAdd = () => {
    setArticleDetails(new Article());
    setCreateModalVisible(true);
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      setArticles([newArticle, ...articles]);
      successMsg("Article créé avec succés");

      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }

    // toAddOrUpdateArticle(id, article).then((newArticle) => {
    //   setArticles([newArticle, ...articles]);
    //   successMsg("Article créé avec succés");

    //   setCreateModalVisible(false);
    // });
  };

  const updateArticle = (display, article) => {
    setDetailVisible(false);
    var newArticles = [...articles];
    var index = newArticles.findIndex((a) => a.id == article.id);

    newArticles[index].data = article.data;
    newArticles[index].b__tecdoc = article.b__tecdoc;
    newArticles[index].titre = article.titre;
    setArticles(newArticles);
  };

  const sortInputFirst = (input, data) => {
    var first = [];
    var others = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].toUpperCase().indexOf(input.toUpperCase()) >= 0) {
        if (data[i].toUpperCase().indexOf(input.toUpperCase()) == 0) {
          first.push(data[i]);
        } else {
          others.push(data[i]);
        }
      }
    }
    first.sort();
    others.sort();

    setArticleNames(first.concat(others));
  };

  const updateArticleName = (e) => {
    if (e.key == "Enter") {
      toUpdateArticleName(e.target.value, articleToUpdate.id).then((res) => {
        successMsg("Nom article changé avec succées");
        var articlesList = [...articles];
        articlesList.forEach((element) => {
          if (element.id == articleToUpdate.id) {
            element.s__secondTitle = e.target.value;
          }
        });
        setArticles(articlesList);
        setarticleToUpdate(null);
      });
    }
  };

  return (
    <div id={achat ? "achat" : "vente"}>
      {!displayTitle && <Typography.Title level="2">Tecdoc</Typography.Title>}
      

      <Form form={form} name="basic" style={{ padding: 10 }} initialValues={{ remember: true }}>
        {/* {(cookie.load('id') == 8 || cookie.load('id') == 18 || cookie.load('id') == 1) &&(
        <Row gutter={24}>
          <Col md={7}>
            <ArticleSearch placeholder={"Recherche par désignation..."} type={0} onClick={(value) => onSearch(value, true)}/>
          </Col>
          <Col md={7}>
            <ArticleSearch placeholder={"Recherche par référence..."} type={1} onClick={(value) => onSearch(value, true)}/>
          </Col>

        </Row>)} */}
        <Row gutter={24}>
           {null != displayTitle && displayTitle && (
            <Col md={8}>
              <Form.Item style={{ marginBottom: 0 }} name="searchWord">
                {/* <Input onInput={(e) => toGetArticles(e.target.value, false)} placeholder="Recherche..." enterButton /> */}
                {/* <Select
                  dropdownMatchSelectWidth={600}
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ width: "100%" }}
                  onChange={(e) => onSearch(e, false)}
                  onSearch={(e) => sortInputFirst(e, articlesNamesAll)}
                  placeholder="Recherche par désignation..."
                >
                  <Option value="Tout">Tout</Option>

                  {articlesNames.map((name) => (
                    <Option value={name}>{name}</Option>
                  ))}
                </Select> */}
                <ArticleSearch onFocus={(e) => setFocused(0)} focused={focused} placeholder={"Recherche par désignation..."} type={0} onClick={(value) => onSearch(value, true)}/>

              </Form.Item>
            </Col>)}
          

          <Col md={7}>
            <Form.Item style={{ marginBottom: 0 }} name="searchAvancée">
              {/* <Input.Search onSearch={(e) => onSearch(e, true)} placeholder="Rec référence..." enterButton /> */}
              <ArticleSearch onFocus={(e) => setFocused(1)} focused={focused} placeholder={"Recherche par référence..."} type={1} onClick={(value) => onSearch(value, true)}/>

            </Form.Item>
          </Col> 

          <Col md={7}>
            <Form.Item style={{ marginBottom: 0 }} name="searchWordTecdoc">
              <Input.Search onFocus={() =>setFocused(2)} onSearch={onSearchTecdoc} placeholder="Rec tecdoc..." enterButton />
            </Form.Item>
          </Col>

          <Col md={2}>
            <Tooltip title="Pour créer un nouveau article" color="#58c5cc" placement="leftTop">
              <img src="/images/plusVente.svg" onClick={() => toAdd()} style={{ width: "5vh", cursor: "pointer" }} />
            </Tooltip>
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: "0.5vh" }}>
          {articlesTecdoc.length > 0 && (
            <>
              <Col md={12}>
                <Form.Item label="Marque" name="marque" style={{ marginBottom: 0 }}>
                  <Select showSearch onChange={(e) => onChangeMarque(e)}>
                    <Option value="Tout">Tout</Option>
                    {brands &&
                      brands.map((brand, j) => (
                        <Option value={brand.name} key={j}>
                          {brand.name + "(" + brand.count + ")"}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Groupe" name="groupe" style={{ marginBottom: 0 }}>
                  <Select showSearch onChange={(e) => onChangeGroup(e)}>
                    <option value="Tout">Tout</option>
                    {groups.map((group, j) => (
                      <Option value={group.group} key={j}>
                        {group.group + "(" + group.number + ")"}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
      <Col span="24" style={{ minHeight: !displayTitle && "30vh" }}>
        {displayBigResult && <Empty description="La recherche contient beaucoup de résultats. Merci d'affiner votre recherche!"></Empty>}
        {!loading && articlesTecdoc.length == 0 && fromTecdoc && <Empty description="Aucun article disponible"></Empty>}
        {!loading && articles.length == 0 && !fromTecdoc && <Empty description="Aucun article disponible"></Empty>}

        <div className="table-wrapper auto--overflow  ">
          {fromTecdoc && <Skeleton active loading={loading} />}

          {null != articlesTecdoc && articlesTecdoc.length > 0 && (
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Référence</th>
                  <th className="ant-table-cell">Article</th>
                  <th className="ant-table-cell">Marque</th>
                  <th className="ant-table-cell">Prix U TTC</th>
                  <th className="ant-table-cell">Stock</th>
                  <th className="ant-table-cell">
                    <Row justify="end" style={{ display: "inline-block" }}>
                      <Tooltip title="Page précédente" color="#2db7f5">
                        <Button
                          size="large"
                          disabled={pageTecdoc == 1}
                          onClick={() => toGetArticlesTecdoc(form.getFieldValue("searchWordTecdoc"), "", pageTecdoc - 1)}
                          icon={<LeftOutlined />}
                          type="link"
                        ></Button>
                      </Tooltip>

                      <Tooltip title="Page suivante" color="#2db7f5">
                        <Button
                          size="large"
                          disabled={articlesTecdocAll.length < 100}
                          onClick={() => toGetArticlesTecdoc(form.getFieldValue("searchWordTecdoc"), "", pageTecdoc + 1)}
                          icon={<RightOutlined />}
                          type="link"
                        ></Button>
                      </Tooltip>
                    </Row>
                  </th>
                </tr>
              </thead>

              <tbody className="ant-table-tbody">
                {!loading &&
                  fromTecdoc &&
                  null != articlesTecdoc &&
                  articlesTecdoc.length > 0 &&
                  articlesTecdoc.map((element, i) => (
                    <tr key={i} className={element.inStock ? "inStock" : i % 2 != 0 && "auto--background-grey"}>
                      <td className="ant-table-cell">
                        <b style={{ color: "#2696d3", fontSize: "1rem" }}>{element.inStock ? element.data.s__reference : element.ref}</b>
                      </td>
                      <td className="ant-table-cell">
                        <b>{element.fullName}</b>
                      </td>
                      <td className="ant-table-cell">{element.brand.name}</td>

                      {null != element.data ? (
                        <>
                          <td className="ant-table-cell">
                            <b>
                              {achat ? element.data.l__prixAchatTTC.toFixed(3) : element.data.l__prixVenteTTC.toFixed(3)}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  verticalAlign: "text-top",
                                }}
                              >
                                DT
                              </span>
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <span
                              className={
                                element.data.stock <= 0
                                  ? "auto--color-danger"
                                  : element.data.stock < element.data.l__seuil
                                  ? "auto--color-warning"
                                  : "auto--color-success"
                              }
                            >
                              {element.data.stock}
                            </span>
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="ant-table-cell"></td> <td className="ant-table-cell"></td>
                        </>
                      )}
                      <td className="ant-table-cell" style={{ display: "inline-flex" }}>
                        {!element.inStock && (
                          <Button type="link" onClick={(e) => addFromTecdoc(element)} icon={<img src="/images/plusVente.svg" />} />
                        )}
                        <Button
                          type="link"
                          icon={<img src="/images/detailArticle.svg" style={{ height: "90%" }} />}
                          onClick={(e) => toGetArticleDetails(element)}
                          style={{ marginLeft: 1 }}
                        ></Button>

                        {displayTitle && element.inStock && null != addItem && (
                          <Button
                            type="link"
                            style={{ marginLeft: 1 }}
                            icon={<img src="/images/toAdd.svg" />}
                            onClick={() => addItem(toFormatArticle(element.data, achat))}
                          ></Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {!fromTecdoc && null != articles && articles.length > 0 && (
            <>
              <div className="table-wrapper">
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell"></th>
                      <th className="ant-table-cell">Titre</th>
                      <th className="ant-table-cell">Caractéristiques</th>
                      <th className="ant-table-cell">P U TTC</th>
                      <th className="ant-table-cell">Stock</th>
                      <th className="ant-table-cell">
                        {!firstSearch && !fromTecdoc && (
                          <Row justify="end" style={{ display: "inline-block" }}>
                            <Tooltip title="Page précédente" color="#2db7f5">
                              <Button size="large" disabled={page == 0} onClick={() => goBack()} icon={<LeftOutlined />} type="link"></Button>
                            </Tooltip>

                            <Tooltip title="Page suivante" color="#2db7f5">
                              <Button size="large" onClick={() => goNext()} icon={<RightOutlined />} type="link"></Button>
                            </Tooltip>
                          </Row>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <Skeleton active loading={loading} />
                  <tbody className="ant-table-tbody">
                    {!loading &&
                      articles.map((article, i) => (
                        <tr key={i} className={i % 2 != 0 && "auto--background-grey"}>
                          <td className="ant-table-cell">
                            <img
                              onClick={() => {
                                setTecdocLiaison(true);
                                setSelectedArticle(article);
                              }}
                              src="/images/tecdoc.png"
                              className={article.b__tecdoc ? "auto-tecdoc-img" : "auto-tecdoc-img-gray"}
                              alt="smtv-logo"
                            />
                          </td>
                          <td className="ant-table-cell">
                            {articleToUpdate && article.id === articleToUpdate.id ? (
                              <Input
                                onSearch={onSearchTecdoc}
                                defaultValue={article.s__secondTitle}
                                enterButton
                                onKeyPress={(e) => updateArticleName(e)}
                              />
                            ) : (
                              <b
                                style={{ fontSize: "1rem" }}
                                onClick={(e) => e.detail == 2 && cookie.load("role") == "Super Admin" && setarticleToUpdate(article)}
                              >
                                {article.s__secondTitle}
                              </b>
                            )}
                          </td>
                          <td className="ant-table-cell" style={{ padding: "10px" }}>
                            <span>REF</span> : <b style={{ color: "#2696d3", fontSize: "0.75rem" }}>{article.s__reference}</b>
                            <br />
                            <span>Mar</span> : <b style={{ color: "#846eb1", fontSize: "0.75rem" }}>{article.marqueTitle}</b>
                            <br />
                            <span>Emp</span> :{" "}
                            <b style={{ fontSize: "0.8rem" }}>
                              {article.emplacement.map((e) => (
                                <>
                                  <div>{e.stock + " => " + e.emp}</div>
                                </>
                              ))}
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <b>
                              {achat ? article.l__prixAchatTTC.toFixed(3) : article.l__prixVenteTTC.toFixed(3)}{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  verticalAlign: "text-top",
                                }}
                              >
                                DT
                              </span>
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <span
                              className={
                                article.stock <= 0
                                  ? "auto--color-danger"
                                  : article.stock < article.l__seuil
                                  ? "auto--color-warning"
                                  : "auto--color-success"
                              }
                            >
                              {article.stock}
                            </span>
                          </td>
                          <td className="ant-table-cell" style={{ display: "inline-flex" }}>
                            <Button
                              type="link"
                              onClick={(e) => toDisplayArticle(article)}
                              icon={<img src="/images/detailArticle.svg" style={{ height: "90%" }} />}
                            ></Button>
                            {null != addItem && (
                              <Button
                                type="link"
                                style={{ marginLeft: "1px" }}
                                icon={<img src="/images/toAdd.svg" />}
                                onClick={() => addItem(toFormatArticle(article))}
                              ></Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </Col>

      <Modal
        className="smtv-modal modal-large"
        visible={tecdocLiaison}
        maskClosable={false}
        title={selectedArticle.s__secondTitle + " " + selectedArticle.s__reference}
        onCancel={(e) => setTecdocLiaison(false)}
        footer={[
          <Button key="back" onClick={(e) => setTecdocLiaison(false)}>
            Fermer
          </Button>,
        ]}
      >
        <TecdocLiaison selectedArticle={selectedArticle} setTecdocLiaison={(j, json) => afterBind(j, json)} />
      </Modal>
      <Modal
        className="smtv-modal"
        title={null != article && article.ref + " " + article.fullName}
        visible={detailModalVisible}
        maskClosable={false}
        onCancel={(e) => toCloseModal()}
        footer={[
          <Button key="back" onClick={(e) => toCloseModal(false)}>
            Fermer
          </Button>,
        ]}
      >
        <Skeleton active avatar loading={loadingModal} />

        <Divider>Caractéristiques de l'article</Divider>
        <table className="ant-table">
          <tbody className="ant-table-tbody">
            {details.map((o, x) => (
              <tr key={x}>
                <td className="ant-table-cell">
                  <Typography.Title level={5}>
                    <strong>{o.name}</strong>
                  </Typography.Title>
                </td>

                <td className="ant-table-cell">
                  <Typography.Text>{o.value}</Typography.Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Divider>References d'origine</Divider>

        <table className="ant-table">
          <tbody className="ant-table-tbody">
            {origines.map((o, x) => (
              <tr key={x}>
                <td className="ant-table-cell">
                  <Typography.Title level={5}>
                    <strong>{o.name}</strong>
                  </Typography.Title>
                </td>

                <td className="ant-table-cell">
                  <Typography.Link onClick={(e) => onClickOrigine(o.value)}>{o.value}</Typography.Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Divider>Images</Divider>
        {null != article && article.img != "" && <Image src={article.img} />}
      </Modal>

      <ArticleModal saveArticle={saveArticle} details={articleDetails} createModalVisible={createModalVisible} closeCreateModal={exitCreateModal} />

      <ArticleDetail setDetailVisible={updateArticle} article={articleDisplay} visible={detailVisble} />
    </div>
  );
};

export default Tecdoc;
