import { Descriptions, Col, Image, Row, Card, Modal, Form, Input, Button, Alert, Switch, Tabs } from "antd";

import { useState, useEffect } from "react";
import { toChangeDisplay, toGetCode, toGetProfile, toUpdateCredentials, toUpdateCode, uploadImage } from "./Api";
import moment from "moment";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { errorMsg, successMsg } from "../../Utils/Utils";
import cookie from "react-cookies";
import history from "../../Utils/History";
import axios from "axios";
import FicheEmployee from "../Employees/FicheEmployee";

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Profile = ({ user }) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const [displayName, setDisplayName] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayTel, setDisplayTel] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalCode, setModalCode] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [code, setCode] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [form] = Form.useForm();
  const [formCode] = Form.useForm();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openLogin, setOpenLogin] = useState(true);

  useEffect(() => {
    if (!openLogin) {
      if (null != user) {
        setData(user);
        setDisplayName(user.displayName);
        setDisplayTel(user.displayTel);
      } else {
        toGetProfile().then((res) => {
          setData(res);
          setDisplayName(res.displayName);
          setDisplayTel(res.displayTel);
          formCode.setFieldsValue({
            s__code: res.code,
          });
        });
        toGetCode().then((res) => {
          setCode(res.code);

          setPercentage(res.percentage);
        });
        setLoading(false);
      }
    }
  }, [openLogin]);

  const cancelCreateModal = () => {
    form.setFieldsValue({
      username: data.username,
      password1: "",
      password2: "",
    });
    setModalVisible(false);
  };

  const toUploadImage = (file) => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("id", data.id);
    uploadImage(bodyFormData).then(() => {
      setSelectedImage(file);
    });
  };

  const saveCode = () => {
    formCode.validateFields().then((values) => {
      toUpdateCode(formCode.getFieldValue("s__code")).then((res) => {
        if (res) {
          successMsg("Code modifiée avec succés!");
          setModalCode(false);
        } else {
          errorMsg("Merci de choisir un autre code");
        }
      });
    });
  };
  const closeCreateModal = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.password1 != values.password2) {
          setLoginError("Les mots de passe ne sont pas identiques!");
        } else {
          toUpdateCredentials(values.username, values.password1);
          cancelCreateModal();
        }
      })
      .catch((errorInfo) => {
        errorMsg(errorInfo["errorFields"][0]["name"] + " " + errorInfo["errorFields"][0]["errors"]);
      });
  };

  const onChange = (a, b) => {
    setDisplayName(a);
    setDisplayTel(b);

    toChangeDisplay(a, b);
  };

  useEffect(() => {
    document.addEventListener("mousemove", () => {
      localStorage.setItem("lastActvity", new Date());
    });
    document.addEventListener("click", () => {
      localStorage.setItem("lastActvity", new Date());
    });

    let timeInterval = setInterval(() => {
      let lastAcivity = localStorage.getItem("lastActvity");
      var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
      var seconds = Math.floor(diffMs / 1000);
      if (seconds === 30) {
        clearInterval(timeInterval);
        history.push("/");
      }
    }, 1000);
  }, []);

  const toVerifyLogin = async () => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/authenticate", {
        username,
        password,
        token: cookie.load("fcmtoken"),
      })

      .then(function (response) {
        if (response.data.success.active === 0) {
          setLoginError("Votre compte a été désactivé, veuillez contacter votre administrateur");
        } else {
          if (cookie.load("id") != response.data.success.id) {
            setLoginError("Merci de se déconnecter et vous connectez avec votre compte");
          } else {
            history.push("/compte/profile");
            setOpenLogin(false);
          }
        }
      })
      .catch(function (err) {
        setLoginError("Nom d'utilisateur ou mot de passe incorrecte");
      });
  };

  return (
    <>
      {null != data && (
        <Tabs>
          <Tabs.TabPane key='1' tab='Mes Informations'>
            <Card style={{ marginTop: "2vh" }}>
              <Row span={24}>
                <Col span={12}>
                  <Image
                    width={500}
                    height={300}
                    src={selectedImage ? URL.createObjectURL(selectedImage) : process.env.REACT_APP_API_URL + "/images/" + cookie.load("id") + ".jpg"}
                  />
                  <input
                    type='file'
                    name='myImage'
                    onChange={(event) => {
                      console.log(event.target.files[0]);
                      toUploadImage(event.target.files[0]);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Descriptions bordered>
                    <Descriptions.Item span={8} label='Nom'>
                      {data.nom}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label='Prénom'>
                      {data.prenom}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label='Username'>
                      {data.username}
                    </Descriptions.Item>

                    <Descriptions.Item span={8} label='Téléphone'>
                      {data.phone}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label='CIN'>
                      {data.cin}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label='Role'>
                      {data.role}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label='Date de création'>
                      {moment(data.createdDate).format("D MMMM YYYY")}
                    </Descriptions.Item>
                    {null != data.createdBy && (
                      <Descriptions.Item span={8} label='Crée par'>
                        {data.createdBy}
                      </Descriptions.Item>
                    )}
                    {null == user && (
                      <>
                        <Descriptions.Item span={8} label="Afficher mon nom dans l'impression">
                          <Switch onChange={() => onChange(!displayName, displayTel)} checked={displayName} />
                        </Descriptions.Item>

                        <Descriptions.Item span={8} label="Afficher mon téléphone dans l'impression">
                          <Switch onChange={() => onChange(displayName, !displayTel)} checked={displayTel} />
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </Col>
              </Row>
              <Row span={24}>
                <Col span={8}></Col>
                <Col span={16} style={{ marginTop: "3vh", textAlign: "center" }}>
                  <Button type='primary' onClick={(e) => setModalVisible(true)} size='large' icon={<EditOutlined />}>
                    Modifier mon username et mot de passe
                  </Button>
                  {cookie.load("role") == "Super Admin" && (
                    <Button type='primary' onClick={(e) => setModalCode(true)} size='large' style={{ marginLeft: "10px" }} icon={<EditOutlined />}>
                      Modifier mon code
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane key={"2"} tab='Ma fiche employée'>
            <FicheEmployee id={cookie.load("id")} user={data} />
          </Tabs.TabPane>
        </Tabs>
      )}

      <Modal className='smtv-modal' title='Login' visible={openLogin} footer={[]}>
        <Form
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
          }}
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
            username: "",
            password: "",
          }}
          onFinish={toVerifyLogin}
        >
          <div className='logo-wrapper' alt='smtv-logo'>
            <img src='/images/logo-original.png' className='logo-login' />
          </div>
          {loginError && <Alert message={loginError} type='error' style={{ marginBottom: 20 }} />}
          <Form.Item
            label='Username'
            name='username'
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre nom d'utilisateur!",
              },
            ]}
          >
            <Input onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            onChange={(e) => setPassword(e.target.value)}
            rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button size='large' type='primary' htmlType='submit'>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        className='smtv-modal'
        title='Modifier username et password'
        visible={modalVisible}
        maskClosable={false}
        onCancel={(e) => cancelCreateModal()}
        footer={[
          <Button key='back' onClick={(e) => cancelCreateModal()}>
            Annuler
          </Button>,
          <Button key='submit' type='primary' onClick={(e) => closeCreateModal()}>
            Enregistrer user
          </Button>,
        ]}
        onOk={closeCreateModal}
      >
        <Form
          form={form}
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            padding: "50px 10px",
          }}
          {...layout}
          name='basic'
          initialValues={{
            username: null != data ? data.username : "",
            password1: "",
            password2: "",
          }}
        >
          <Form.Item
            label='Username'
            name='username'
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre nom d'utilisateur!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password1'
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label='Retapez votre password'
            name='password2'
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          {loginError && <Alert message={loginError} type='error' style={{ marginBottom: 20 }} />}
        </Form>
      </Modal>
      <Modal
        className='smtv-modal'
        title='Modifier code'
        visible={modalCode}
        maskClosable={false}
        onCancel={(e) => setModalCode(false)}
        footer={[
          <Button key='back' onClick={(e) => setModalCode(false)}>
            Annuler
          </Button>,
          <Button key='submit' type='primary' onClick={(e) => saveCode()}>
            Enregistrer
          </Button>,
        ]}
      >
        <Form
          form={formCode}
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            padding: "50px 10px",
          }}
          name='basic'
        >
          <Form.Item
            label='Code'
            name='s__code'
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre code!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Profile;
