import { connect } from "react-redux";

import { login } from "../Actions/AuthActions";
import { Form, Input, Button, Alert } from "antd";

import "../Styles/Login.css";
import CenteredLayout from "../Layouts/CenteredLayout";
import { useEffect, useState } from "react";
import { toLogin } from "../Utils/Api";
import history from "../Utils/History";
import { toGetProviderClientArticle } from "../Utils/Utils";
import { getToken } from "../Utils/firebase";
//import { getToken } from "../Utils/firebase";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Login = ({ AppStore, ...props }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getToken();
  }, []);
  const submit = () => {
    setLoginError(null);
    setLoading(true);

    toLogin(username, password).then((res) => {
      setLoading(false);
      switch (res) {
        case 0:
          //compte désacttivé
          setLoginError("Votre compte a été désactivé, veuillez contacter votre administrateur");

          break;
        case 1:
          //compte actif
          toGetProviderClientArticle();
          props.login({ name: username });
          if (null != AppStore) {
            AppStore.setFirst(0);
            AppStore.setSecond(0);
          }

          history.push("/achat");
          break;
        case -1:
          //compte invalide
          setLoginError("Nom d'utilisateur ou mot de passe incorrecte");
          break;

        default:
          break;
      }
    });
  };

  return (
    <CenteredLayout>
      <Form
        style={{
          backgroundColor: "white",
          border: "1px solid #ddd",
          padding: "50px 100px",
          marginTop: "-20%",
        }}
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
          username: "",
          password: "",
        }}
        onFinish={submit}
      >
        <div className="logo-wrapper" alt="smtv-logo">
          <img src="/images/logo-original.png" className="logo-login" />
        </div>
        {loginError && <Alert message={loginError} type="error" style={{ marginBottom: 20 }} />}
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Veuillez saisir votre nom d'utilisateur!",
            },
          ]}
        >
          <Input onChange={(e) => setUsername(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button size="large" type="primary" htmlType="submit" disabled={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </CenteredLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
