import {
  Col,
  Row,
  Typography,
  Modal,
  Select,
  Button,
  Input,
  Skeleton
} from "antd";
import React, { useEffect, useState } from "react";

import "../../../Styles/TwoTables.scss";

import history from "../../../Utils/History";
import { toGetEmplacementList } from "../../Gestions/Api";
import { toGetStock } from "../APIs";
import { toGetEntityList } from "../../Compte/Api";
import ArticleDetail from "../../Gestions/ArticleDetail";
import "../../../Styles/TwoTables.scss";
import { customFilter } from "../../../Utils/Utils";

const InventoryPage = ({ AppStore, boncommande, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [emplacements, setemplacements] = useState([]);
  const [detailVisble, setDetailVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [items, setItems] = useState([]);
  const [itemsAll, setItemsAll] = useState([]);
  const [articleDisplay, setArticleDisplay] = useState("");
  const [Entitys, setEntitys] = useState([]);
  const [entityId, setEntityId] = useState(-1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AppStore.setFirst(0);
    AppStore.setSecond(13);
    toGetEmplacementList().then((data) => setemplacements(data));
    if (AppStore.entityId != -1) {
      toGetStock(AppStore.entityId).then((res) => {
        var l__res = [];
        res.forEach((item) => {
          item.name = item.article.s__secondTitle;
          item.ref = item.article.s__reference;
          l__res.push(item);
        });
        setItemsAll(l__res);
        setItems(l__res);
        setLoading(false);
      });
    }
    toGetEntityList().then((res) => {
      setEntitys(res);
    });
  }, []);

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const updatedList = customFilter(e.target.value, itemsAll, [
      "name",
      "ref",
      "emplacement"
    ]);
    setItems(updatedList);
  };

  const toDisplayArticle = (item) => {
    setArticleDisplay({
      id: item.article.id,
      titre: item.article.s__firstTitle,
      titre2: item.article.s__secondTitle,
      reference: item.article.s__reference,
      code: item.article.s__barCode,
      type: item.article.s__type,
      unite: item.article.unite,
      marque: item.article.marqueTitle,
      categorie: item.article.categoryTitle,
      emplacement: item.article.emplacement,
      seuil: item.article.l__seuil,
      tva: item.article.tva,
      prixAchatHT: item.article.l__prixAchatHT,
      prixAchatTTC: item.article.l__prixAchatTTC,

      marge: item.article.l__marge,
      margeValue: item.article.l__margeValue,
      prixVenteHT: item.article.l__prixVenteHT,
      prixVenteTTC: item.article.l__prixVenteTTC,

      margeGros: item.article.l__margeGros,
      margeGrosValue: item.article.l__margeGrosValue,
      prixVenteGrosHT: item.article.l__prixVenteGrosHT,
      prixVenteGrosTTC: item.article.l__prixVenteGrosTTC,
      data: item.article.data,
      b__tecdoc: item.article.b__tecdoc
    });
    setDetailVisible(true);
  };

  return (
    <div id="achat">
      <ArticleDetail
        setDetailVisible={() => setDetailVisible(false)}
        article={articleDisplay}
        visible={detailVisble}
      />
      <Row className="auto--custom-header">
        <Col xl={12} md={12} xs={24}>
          <Typography.Title level={6}>INVENTAIRE </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span="8" offset="8">
          <Select
            style={{ width: "100%" }}
            onChange={(e) => {
              setEntityId(e);
              AppStore.setEntityId(e);
              toGetStock(e).then((res) => {
                var l__res = [];
                res.forEach((item) => {
                  item.name = item.article.s__secondTitle;
                  item.ref = item.article.s__reference;
                  l__res.push(item);
                });
                setItemsAll(l__res);
                setItems(l__res);
                setLoading(false);
              });

              if (e != -1) {
                AppStore.setEntityName(
                  Entitys.filter((a) => a.id == e)[0].title
                );
              } else {
                AppStore.setEntityName("");
              }
            }}
            placeholder="Séléctionner l'entité"
          >
            {Entitys.map((e) => (
              <Select.Option value={e.id}>{e.title}</Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      {entityId != -1 && (
        <Row style={{ marginTop: "2vh" }}>
          <Col span="8" />
          <Col span="8" className="">
            <Row justify={"center"}>
              <Col
                span={10}
                className="auto--new-menu-vente auto--button-menu"
                onClick={() => {
                  history.push("/achat/inventory/list");
                }}
              >
                <img className="auto--img-button" src="/images/produits.png" />
                <br />
                <span>Inventaire</span>
              </Col>
              <Col span={4}></Col>
              <Col
                span={10}
                className="auto--new-menu-vente auto--button-menu"
                onClick={() => setVisible(true)}
              >
                <img
                  className="auto--img-button"
                  src="/images/bonlivraison.png"
                />
                <br />
                <span>Stock {AppStore.entityName}</span>
              </Col>
            </Row>
            <hr style={{ marginTop: "2vh" }} />
            <Row justify={"center"} style={{ marginTop: "2vh" }}>
              <Col
                span={10}
                className="auto--new-menu-achat auto--button-menu"
                onClick={() => {
                  history.push("/achat/bs/list");
                }}
              >
                <img
                  className="auto--img-button"
                  src="/images/boncommande.png"
                />
                <br />
                <span>Bon de sortie</span>
              </Col>
              <Col span={4}></Col>
              <Col
                span={10}
                className="auto--new-menu-achat auto--button-menu"
                onClick={() => {
                  history.push("/achat/be/list");
                }}
              >
                <img className="auto--img-button" src="/images/reception.png" />
                <br />
                <span>Bon d'entrée</span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={"Stock " + AppStore.entityName}
        width="150vh"
        style={{ width: "150vh" }}
      >
        <div id="achat">
          <Input.Search
            style={{ width: "50vh" }}
            onInput={onSearch}
            value={searchValue}
            placeholder="Recherche par référence, nom, emplacement..."
            enterButton
          />
          <Row style={{ marginTop: "2vh" }}>
            <Col span={24}>
              <div className="table-wrapper auto--overflow  ">
                <div className="table-wrapper">
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Titre</th>
                        <th className="ant-table-cell">Caractéristiques</th>
                        <th className="ant-table-cell">P U TTC</th>
                        <th className="ant-table-cell">Stock</th>
                        <th className="ant-table-cell"></th>
                      </tr>
                    </thead>
                    <Skeleton active loading={loading} />

                    <tbody className="ant-table-tbody">
                      {items.map((item, i) => (
                        <tr
                          key={i}
                          className={i % 2 != 0 && "auto--background-grey"}
                        >
                          <td className="ant-table-cell"></td>
                          <td className="ant-table-cell">
                            <b style={{ fontSize: "1rem" }}>
                              {item.article.s__secondTitle}
                            </b>
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ padding: "10px" }}
                          >
                            <span>REF</span> :{" "}
                            <b style={{ color: "#2696d3", fontSize: "1rem" }}>
                              {item.article.s__reference}
                            </b>
                            <br />
                            <span>Mar</span> :{" "}
                            <b style={{ color: "#846eb1", fontSize: "1rem" }}>
                              {item.article.marqueTitle}
                            </b>
                            <br />
                            <span>Emp</span> :{" "}
                            <b style={{ fontSize: "1rem" }}>
                              {item.emplacement}
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <b>
                              {item.article.l__prixVenteTTC.toFixed(3)}{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  verticalAlign: "text-top"
                                }}
                              >
                                DT
                              </span>
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <span className={"auto--color-success"}>
                              {item.qte}
                            </span>
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ display: "inline-flex" }}
                          >
                            <Button
                              type="link"
                              onClick={(e) => toDisplayArticle(item)}
                              icon={
                                <img
                                  src="/images/detailArticle.svg"
                                  style={{ height: "90%" }}
                                />
                              }
                            ></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>{" "}
            </Col>
          </Row>{" "}
        </div>
      </Modal>
    </div>
  );
};

export default InventoryPage;
