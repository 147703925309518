import { ITEMS_ACTIONS } from "./Actions";

export const addItem = (item) => {
  return {
    type: ITEMS_ACTIONS.ADD_ITEM,
    data: { item }
  };
};

export const setTotal = (total) => {
  return {
    type: ITEMS_ACTIONS.SET_TOTAL,
    data: { total }
  };
};

export const setSelectedItem = (ref) => {
  return {
    type: ITEMS_ACTIONS.SET_SELECTED_ITEM,
    data: { ref }
  };
};

export const setTotalDiscount = (totalDiscount) => {
  return {
    type: ITEMS_ACTIONS.SET_TOTAL_DISCOUNT,
    data: { totalDiscount }
  };
};

export const setDetails = (article) => {
  return {
    type: ITEMS_ACTIONS.SET_DETAILS,
    data: { article }
  };
};

export const setUnitPriceTTC = (value, item) => {
  return {
    type: ITEMS_ACTIONS.SET_UNIT_PRICE_TTC,
    data: { value, item }
  };
};

export const setUnitPriceHT = (value, item) => {
  return {
    type: ITEMS_ACTIONS.SET_UNIT_PRICE_HT,
    data: { value, item }
  };
};

export const removeItem = (item) => {
  return {
    type: ITEMS_ACTIONS.REMOVE_ITEM,
    data: { item }
  };
};

export const setItemDiscount = (discount, item) => {
  return {
    type: ITEMS_ACTIONS.SET_ITEM_DISCOUNT,
    data: { item, discount }
  };
};

export const setItemMarge = (marge, item) => {
  return {
    type: ITEMS_ACTIONS.SET_ITEM_MARGE,
    data: { item, marge }
  };
};
export const setItemEmp = (emp, item) => {
  return {
    type: ITEMS_ACTIONS.SET_ITEM_EMP,
    data: { item, emp }
  };
};

export const setItemQuantity = (quantity, item) => {
  return {
    type: ITEMS_ACTIONS.SET_ITEM_QUANTITY,
    data: { quantity, item }
  };
};

export const setClient = (client) => {
  return {
    type: ITEMS_ACTIONS.SET_CLIENT,
    data: { client }
  };
};

export const setProvider = (provider) => {
  return {
    type: ITEMS_ACTIONS.SET_PROVIDER,
    data: { provider }
  };
};

export const setEmplacement = (emplacement) => {
  return {
    type: ITEMS_ACTIONS.SET_EMPLACEMENT,
    data: { emplacement }
  };
};

export const setReglements = (reglements) => {
  return {
    type: ITEMS_ACTIONS.SET_REGLEMENTS,
    data: { reglements }
  };
};

export const setAchat = (achat) => {
  return {
    type: ITEMS_ACTIONS.SET_ACHAT,
    data: { achat }
  };
};

export const clearItems = () => {
  return {
    type: ITEMS_ACTIONS.CLEAR_ITEMS
  };
};

export const setItemsState = (state) => {
  return {
    type: ITEMS_ACTIONS.SET_ITEMS_STATE,
    data: state
  };
};
