/* eslint-disable jsx-a11y/alt-text */
import { Button, Form, Typography, Input, Skeleton, Row, Col, Tooltip, List,  } from "antd";
import { useEffect, useState } from "react";

import { MailOutlined,WhatsAppOutlined } from "@ant-design/icons";
import QuoteModal from "../Quotes/QuoteModal";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";

import DataTable from "react-data-table-component";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import {getDevisEnligneList, toConvertQuoteEnligne, toSaveQuotePDF} from "../APIs"
import BonLivraisonModal from "../BL/BonLivraisonModal";
import { errorMsg, toConvertDate } from "../../../Utils/Utils";
import Email from "../Email";

const DevisEnligne = ({ AppStore, ...props }) => {
  const [quotes, setQuotes] = useState([]);
  const [blToInspect, setBlToInspect] = useState(null);
  
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [entete, setEntete] = useState(true);
    const [emailModal, setEmailModal] = useState(false);

  const [show, setShow] = useState(true);
  const [quoteToInspect, setQuoteToInspect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [whatsappModal, setWhatsappModal] = useState(false);


  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(19);
    getDevisEnligneList().then((res) =>{
        setQuotes(res)
        setLoading(false)
        setShow(false)
    })

  }, []);

  


  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const onSelectRow = (e) => {
    setSelectedQuotes(e.selectedRows);
  };

  
  const toDisplayBl = () => {
    if (selectedQuotes.length > 1) return false;
    var display = true;

    selectedQuotes.forEach((element) => {
      if (element.quote != null) {
        display = false;
        return;
      }
    });
    return display;
  };

  const columns = [
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      format: (row) => `${toConvertDate(row.date)}`,
      grow:0.75
      
    },
    {
      name: "Client",
      selector: "data",
      format:(row) => <span>{row.data.infos.name}</span>,
      grow:0.5
    },
    {
      name: "Email",
      selector: "data",
      format:(row) => <span>{row.data.infos.email}</span>,
            grow:0.5
    },
    {
      name: "Téléphone",
      selector: "data",
      format:(row) => <span>{row.data.infos.phone}</span>,
            grow:0.5

    },
    {
      name: "Marque ",
      selector: "data",
      format:(row) => <span>{row.data.carinfos.marque}</span>,
            grow:0.5

    },   
    {
      name: "VIN ",
      selector: "data",
      format:(row) => <span>{row.data.carinfos.vin}</span>,
      
    },   
    {
      name: "KM ",
      selector: "data",
      format:(row) => <span>{row.data.carinfos.km}</span>,
            grow:0.5

    },       
    {
      name: "Articles",
      selector: "data",
            format:(row) => <p>
             {row.data.carinfos.articles}
        
        </p>,
      grow: 1.5,

    },
    
    {
      name: "Note",
      selector: "data",
            format:(row) => 
                <span>
             {row.data.carinfos.note}
        </span>
        
        ,
      

    },
    {
      name: "Livraison",
      selector: "data",
            grow:0.25,

            format:(row) => <span>
                
             {row.data.carinfos.livraison ? 'oui' : 'non'}
        
        
        </span>,
      

    },
    {
      name: "Devis",
      selector: "quoteRef",
      sortable: true,
      
      //format: (bl) => bl.blRef && <Typography.Link onClick={() => setBlToInspect(bl.blId)}>{bl.blRef}</Typography.Link>,
      format: (bl) => bl.quoteId && <Typography.Link onClick={() => setQuoteToInspect(bl.quote)}>{bl.quoteRef}</Typography.Link>,
    },
    {
      name: "BL",
      selector: "blRef",
      sortable: true,
      format: (bl) => bl.blId && <Typography.Link onClick={() => setBlToInspect(bl.blId)}>{bl.blRef}</Typography.Link>,
      

    },
  ];

  const createQuoteEnligne = () =>{
    AppStore.setFirst(1);
    AppStore.setSecond(7);

    toConvertQuoteEnligne(selectedQuotes[0].id).then((res) =>{
      if(res != null){
        const win = window.open(
      "/vente/devis/modifier/" + res,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
      }else{
        errorMsg('Le devis a été déja pris en charge')
      }
        
    

    })
  }

  const sendWhatsapp = () => {
    toSaveQuotePDF(selectedQuotes[0].quote.id, true, true).then(() => {
      const api = "https://back.cody.tn/pdf/devis" + selectedQuotes[0].quoteRef + ".pdf";
      const url = "https://web.whatsapp.com/send?phone=+216" + selectedQuotes[0].data.infos.phone + "&text=" + api + "&app_absent=0";
      window.open(url);
      setWhatsappModal(false);
    });
  };


  

  return (
    <div id="vente">
      <Loading show={show} color="red" showSpinner={true} />
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>LISTE DES DEMANDE DE DEVIS</Typography.Title>
          </Col>

        </Row>
      </Form>
      <Row gutter="24" style={{ marginTop: "1vh" }}>
        <Col span="23">
          <Skeleton active loading={loading} />

          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={quotes}
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de devis trouvé(s)</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={columns}
            data={quotes}
            pagination
            selectableRows
          />
        </Col>


        <Col span="1" className="auto--background-grey">
          {selectedQuotes.length > 0 && (
            <div style={{ marginTop: "5vh" }}>
              
              {toDisplayBl() && (
                <Tooltip title="Pour créer un devis" color="#58c5cc" placement="leftTop">
                  <Button
                    type="link"
                    onClick={() => createQuoteEnligne()}
                    style={{ marginTop: "3vh" }}
                    icon={<img src="/images/factureBlack.svg" className="auto--icon-list"></img>}
                  ></Button>
                </Tooltip>
                
              )}
              

            </div>
          )}
          {selectedQuotes.length === 1 && selectedQuotes[0].quote != null && (
            <>                <Tooltip title="Envoyer par email" color="#58c5cc" placement="leftTop">
                  <Button
                    type="link"
                    onClick={(e) => {
                      setEmailModal(true);
                    }}
                    size="large"
                    style={{ marginTop: "2vh" }}
                    icon={<MailOutlined />}
                  ></Button>
                </Tooltip>

                <Tooltip title="Envoyer par whatsapp" color="#58c5cc" placement="leftTop">
                  <Button
                    type="link"
                    onClick={(e) => {
                      sendWhatsapp(true);
                    }}
                    size="large"
                    style={{ marginTop: "2vh" }}
                    icon={<WhatsAppOutlined />}
                  ></Button>
                </Tooltip>
                </>

              )}

          
        </Col>
      </Row>
      <BonLivraisonModal entete={false} id={blToInspect} onClose={() => setBlToInspect(null)} />
      <QuoteModal quote={quoteToInspect} entete={entete} onClose={() => setQuoteToInspect(null)} />

      <Email to={selectedQuotes.length === 1 &&selectedQuotes[0].data.infos.email} id={selectedQuotes.length === 1 && [selectedQuotes[0].quote]} setEmailModal={() => setEmailModal(false)}
      
      
      visible={emailModal} type="quoteenligne" />

    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(null, mapDispatchToProps)(DevisEnligne);
