export default class Article {
  id = -1;
  titre = "";
  titre2 = "";
  reference = "";
  code = "";
  type = "Origine";
  unite = "";
  marque = "";
  categorie = "";
  emplacement = "";
  seuil = 1;
  tva = 0;
  marge = 40;
  prixAchatHT = 0;
  prixAchatTTC = 0;
  prixVenteHT = 0;
  prixVenteTTC = 0;
  margeGros = 40;
  margeValue = "%";
  margeGrosValue = "%";
  img = "";
  data = [];
  fromTecdoc = false;
  prixVenteGrosHT = 0;
  prixVenteGrosTTC = 0;
}
