import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
} from "antd";
import { useState, useEffect } from "react";
import { toGetMarqueList, toDeleteMarque, toAddOrUpdateMarque } from "./Api";
import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";
import { PlusOutlined } from "@ant-design/icons";

moment.updateLocale("fr", localization);

const FactureAvoir = () => {
  const [loading, setLoading] = useState(true);
  const [open, setIsOpen] = useState(false)

  useEffect(() => {

  }, []);

  return (
    <>
      <Row>
        <Col span="12">
          <Typography.Title level="2">Facture Avoir</Typography.Title>
        </Col>
        <Col span="12">
          <Button type="primary" onClick={() => setIsOpen(true)}>Ajouter</Button>
        </Col>
        
      </Row>
      <Modal title="Facture Avoir" visible={open}>

        <Input.Search placeholder="Référence Facture"/>

      </Modal>

      <Row
        style={{
          overflowY: "hidden",
        }}
      >
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date création</th>
                  <th className="ant-table-cell">Réf</th>
                  <th className="ant-table-cell">Référence Facture</th>
                  <th className="ant-table-cell">Crée par</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />

            </table>
          </div>
        </Col>
      </Row>
     
    </>
  );
};

export default FactureAvoir;
