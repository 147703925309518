/* eslint-disable jsx-a11y/alt-text */
import { Button, Form, Typography, Input, Skeleton, Row, Col, Select, Tooltip, Switch, Popconfirm, DatePicker, notification, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  toGetQuotes,
  toPrintQuote,
  toUpdateQuoteData,
  toCreateBlFromQuote,
  toDeleteQuotes,
  toGetClientsListIds,
  toGetTotalQuotes,
  toUpdateDate,
  toCreateDevisF,
  toVerifyAuthorizationApi,
  toSaveBLPDF,
  toSaveQuotePDF,
} from "../APIs";
import moment from "moment";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { SaveOutlined, CalendarFilled, MailOutlined, ExportOutlined, WhatsAppOutlined } from "@ant-design/icons";
import QuoteModal from "./QuoteModal";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import history from "../../../Utils/History";
import DataTable from "react-data-table-component";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import { numberFormatCurrency, successMsg, toConvertDate } from "../../../Utils/Utils";
import ls from "localstorage-ttl";
import { toGetUsers } from "../../Compte/Api";
import BonLivraisonModal from "../BL/BonLivraisonModal";
import ModalCode from "../ModalCode";
import Email from "../Email";

const { Option } = Select;

const QuotesList = ({ AppStore, ...props }) => {
  const [emailModal, setEmailModal] = useState(false);

  const [date, setDate] = useState("");
  const [quotes, setQuotes] = useState([]);
  const [blToInspect, setBlToInspect] = useState(null);
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [text, settext] = useState("");

  const [quotesAll, setQuotesAll] = useState([]);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [entete, setEntete] = useState(true);
  const [show, setShow] = useState(true);
  const [quoteToInspect, setQuoteToInspect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [whatsappModal, setWhatsappModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectedMethode, setSelectedMethode] = useState(0);
  const [selectedClient, setSelectedClient] = useState("0");
  const [selectedDate, setSelectedDate] = useState(cookie.load("role") == "Super Admin" ? "0" : "2");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(cookie.load("role") == "Super Admin" ? "0" : cookie.load("id"));
  const [selectedDateValue, setSelectedDateValue] = useState("0");
  const [displayDate, setDisplayDate] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [visible, setIsVisible] = useState(false);

  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(7);

    fetchQuotes(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);
    });

    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const fetchQuotes = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotalRows(0);

    toGetQuotes(page, perPage, options).then((data) => {
      setQuotes(data.res);
      setQuotesAll(data.res);
      ls.set(`quotes`, data.res);
      setShow(false);
      setLoading(false);
    });
    toGetTotalQuotes(options).then((data) => {
      setTotalRows(data.nb);
    });
  };

  const sendWhatsapp = () => {
    toSaveQuotePDF(selectedQuotes[0].id, true, true).then(() => {
      const api = "https://back.cody.tn/pdf/devis" + selectedQuotes[0].ref + ".pdf";
      const url = "https://web.whatsapp.com/send?phone=+216" + phone + "&text=" + api + "&app_absent=0";
      window.open(url);
      setWhatsappModal(false);
    });
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const printQuote = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    if (selectedQuotes.length == 1) {
      toPrintQuote(selectedQuotes[0].id, titre1, entete);
    }
  };
  const updateQuote = (quote) => {
    const win = window.open(
      "/vente/devis/modifier/" + quote.id,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  const onSearch = (e) => {
    // setSearchValue(e.target.value);
    // const updatedList = customFilter(e.target.value, quotesAll, [
    //   "clientName",
    //   "total",
    //   "ref",
    //   "note",
    //   "date",

    //   "displayableName",
    //   "displayableMF",
    // ]);
    // setQuotes(updatedList);
    setSearchValue(e);

    setLoading(true);

    fetchQuotes(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e,
    });
  };

  const toUpdateMf = (quote, value) => {
    const newQuotes = [...quotes];
    const index = newQuotes.findIndex((a) => a.id === quote.id);

    newQuotes[index].displayableMF = value;
    setQuotes(newQuotes);
  };

  const toUpdateClientName = (quote, value) => {
    const newQuotes = [...quotes];
    const index = newQuotes.findIndex((a) => a.id === quote.id);

    newQuotes[index].displayableName = value;
    setQuotes(newQuotes);
  };

  const toUpdateClientAndMF = (quote) => {
    var data = {
      clientName: quote.displayableName,
      mf: quote.displayableMF,
      id: quote.id,
    };
    toUpdateQuoteData(data).then(() => {
      successMsg("informations mis à jours avec succée! ");
    });
  };
  const key = "updatable";

  const createBl = (id) => {
    AppStore.setFirst(1);
    AppStore.setSecond(8);
    const bl = selectedQuotes[0];
    if (bl.b__locked) {
      const args = {
        key: new Date(),
        message: "Ce client a été désactivé",
        description: "merci de vérifier avec l'administration!!",
        duration: 3,
      };
      notification.error(args);
      return;
    }
    var diff = moment(new Date()).diff(moment(bl.firstUnpaiedBL).add(bl.d__paymentDelay, "days"), "days");

    if (bl.d__threshold > 0 && !isAdminModalVisible) {
      var totalUnpaied = parseFloat(bl.unpaied) + parseFloat(bl.total);
      if (totalUnpaied > bl.d__threshold || (diff > 0 && bl.d__paymentDelay != 0)) {
        toVerifyAuthorizationApi(bl.clientId).then((response) => {
          if (response) {
            toCreateBlFromQuote(selectedQuotes[0].id, id).then((res) => {
              if (res) {
                history.push("/vente/bl/modifier/" + res.id);
              } else {
                notification.error({
                  key,
                  message: "Opérations non autorisé",
                  description: "Le stock négatif n'est pas autorisé!",
                });
              }
            });
            return;
          } else {
            settext(`Le client ${bl.clientName} a dépassé son seuil `);
            setAdminModalVisible(true);
            return;
          }
        });
      } else {
        toCreateBlFromQuote(selectedQuotes[0].id, id).then((res) => {
          if (res) {
            history.push("/vente/bl/modifier/" + res.id);
          } else {
            notification.error({
              key,
              message: "Opérations non autorisé",
              description: "Le stock négatif n'est pas autorisé!",
            });
          }
        });
      }
    } else {
      toCreateBlFromQuote(selectedQuotes[0].id, id).then((res) => {
        if (res) {
          history.push("/vente/bl/modifier/" + res.id);
        } else {
          notification.error({
            key,
            message: "Opérations non autorisé",
            description: "Le stock négatif n'est pas autorisé!",
          });
        }
      });
    }
  };

  const onSelectRow = (e) => {
    setSelectedQuotes(e.selectedRows);
  };

  const toDisplayDelete = () => {
    var display = true;
    selectedQuotes.forEach((element) => {
      if (element.locked || element.blRef != null) {
        display = false;
      }
    });
    return display;
  };
  const toDisplayBl = () => {
    if (selectedQuotes.length > 1) return false;
    var display = true;

    selectedQuotes.forEach((element) => {
      if (element.blRef != null) {
        display = false;
        return;
      }
    });
    return display;
  };

  const toDisplayPrint = () => {
    var display = true;
    for (let index = 1; index < selectedQuotes.length; index++) {
      const element = selectedQuotes[index];
      if (element.clientId != selectedQuotes[index - 1].clientId) {
        display = false;
      }
    }
    return display;
  };

  const onChange = () => {
    setEntete(!entete);
  };

  const deleteQuote = () => {
    var ids = [];
    selectedQuotes.forEach((element) => {
      ids.push(element.id);
    });
    setLoading(true);

    toDeleteQuotes(ids).then(() => {
      fetchQuotes(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
    });
  };

  const columns = [
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Référence",
      selector: "ref",
      sortable: true,
      format: (bl) => (
        <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setQuoteToInspect(bl)}>
          {bl.ref}
        </Typography.Link>
      ),
    },
    {
      name: "Client affiché",
      selector: "displayableName",
      sortable: true,
      grow: 1.5,

      format: (bl) => (
        <Row gutter={24}>
          <Col span={24}>
            <Input onBlur={(e) => toUpdateClientName(bl, e.target.value)} defaultValue={bl.displayableName} />
          </Col>
        </Row>
      ),
    },
    {
      name: "MF affiché",
      selector: "displayableMF",
      sortable: true,
      grow: 1.5,

      format: (bl) => (
        <Row gutter={24}>
          <Col span={18}>
            <Input onBlur={(e) => toUpdateMf(bl, e.target.value)} defaultValue={bl.displayableMF} />
          </Col>
          <Col span={4} style={{ float: "left" }}>
            <Button type='link' onClick={() => toUpdateClientAndMF(bl)} icon={<SaveOutlined />}></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
      format: (row) => `${numberFormatCurrency(row.total)}`,
    },

    {
      name: "Note",
      selector: "note",
    },
    {
      name: "BL",
      selector: "blRef",
      sortable: true,
      format: (bl) => bl.blRef && <Typography.Link onClick={() => setBlToInspect(bl.blId)}>{bl.blRef}</Typography.Link>,
    },
  ];

  const onChangeClient = (option) => {
    var id = "0";
    if (option == "0") {
      id = "0";
    } else {
      id = clients.filter((el) => el.name == option)[0].id;
    }
    setSelectedClient(id);

    fetchQuotes(0, {
      paied: selectedMethode,
      client: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option == "0") {
      id = "0";
    } else {
      id = users.filter((el) => el.username == option)[0].id;
    }

    setSelectedUser(id);
    fetchQuotes(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchQuotes(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const handlePageChange = (page) => {
    fetchQuotes(page - 1, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetQuotes(page - 1, newPerPage, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    }).then((data) => {
      setQuotes(data.res);
      setQuotesAll(data.res);
      setLoading(false);
      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  const updateDate = () => {
    toUpdateDate(date, selectedQuotes[0].id).then((res) => {
      successMsg("Devis modifié avec succées!");
      fetchQuotes(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
      setIsVisible(false);
    });
  };

  const creerFacture = () => {
    toCreateDevisF(selectedQuotes[0].id).then((res) => {
      successMsg("Devis F crée avec succées!");
    });
  };

  return (
    <div id='vente'>
      <Loading show={show} color='red' showSpinner={true} />
      <Form layout='vertical'>
        <Row className='auto--custom-header'>
          <Col span='4'>
            <Typography.Title level={5}>LISTE DES DEVIS</Typography.Title>
          </Col>
          <Col span='5' style={{ textAlign: "right" }} justify='end'>
            <Form.Item label='Réf,note,client/mf affiché'>
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>

          <Col span='4'>
            <Form.Item label='Date'>
              <Select showSearch onSelect={onChangeDate} style={{ width: "100%" }} defaultValue={cookie.load("role") == "Super Admin" ? "0" : "2"}>
                <Option value='0'>Tout</Option>

                <Option value='1'>Hier</Option>
                <Option value='2'>Aujourd'hui</Option>
                <Option value='3'>Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value='5'>Date Spécifique</Option>
                <Option value='6'>Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchQuotes(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchQuotes(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
          </Col>

          <Col span='4'>
            <Form.Item label='Client'>
              <Select showSearch onSelect={onChangeClient} style={{ width: "80%" }} defaultValue='0'>
                <Option value='0'>Tout</Option>

                {clients.map((c) => (
                  <Option value={c.name}>{c.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span='4'>
            {users.length > 0 && (
              <Form.Item label='Utilisateurs'>
                <Select
                  showSearch
                  onSelect={onChangeUsers}
                  style={{ width: "80%" }}
                  defaultValue={
                    cookie.load("role") == "Super Admin" ? "0" : users.length > 0 && users.filter((u) => u.id == cookie.load("id"))[0].username
                  }
                >
                  <Option value='0'>Tout</Option>

                  {users
                    .filter((a) => a.active)
                    .map((u) => (
                      <Option value={u.username}>{u.username}</Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col span='3' style={{ textAlign: "right" }}>
            {/* <Button
              type="link"
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button> */}
            <Link key='1' to='/vente/devis/creer'>
              <Button type='link' size='large' icon={<img src='/images/plusVente.svg' />}></Button>
            </Link>
          </Col>
        </Row>
      </Form>{" "}
      <Modal title='Envoyer par WhatsApp' visible={whatsappModal} footer={[]} onCancel={() => setWhatsappModal(false)}>
        <Input placeholder='Numéro WhatsApp' onChange={(e) => setPhone(e.target.value)}></Input>
        <Button onClick={() => sendWhatsapp()}>Envoyer</Button>
      </Modal>
      <Row gutter='24' style={{ marginTop: "1vh" }}>
        <Col span='23'>
          <Skeleton active loading={loading} />

          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={quotes}
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de devis trouvé(s)</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={columns}
            data={quotes}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Col>

        <Col span='1' className='auto--background-grey'>
          {selectedQuotes.length > 0 && (
            <div style={{ marginTop: "5vh" }}>
              {toDisplayPrint() && (
                <>
                  <Tooltip title='Pour imprimer avec/sans entête' color='#58c5cc' placement='leftTop'>
                    <Switch checked={entete} onChange={() => onChange()} />
                  </Tooltip>
                  <Tooltip title='Pour imprimer' color='#58c5cc' placement='leftTop'>
                    <Button
                      type='link'
                      onClick={(e) => printQuote(e)}
                      onContextMenu={(e) => printQuote(e)}
                      style={{ marginTop: "2vh" }}
                      icon={<img src='/images/print.svg' className='auto--icon-list'></img>}
                    ></Button>
                  </Tooltip>
                </>
              )}
              {selectedQuotes.length === 1 && (
                <>
                  <Tooltip placement='leftTop' title='Détails du devis' color='#58c5cc'>
                    <Button
                      disabled={selectedQuotes.length > 1}
                      type='link'
                      style={{ marginTop: "2vh" }}
                      onClick={() => setQuoteToInspect(selectedQuotes[0])}
                      icon={<img src='/images/info.svg' className='auto--icon-list'></img>}
                    ></Button>
                  </Tooltip>
                </>
              )}
              {selectedQuotes.length === 1 && (
                <Tooltip title='Pour modifier le devis' color='#58c5cc' placement='leftTop'>
                  <Button
                    type='link'
                    disabled={selectedQuotes.length > 1}
                    style={{ marginTop: "2vh" }}
                    onClick={() => updateQuote(selectedQuotes[0])}
                    icon={<img src='/images/edit.svg' className='auto--icon-list'></img>}
                  ></Button>
                </Tooltip>
              )}

              {toDisplayDelete() && (
                <Tooltip title='Pour supprimer le(s) devis' color='#58c5cc' placement='leftTop'>
                  <Popconfirm
                    title={`Voulez vous vraiment supprimer ce devis?`}
                    onConfirm={() => deleteQuote()}
                    okText='Oui'
                    cancelText='Non'
                    placement='left'
                  >
                    <Button type='link' style={{ marginTop: "2vh" }} icon={<img src='/images/trash.svg' className='auto--icon-list'></img>}></Button>
                  </Popconfirm>
                </Tooltip>
              )}
              {toDisplayBl() && (
                <Tooltip title='Pour créer un bon de livraison' color='#58c5cc' placement='leftTop'>
                  <Button
                    type='link'
                    onClick={() => createBl()}
                    style={{ marginTop: "3vh" }}
                    icon={<img src='/images/factureBlack.svg' className='auto--icon-list'></img>}
                  ></Button>
                </Tooltip>
              )}
              {selectedQuotes.length == 1 && (
                <Tooltip title='Pour modifier la date du devis' color='#58c5cc' placement='leftTop'>
                  <Button
                    type='link'
                    onClick={() => setIsVisible(true)}
                    style={{ marginTop: "3vh" }}
                    icon={<CalendarFilled size={"large"} />}
                  ></Button>
                </Tooltip>
              )}
              {selectedQuotes.length > 0 && (
                <Tooltip title='Envoyer par email' color='#58c5cc' placement='leftTop'>
                  <Button
                    type='link'
                    onClick={(e) => {
                      setEmailModal(true);
                    }}
                    size='large'
                    style={{ marginTop: "2vh" }}
                    icon={<MailOutlined />}
                  ></Button>
                </Tooltip>
              )}

              {(cookie.load("id") == "8" || cookie.load("id") == "18" || cookie.load("id") == "1") && selectedQuotes.length === 1 && (
                <Tooltip title='Pour facturer le devis (devis F)' color='#58c5cc' placement='leftTop'>
                  <Button type='link' onClick={() => creerFacture()} style={{ marginTop: "2vh" }} icon={<ExportOutlined />}></Button>
                </Tooltip>
              )}
              {selectedQuotes.length === 1 && (
                <Tooltip title='Envoyer par whatsapp' color='#58c5cc' placement='leftTop'>
                  <Button
                    type='link'
                    onClick={(e) => {
                      setWhatsappModal(true);
                    }}
                    size='large'
                    style={{ marginTop: "2vh" }}
                    icon={<WhatsAppOutlined />}
                  ></Button>
                </Tooltip>
              )}
            </div>
          )}
        </Col>
      </Row>
      <BonLivraisonModal entete={false} id={blToInspect} onClose={() => setBlToInspect(null)} />
      <QuoteModal quote={quoteToInspect} entete={entete} onClose={() => setQuoteToInspect(null)} />
      <ModalCode
        isAdminModalVisible={isAdminModalVisible}
        setAdminModalVisible={() => {
          setAdminModalVisible(false);
        }}
        client={
          null != selectedQuotes &&
          selectedQuotes.length > 0 && {
            id: selectedQuotes[0].clientId,
          }
        }
        type={1}
        onOk={(id) => createBl(id)}
        text={text}
      />
      <Modal visible={visible} onOk={() => updateDate()} onCancel={() => setIsVisible(false)}>
        <Col span={12} style={{ textAlign: "left" }}>
          Date:
          <DatePicker onChange={(e, date) => setDate(date)} />
        </Col>
      </Modal>
      <Email id={selectedQuotes.length > 0 && selectedQuotes} setEmailModal={() => setEmailModal(false)} visible={emailModal} type='quote' />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(null, mapDispatchToProps)(QuotesList);
