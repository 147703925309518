import moment from "moment";
import CAxios from "../../Utils/CAxios";
import { EmployeeStore } from "./EmployeeStore";

export const employeeReducer = new EmployeeStore();
export const toGetAbsences = async (employeeId, i__month) => {
  var check = moment(new Date(), "YYYY/MM/DD");
  var year = check.format("YYYY");

  const { data } = await CAxios.post("/api/absence/employee", { id: employeeId, year: year, month: i__month });
  return data.success && data.success.absences ? data.success.absences : [];
};

export const toGetPenalities = async (employeeId, i__month) => {
  var check = moment(new Date(), "YYYY/MM/DD");
  var year = check.format("YYYY");

  const { data } = await CAxios.post("/api/penality/employee", { id: employeeId, year: year, month: i__month });
  return data.success && data.success.penalities ? data.success.penalities : [];
};

export const toGetListEmployee = async () => {
  const { data } = await CAxios.post("/api/employee/all/details");
  return data.success && data.success.list ? data.success.list : [];
};
export const toGetAvances = async (employeeId, i__month) => {
  var check = moment(new Date(), "YYYY/MM/DD");
  var year = check.format("YYYY");

  const { data } = await CAxios.post("/api/avance/employee", { id: employeeId, year: year, month: i__month });
  return data.success && data.success.avances ? data.success.avances : [];
};

export const addNewAvance = async (id, avance) => {
  const element = { id: id, date: moment().format("YYYY-MM-DD"), montant: avance };

  const { data } = await CAxios.post("/api/avance/insert", element);
  return data.success;
};

export const toAddNewDemande = async (id, selectedIndex, avance, dateStart, dateEnd, creditValue, creditDuree) => {
  var element = {};
  switch (selectedIndex) {
    case 0:
      element = {
        type: 0,
        id: id,
        date: moment(new Date()).format("YYYY-MM-DD"),
        data: { montant: avance },
      };
      break;
    case 1:
      var a = moment(dateStart);
      var b = moment(dateEnd);
      var duration = b.diff(a, "days") + 1;
      element = {
        type: 1,
        date: moment(new Date()).format("YYYY-MM-DD"),
        id: id,
        data: { dateStart: dateStart, dateEnd: dateEnd, duration: duration },
      };
      break;
    case 2:
      element = {
        type: 2,
        id: id,
        date: moment(new Date()).format("YYYY-MM-DD"),
        data: { montant: creditValue, period: creditDuree },
      };
      break;

    default:
      break;
  }

  const { data } = await CAxios.post("/api/demand/insert", element);
  return data.success;
};

export const toDeleteAvance = async (id) => {
  const { data } = await CAxios.post("/api/avance/delete", { id });
  return data.success;
};

export const getListOfConges = async (employeeId) => {
  var check = moment(new Date(), "YYYY/MM/DD");
  var year = check.format("YYYY");
  const { data } = await CAxios.post("/api/holiday/list", { id: employeeId, year: year });
  return data.success && data.success.holidays ? data.success.holidays : [];
};
export const getListOfCredit = async (employeeId) => {
  var check = moment(new Date(), "YYYY/MM/DD");
  var year = check.format("YYYY");
  const { data } = await CAxios.post("/api/credit/list", { id: employeeId, year: year });
  return data.success && data.success.credits ? data.success.credits : [];
};

export const getDemandes = async (id, mois) => {
  var check = moment(new Date(), "YYYY/MM/DD");
  var year = check.format("YYYY");

  const { data } = await CAxios.post("/api/demand/list", { id, mois, year });
  return data.success && data.success.demandes ? data.success.demandes : [];
};

export const toDeleteDemande = async (id) => {
  const { data } = await CAxios.post("/api/demand/delete", { id });
  return data.success;
};

export const toUpdateAbsence = async (id, absenceId, selected) => {
  const { data } = await CAxios.post("/api/absence/update", { employeeId: id, id: absenceId, holiday: selected == 1 ? false : true });
  return data.success;
};

export const toDeleteConge = async (id) => {
  const { data } = await CAxios.post("/api/holiday/delete", { id });
  return data.success;
};

export const refuseDemande = async (id) => {
  const { data } = await CAxios.post("/api/demand/refuse", { id });
  return data.success;
};
export const validateDemande = async (id) => {
  const { data } = await CAxios.post("/api/demand/validate", { id });
  return data.success;
};
export const payDemande = async (id) => {
  const { data } = await CAxios.post("/api/demand/pay", { id });
  return data.success;
};

export const toUpdatePenality = async (id) => {
  const { data } = await CAxios.post("/api/penality/update", { id });
  return data.success;
};

export const toAddCredit = async (values) => {
  const { data } = await CAxios.post("/api/credit/insert", values);
  return data.success;

}
