import { Button, Card, Form, InputNumber, Modal, Popconfirm, Table } from "antd";
import { useEffect, useState } from "react";
import { errorMsg } from "../../Utils/Utils";
import { addNewAvance, toDeleteAvance, toGetAvances } from "./Apis";

const Avances = ({ employeeReducer, mois, id, setAvanceTotal }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [montant, setMontant] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, [mois, id]);
  const getData = () => {
    setLoading(true);

    toGetAvances(id, mois).then((res) => {
      setData(res);
      setAvanceTotal(res.reduce((a, b) => a + b.montant, 0));

      setLoading(false);
    });
  };
    const conditionalRowStyles = [
    {
      
      when: (row) => row.credit == true,
      style: {
        backgroundColor: "orange",
        color: "white",
      },
    },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Montant",
      dataIndex: "montant",
      key: "montant",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (el) => (
        <Popconfirm
          title={`Voulez vous vraiment supprimer cet enregistrement?`}
          onConfirm={() =>
            toDeleteAvance(el.id).then((res) => {
              getData();
              employeeReducer.setReload(true);
            })
          }
          okText='Oui'
          cancelText='Non'
          placement='left'
        >
          <Button type='link' icon={<img src='/images/trash.svg' className='auto--icon-list'></img>}></Button>
        </Popconfirm>
      ),
    },
  ];

  const onChangeMontant = (e) => {
    setMontant(e);
  };

  const toSaveData = () => {
    if (isNaN(parseFloat(montant))) {
      errorMsg("Merci de mettre un montant supérieur à 0");
    } else {
      //save absence
      addNewAvance(id, montant).then(() => {
        setOpen(false);
        setMontant(0);
        getData();
        employeeReducer.setReload(true);
      });
    }
  };
  return (
    <>
      <Card
        className='smtv--card-background'
        type='inner'
        bordered
        title={<h4 className='text-white'>Avances</h4>}
        extra={<Button onClick={() => setOpen(true)} type='link' icon={<img src='/images/add.svg' className='auto--icon-list'></img>}></Button>}
      >
        <Table 
          rowClassName={ (record, index) =>  record.credit ? "auto--background-rembourssement"  :"" }
          loading={loading} columns={columns} dataSource={data} />
      </Card>
      <Modal title='Ajouter une avance' visible={open} onCancel={() => setOpen(false)} onOk={() => toSaveData()}>
        <Form>
          <Form.Item label='Montant'>
            <InputNumber onChange={(e) => onChangeMontant(e)} placeholder='montant' value={montant} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Avances;
