import { Card, Col, PageHeader, Row, Skeleton, Tabs } from "antd";
import { toGetUsers } from "../Compte/Api";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import history from "../../Utils/History";
import FicheEmployee from "./FicheEmployee";
import EmployeeTables from "./EmployeesTable";
import EmployeesCollapse from "./EmployeesCollapse";
import { employeeReducer } from "./Apis";

const ListEmployees = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (cookie.load("id") != "1" && cookie.load("id") != "8" && cookie.load("id") != "18") history.push("/");
    toGetUsers().then((res) => {
      setUsers(res);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Tabs defaultActiveKey='0'>
        <Tabs.TabPane tab='liste' key={"0"}>
          <EmployeesCollapse employeeReducer={employeeReducer} />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab='liste' key={"1"}>
          <EmployeeTables />
        </Tabs.TabPane> */}
        <Tabs.TabPane tab='grid' key='2'>
          <Skeleton active loading={loading} />
          {selected == null ? (
            <Row>
              {users
                .filter((x) => x.active)
                .map((el) => (
                  <Col span='4' style={{ padding: "5px" }}>
                    <Card bordered type='inner' bordred title={el.username} onClick={() => setSelected(el)}>
                      <h5>{el.nom}</h5>
                    </Card>
                  </Col>
                  // <FicheEmployee id={el.id} user={el} />
                ))}
            </Row>
          ) : (
            <>
              <PageHeader
                className='site-page-header '
                style={{ backgroundColor: "#fff", marginBottom: "20px" }}
                onBack={() => setSelected(null)}
                title='List des employées'
              ></PageHeader>
              <FicheEmployee employeeReducer={employeeReducer} id={selected.id} user={selected} />
            </>
          )}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default ListEmployees;
