/* eslint-disable eqeqeq */
import { Button, Card, Checkbox, Col, Modal, Popconfirm, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { toGetAbsences, toUpdateAbsence } from "./Apis";
import { errorMsg } from "../../Utils/Utils";
import cookie from "react-cookies";

const Absences = ({ employeeReducer, mois, id, setAbsences }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [checked, setChecked] = useState(-1);

  useEffect(() => {
    getData();
  }, [mois, id]);

  const getData = () => {
    setLoading(true);
    toGetAbsences(id, mois).then((res) => {
      setData(res);
      setAbsences(res);
      setLoading(false);
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{status == 1 ? "Absent" : status == 2 ? "Absence justifiée" : ""}</>,
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (el) => (
        <>
          {el.status == 1 && (cookie.load("id") === "8" || cookie.load("id") == "18") && (
            <Button
              type='link'
              onClick={() => {
                setSelected(el.absenceId);
              }}
              icon={<img src='/images/edit.svg'></img>}
            ></Button>
          )}
        </>
      ),
    },
  ];

  const toSaveData = () => {
    if (checked === -1) {
      errorMsg("Merci de séléctionner une option");
      return;
    }
    toUpdateAbsence(id, selected, checked).then((res) => {
      getData();
    });
  };

  return (
    <Card className='smtv--card-background' type='inner' bordered title={<h4 className='text-white'>Absences</h4>}>
      <Table loading={loading} columns={columns} dataSource={data} />
      <Modal title="Modifier l'absence" visible={selected !== -1} onCancel={() => setSelected(-1)} onOk={() => toSaveData()}>
        <Row>
          <Col span='12'>Absence justifié</Col>
          <Col span='12'>
            <Checkbox checked={checked === 1} onChange={() => setChecked(1)} />
          </Col>
        </Row>
        <Row>
          <Col span='12'>Convertir en congés</Col>
          <Col span='12'>
            <Checkbox checked={checked === 2} onChange={() => setChecked(2)} />
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

export default Absences;
