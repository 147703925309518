import { Typography, Select, Row, Col, Form, DatePicker, Skeleton, Tag } from "antd";
import { useEffect, useState } from "react";
import { toGetQuotesBLClients, toGetQuotesBLEntities, toGetTotalClients, toGetTotalEntities, toGetTotalUser } from "./APIs";
import moment from "moment";
import {
  ComposedChart,
  Line,
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  LabelList,
} from "recharts";
import Loading from "react-loading-bar";

import { toGetEntityList, toGetclients } from "../Compte/Api";
import { toGetClientsList } from "../Vente/APIs";
const { RangePicker } = DatePicker;

const { Option } = Select;
const colors = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000",
];

const StatsClient = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clients, setclients] = useState([]);
  const [all, setAll] = useState([]);
  const [selected, setSelectedClient] = useState([]);
  const [entityIds, setEntityIds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [type, setSelectedType] = useState("1");

  useEffect(() => {
    toGetClientsList().then((clients) => {
      setclients(clients.filter((e) => e.active));
      getDataClients(0, type, [clients[0].id]);
      setSelectedClient([clients[0].id]);
    });
  }, []);

  const getData = (date, clientsIds) => {
    setData([]);
    getDataClients(date, type, selected);
    return;
  };

  const getDataClients = (date, type, clientsIds) => {
    setLoading(true);
    switch (type) {
      case "1": //chiffre d'affaires
        toGetTotalClients(date, clientsIds).then((res) => {
          setData(res);
          setAll(res);
          setLoading(false);
        });
        break;

      case "2": //devis convertis en bl
        toGetQuotesBLClients(date, clientsIds).then((res) => {
          setData(res);
          setAll(res);
          setLoading(false);
        });
        break;

      default:
        break;
    }
  };
  const onChangeClients = (option) => {
    setData([]);
    var ids = [];
    if (option.length == 0) {
      setSelectedClient([]);
      getDataClients(selectedDate, type, ids);

      return;
    }
    option.forEach((element) => {
      if (element != "0") {
        ids.push(clients.filter((el) => el.s__name == element)[0].id);
      }
    });
    setSelectedClient(ids);
    getDataClients(selectedDate, type, ids);
  };

  const onChangeDate = (option) => {
    setSelectedDate(option);
    getData(option, entityIds);
  };
  const getUsername = (el) => {
    return clients.filter((e) => e.id == el)[0].s__name;
  };

  const toGetTotal = (id) => {
    let total = 0;
    data.forEach((el) => {
      total += parseFloat(el[getUsername(id)]);
    });
    if (type == "2") {
      return Math.round(total / data.length);
    }
    return total.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$& ");
  };

  return (
    <>
      <Loading show={loading} color="red" showSpinner={true} />

      <Row className="auto--custom-header" style={{ marginTop: "20px" }}>
        <Col span="5">
          <Typography.Title level={3}>Statistiques Clients </Typography.Title>
        </Col>
        <Col span="4">
          <Select
            showSearch
            style={{ width: "80%" }}
            defaultValue={"1"}
            onChange={(e) => {
              setSelectedType(e);
              getDataClients(selectedDate, entityIds, e, selected);
            }}
          >
            <Option value="1">Chiffre d'affaires</Option>
            <Option value="2">Devis convertis en BL</Option>
          </Select>
        </Col>
        <Col span="5">
          <Form.Item label="Date">
            <Select showSearch onSelect={onChangeDate} style={{ width: "100%" }} defaultValue={"0"}>
              <Option value="0">Derniers 10 jrs</Option>
              <Option value="1">Ce mois</Option>
              <Option value="2">Cette année</Option>
              <Option value="3">Année</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span="10">
          <Form.Item label="Clients">
            {clients.length > 0 && (
              <Select showSearch mode="multiple" onChange={onChangeClients} style={{ width: "80%" }}>
                {clients
                  .filter((a) => a.active)
                  .map((u) => (
                    <Option value={u.s__name}>{u.s__name}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {data.length > 0 && (
        <>
          <Row className="auto--custom-header">
            <Col span="24">
              <div style={{ height: 600, marginTop: "4vh" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 50,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <XAxis dataKey="date" />
                    <YAxis type="number" dataKey={(v) => parseInt(v.valeur)} domain={[0, "dataMax + 100"]} />

                    <Tooltip />
                    {selected.length > 0 &&
                      selected.map((el, i) => <Bar type="monotone" dataKey={getUsername(el)} stroke={colors[i]} fill={colors[i]} stackId={i}></Bar>)}
                    <Line type="monotone" dataKey="moyenne" stroke="black" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {selected.length > 0 && selected.map((el, i) => <Tag color={colors[i]}>{getUsername(el) + ": " + toGetTotal(el)}</Tag>)}
            {selected.length === 0 && entityIds.map((el, i) => <Tag color={colors[i]}>{getUsername(el) + ": " + toGetTotal(el)}</Tag>)}
          </Row>
        </>
      )}
    </>
  );
};

export default StatsClient;
