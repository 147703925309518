import {
  Typography,
  Card,
  Statistic,
  Tabs,
  Row,
  Col,
  Divider,
  Button
} from "antd";
import { useEffect, useState } from "react";
import { toGetStatClient, toGetStatSales, toSendSMS } from "./APIs";
import cookie from "react-cookies";

const Vente = () => {
  const colors = ["#1b9e4d", "darkorange", "#ef5350"];
  const [data, setData] = useState(null);
  const [dataSales, setDataSales] = useState(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    toGetStatClient().then((res) => {
      setData(res);
    });
    toGetStatSales().then((res) => {
      setDataSales(res);
    });
  }, []);

  const sendSMS = () => {
    toSendSMS();
  };
  return (
    <Row>
      <Col span="16">
    <div  className="site-statistic-demo-card">
    
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Aujourd'hui" key="1">
          <Typography.Title>Top 3 Vendeurs</Typography.Title>
          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {dataSales &&
                  dataSales[0].ca.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bl">
                {dataSales &&
                  dataSales[1].nb.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Devis convertit en bl">
                {dataSales &&
                  dataSales[2].qte.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" %"
                    />
                  ))}
              </Card>
            </Col>
          </Row>
          <Divider />
          <Typography.Title>Top 3 Clients</Typography.Title>
          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {data &&
                  data[0].ca.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bl">
                {data &&
                  data[1].nb.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Devis convertit en bl">
                {data &&
                  data[2].qte.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" %"
                    />
                  ))}
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ce mois" key="2">
          <Typography.Title>Top 3 Vendeurs</Typography.Title>

          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {dataSales &&
                  dataSales[0].ca.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bl">
                {dataSales &&
                  dataSales[1].nb.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Devis convertit en bl">
                {dataSales &&
                  dataSales[2].qte.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" %"
                    />
                  ))}
              </Card>
            </Col>
          </Row>

          <Divider />
          <Typography.Title>Top 3 Clients</Typography.Title>

          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {data &&
                  data[0].ca.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bl">
                {data &&
                  data[1].nb.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Devis convertit en bl">
                {data &&
                  data[2].qte.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" %"
                    />
                  ))}
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dernier 6 mois" key="3">
          <Typography.Title>Top 3 Vendeurs</Typography.Title>

          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {dataSales &&
                  dataSales[0].ca.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bl">
                {dataSales &&
                  dataSales[1].nb.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Devis convertit en bl">
                {dataSales &&
                  dataSales[2].qte.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" %"
                    />
                  ))}
              </Card>
            </Col>
          </Row>

          <Divider />
          <Typography.Title>Top 3 Clients</Typography.Title>

          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {data &&
                  data[0].ca.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bl">
                {data &&
                  data[1].nb.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Devis convertit en bl">
                {data &&
                  data[2].qte.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" %"
                    />
                  ))}
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      {/* <div>
        <ul>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit">Send</button>
        </form>
      </div> */}
    </div>
    </Col>
    <Col span="8" style={{textAlign: 'right'}}>
      <img style={{width: 500, height: 300}} src={`${process.env.REACT_APP_API_URL}/images/${cookie.load('id')}.jpg`}/>

    </Col>
    </Row>
  
  );
};

export default Vente;
