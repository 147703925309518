import { Button, Card, Input, Modal, Popconfirm, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getDemandes, payDemande, refuseDemande, toAddNewDemande, validateDemande } from "./Apis";
import { FileDoneOutlined, CloseOutlined, DollarOutlined } from "@ant-design/icons";
import cookie from "react-cookies";
import { errorMsg } from "../../Utils/Utils";

const Demandes = ({ employeeReducer, id, solde, salary, mois, year }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [avance, setAvance] = useState(0);

  //congé
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  //crédit
  const [creditValue, setCreditValue] = useState(0);
  const [creditDuree, setCreditDuree] = useState(0);

  useEffect(() => {
    getData(mois, year);
  }, [mois, year, id]);

  const getData = () => {
    setLoading(true);
    getDemandes(id, mois).then((l__demandes) => {
      l__demandes.sort((a, b) => (a.date < b.date ? 1 : -1));
      // l__demandes.sort((a, b) => (a.type > b.type ? 1 : -1));
      // l__demandes.sort((a, b) => (a.status < b.status ? 1 : -1));
      setLoading(false);
      setData(l__demandes);
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Type",
      dataIndex: "",
      key: "",
      render: (element) => (
        <>
          {element.type === 0 && "Avance"}
          {element.type === 1 && "Congé"}
          {element.type === 2 && "Crédit"}
        </>
      ),
    },
    {
      title: "Data",
      dataIndex: "",
      key: "x",
      render: (element) => (
        <>
          {element.type == 0 ? (
            <React.Fragment>
              <p>{"Montant: " + element.data.montant}</p>
            </React.Fragment>
          ) : element.type == 1 ? (
            <React.Fragment>
              <p>{"Date de début: " + element.data.dateStart}</p>
              <p>{"Durée: " + element.data.duration}</p>
            </React.Fragment>
          ) : element.type == 2 ? (
            <React.Fragment>
              <p>{"Montant: " + element.data.montant}</p>
              <p>{"Période: " + element.data.period}</p>
            </React.Fragment>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "y",
      render: (element) => (
        <>
          {element.status === 0 && "En attente"}
          {element.status === 1 && <span style={{ color: "#78eb81" }}>Validé </span>}
          {element.status === 3 && <span style={{ color: "#78eb81" }}>Payé </span>}
          {element.status === 2 && <span style={{ color: "#D5001E" }}>Refusé </span>}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (element) => (
        <>
          {(cookie.load("id") === "8" || cookie.load("id") == "18") && element.status == 0 && (
            <>
              <React.Fragment>
                <Button
                  onClick={(e) => {
                    toValidateDemande(element.demandeId);
                  }}
                  icon={
                    <FileDoneOutlined
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  }
                />
                <Button
                  onClick={(e) => {
                    toRefuseDemande(element.demandeId);
                  }}
                  icon={
                    <CloseOutlined
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                      }}
                    />
                  }
                />
              </React.Fragment>
            </>
          )}
          {(cookie.load("id") === "8" || cookie.load("id") == "18") && element.status == 1 && (
            <>
              <React.Fragment>
                <Button
                  onClick={(e) => {
                    toPayDemande(element.demandeId);
                  }}
                  icon={
                    <DollarOutlined
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  }
                />
              </React.Fragment>
            </>
          )}
        </>
      ),
    },
  ];

  const toRefuseDemande = (i__id) => {
    refuseDemande(i__id).then((res) => {
      getData(id);
    });
  };

  const toValidateDemande = (i__id) => {
    validateDemande(i__id).then((res) => {
      getData(id);
    });
  };

  const toPayDemande = (i__id) => {
    payDemande(i__id).then((res) => {
      getData(id);
    });
  };

  const addNewDemande = () => {
    if (selectedIndex === 0 && parseFloat(solde) + parseFloat(avance) > salary * 1.5) {
      errorMsg("Vous allez dépasser votre plafond!");
      return;
    }
    toAddNewDemande(id, selectedIndex, avance, dateStart, dateEnd, creditValue, creditDuree).then((res) => {
      getData(id);
      setCreditDuree(0);
      setCreditValue(0);
      setDateEnd(null);
      setDateStart(null);
      setAvance(0);
      setSelectedIndex(0);
      setOpenModal(false);
    });
  };

  return (
    <>
      <Card
        className='smtv--card-background'
        type='inner'
        bordered
        title={<h4 className='text-white'>Demandes</h4>}
        extra={<Button onClick={() => setOpenModal(true)} type='link' icon={<img src='/images/add.svg' className='auto--icon-list'></img>}></Button>}
      >
        <Table loading={loading} columns={columns} dataSource={data} />
      </Card>
      <Modal disablePortal disableEnforceFocus disableAutoFocus visible={openModal} onCancel={() => setOpenModal(false)} footer={[]}>
        <h3>{selectedIndex === 0 && <span>Ajouter une Demande</span>}</h3>
        <p>
          <select onChange={(e) => setSelectedIndex(e.target.selectedIndex)}>
            <option>Avance</option>
            {/* <option>Congés</option> */}
            <option>Crédit</option>
          </select>
        </p>
        {selectedIndex === 0 && (
          <React.Fragment>
            <p>
              <Input
                labelText='Entrer le montant'
                type='number'
                value={avance}
                onChange={(event) => {
                  event.target.value >= 0 && setAvance(event.target.value);
                }}
                id='mpntant'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </p>

            <Button
              color='primary'
              disabled={avance == 0}
              onClick={(e) => {
                addNewDemande();
              }}
            >
              {"Enregistrer la demande"}
            </Button>
          </React.Fragment>
        )}
        {selectedIndex === 1 && (
          <React.Fragment>
            <p>
              <Input
                labelText='Choisir la date de début'
                type='date'
                value={dateStart}
                onChange={(event) => setDateStart(event.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </p>
            <p>
              <Input
                labelText='Choisir la date de fin'
                type='date'
                value={dateEnd}
                min={dateStart}
                onChange={(event) => setDateEnd(event.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </p>

            <Button
              color='primary'
              disabled={dateStart == null || dateEnd == null}
              onClick={(e) => {
                addNewDemande();
              }}
            >
              {"Enregistrer la demande"}
            </Button>
          </React.Fragment>
        )}
        {selectedIndex === 2 && (
          <React.Fragment>
            <p>
              <Input
                labelText='Entrer le montant'
                type='number'
                value={creditValue}
                onChange={(event) => setCreditValue(event.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
              />

              <Input
                labelText='Entrer la duree'
                type='number'
                value={creditDuree}
                onChange={(event) => {
                  event.target.value >= 0 && setCreditDuree(event.target.value);
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </p>

            <Button
              color='primary'
              disabled={creditDuree == 0 || creditValue == 0}
              onClick={(e) => {
                addNewDemande();
              }}
            >
              {"Enregistrer la demande"}
            </Button>
          </React.Fragment>
        )}
      </Modal>
    </>
  );
};

export default Demandes;
