import { Typography, Skeleton, Row, Col, Form, DatePicker, Select, Descriptions, Button, Input, Modal, Popconfirm, InputNumber } from "antd";
import { useEffect, useState } from "react";

import moment from "moment";
import { toGetUsers } from "../Compte/Api";
import { toAddDivers, toGetCaisseData, toGetDivers, toPrintFiche, toPrintExercice, toRemoveDivers, toUpdateCaisseValue } from "./APIs";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { successMsg, toConvertDate } from "../../Utils/Utils";
import BonLivraisonModal from "../Vente/BL/BonLivraisonModal";
import FactureModal from "../Vente/Factures/FactureModal";
const { Option } = Select;

const Caisse = ({ AppStore, ...props }) => {
  const [blToInspect, setBlToInspect] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [selectedCaisse, setSelectedCaisse] = useState(null);
  const [userId, setUserId] = useState(-1);
  const [form] = Form.useForm();
  const [formDivers] = Form.useForm();
  const [value, setValue] = useState(-1);
  const [users, setUsers] = useState([]);
  const [divers, setDivers] = useState([]);
  const [diverOpen, setDiverOpen] = useState(false);
  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(13);
    toGetUsers().then((users) => {
      setUsers(users.filter((u) => u.caisse));
      setUserId(users.filter((u) => u.caisse)[0].id);
      getData(moment(date).format("YYYY-MM-DD"), users.filter((u) => u.caisse)[0].id);
      setLoading(false);
    });
  }, []);

  const onChangeUsers = (option) => {
    var id = users.filter((el) => el.username == option)[0].id;
    setUserId(id);
    getData(date, id);
  };

  const getData = (date, l__userId) => {
    setLoading(true);
    setUserId(l__userId);
    setDate(moment(date).format("YYYY-MM-DD"));
    toGetDivers(moment(date).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD"), l__userId).then((res) => {
      setDivers(res);
    });
    toGetCaisseData(moment(date).format("YYYY-MM-DD"), l__userId).then((res) => {
      setData(res);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  const imprimer = () => {
    toPrintFiche(date, userId).then((res) => {});
  };
  const imprimerExercice = () => {
    setLoading(true);
    toPrintExercice(moment(date).format("YYYY"), userId).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };
  const addDiver = () => {
    formDivers.validateFields().then((res) => {
      toAddDivers(res).then((res) => {
        successMsg("Divers ajoutées avec succées");
        getData(date, userId);
        setDiverOpen(false);
      });
    });
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 5:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const updateCaisseValue = () => {
    toUpdateCaisseValue(selectedCaisse.id, value).then((res) => {
      successMsg("Valeur de caisse modifié avec succés!");
      setSelectedCaisse(null);
      getData(date, userId);
    });
  };
  return (
    <>
      {selectedCaisse && (
        <Modal
          onOk={() => updateCaisseValue()}
          onCancel={() => setSelectedCaisse(null)}
          visible={selectedCaisse != null}
          title="Modifier la valeur de caisse"
        >
          <h3>{selectedCaisse.ref}</h3>
          <InputNumber onChange={(e) => setValue(e)} defaultValue={selectedCaisse.value} />
        </Modal>
      )}
      <Skeleton active loading={loading} />
      <Form form={form} style={{ marginTop: "2vh" }}>
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={3}>CAISSE</Typography.Title>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  getData(date, userId);
                }}
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} span={8} label="Utilisateurs" name="user">
              {users.length > 0 && (
                <Select showSearch onSelect={onChangeUsers} defaultValue={users.length > 0 && users[0].username}>
                  {users.map((u) => (
                    <Option value={u.username}>{u.username}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span="4">
            <Button style={{ marginLeft: "20px" }} type="primary" onClick={() => imprimer()}>
              Imprimer la fiche de caisse
            </Button>
          </Col>
          <Col span="4">
            <Button style={{ marginLeft: "20px" }} type="primary" onClick={() => imprimerExercice()}>
              Imprimer l'exercice
            </Button>
          </Col>
          <Col span="4">
            <Button style={{ marginLeft: "20px" }} type="primary" onClick={() => setDiverOpen(true)}>
              Ajouter un divers
            </Button>
          </Col>
        </Row>
      </Form>
      {/* {data.length > 0 && (
        <Row>
          <Col span="24">
            <div style={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  height={400}
                  data={data}
                  style={{ marginTop: "50px", marginBottom: "2vh" }}
                >
                  <XAxis dataKey="username" fontFamily="Proxima Nova"></XAxis>
                  <Tooltip cursor={{ fill: "transparent" }} />

                  <Bar
                    radius={[2, 2, 0, 0]}
                    dataKey="total"
                    barSize={200}
                    stackId="a"
                    fill="#846eb1"
                    fontFamily="Proxima Nova"
                  >
                    {data.map((entry, index) => (
                      <Cell></Cell>
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )} */}
      <Descriptions column={1} bordered justify="end" size="large" style={{ backgroundColor: "white", marginTop: "2vh" }}>
        <Descriptions.Item label="espèces" className="item-label">
          {(
            data.filter((el) => el.type == 0).reduce((a, b) => a + b.value, 0) +
            divers.filter((el) => el.type == 1).reduce((a, b) => a + parseFloat(b.value), 0) -
            divers.filter((el) => el.type == 0).reduce((a, b) => a + parseFloat(b.value), 0)
          ).toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="chèques" className="item-label">
          {data
            .filter((el) => el.type == 1)
            .reduce((a, b) => a + b.value, 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="traites" className="item-label">
          {data
            .filter((el) => el.type == 2)
            .reduce((a, b) => a + b.value, 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="Carte bancaire" className="item-label">
          {data
            .filter((el) => el.type == 3)
            .reduce((a, b) => a + b.value, 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="Virement bancaire" className="item-label">
          {data
            .filter((el) => el.type == 4)
            .reduce((a, b) => a + b.value, 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="retenu à la source" className="item-label">
          {data
            .filter((el) => el.type == 5)
            .reduce((a, b) => a + b.value, 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="contre remoboursement" className="item-label">
          {data
            .filter((el) => el.type == 6)
            .reduce((a, b) => a + b.value, 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="Actif" className="item-label">
          {divers
            .filter((el) => el.type == 1)
            .reduce((a, b) => a + parseFloat(b.value), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="Passif" className="item-label">
          {divers
            .filter((el) => el.type == 0)
            .reduce((a, b) => a + parseFloat(b.value), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label="Total" className="item-label">
          <b style={{ color: "red", fontSize: "20px" }}>
            {(
              data.reduce((a, b) => a + parseFloat(b.value), 0) +
              divers.filter((el) => el.type == 1).reduce((a, b) => a + parseFloat(b.value), 0) -
              divers.filter((el) => el.type == 0).reduce((a, b) => a + parseFloat(b.value), 0)
            ).toFixed(3)}
          </b>
        </Descriptions.Item>
      </Descriptions>
      <div style={{ margin: "20px" }}>
        <b style={{ fontSize: "20px" }}>Divers</b>
      </div>
      <table className="ant-table" style={{ width: "100%", marginTop: "2vh" }}>
        <thead className="ant-table-thead">
          <tr>
            <th className="ant-table-cell">Date</th>
            <th className="ant-table-cell">Note</th>
            <th className="ant-table-cell">Type</th>
            <th className="ant-table-cell">Valeur</th>
            <th className="ant-table-cell"></th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {divers.map((el) => (
            <tr>
              <td className="ant-table-cell">{toConvertDate(el.dt__createdDate)}</td>
              <td className="ant-table-cell">{el.note}</td>
              <td className="ant-table-cell">{el.type ? "Actif" : "Passif"}</td>
              <td className="ant-table-cell">{parseFloat(el.value).toFixed(3)}</td>
              <td>
                <Popconfirm
                  title={`Voulez vous vraiment supprimer ce divers?`}
                  onConfirm={() =>
                    toRemoveDivers(el.id).then((res) => {
                      getData(date, userId);
                    })
                  }
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button style={{ marginRight: 10 }} icon={<DeleteOutlined />} />
                </Popconfirm>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ margin: "20px" }}>
        <b style={{ fontSize: "20px" }}>Détails</b>
      </div>
      <table className="ant-table" style={{ width: "100%", marginTop: "2vh" }}>
        <thead className="ant-table-thead">
          <tr>
            <th className="ant-table-cell">Date</th>
            <th className="ant-table-cell">Référence BL/Facture</th>
            <th className="ant-table-cell">Type</th>
            <th className="ant-table-cell">Total BL/Facture</th>
            <th className="ant-table-cell">Caisse</th>
            <th className="ant-table-cell"></th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {data.map((el) => (
            <tr>
              <td className="ant-table-cell">{toConvertDate(el.createdDate)}</td>
              <td className="ant-table-cell">
                {el.blId != -1 ? (
                  <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setBlToInspect(el.blId)}>
                    {el.ref}
                  </Typography.Link>
                ) : (
                  <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setFactureToInspect(el.factureId)}>
                    {el.ref}
                  </Typography.Link>
                )}
              </td>
              <td className="ant-table-cell">{getReglementType(el)}</td>
              <td className="ant-table-cell">{el.total.toFixed(3)}</td>
              <td className="ant-table-cell">{el.value.toFixed(3)}</td>
              <td className="ant-table-cell">
                {/* <Button
                  type="link"
                  onClick={() => {
                    setValue(el.value);
                    setSelectedCaisse(el);
                  }}
                  icon={<EditOutlined />}
                ></Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <BonLivraisonModal entete={true} id={blToInspect} onClose={() => setBlToInspect(null)} />
      <FactureModal id={null != factureToInspect && factureToInspect} onClose={() => setFactureToInspect(null)} />
      <Modal
        title="Divers"
        visible={diverOpen}
        onCancel={() => setDiverOpen(false)}
        footer={[
          <Button type="primary" onClick={() => addDiver()}>
            Ajouter divers
          </Button>,
        ]}
      >
        <Form form={formDivers}>
          <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} label="Type" name="type">
            <Select>
              <Select.Option value="actif">Actif </Select.Option>
              <Select.Option value="passif">Passif </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} label="Value" name="value">
            <Input type="number"></Input>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} label="Note" name="note">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Caisse;
