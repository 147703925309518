import { makeAutoObservable, observable } from "mobx";

export class EmployeeStore {
  reload = false;

  constructor() {
    makeAutoObservable(this, { reload: observable });
  }

  setReload(reload) {
    this.reload = reload;
  }
}
