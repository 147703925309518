/* eslint-disable eqeqeq */
import { Card, Table, Button } from "antd";
import { useEffect, useState } from "react";
import { toGetPenalities, toUpdatePenality } from "./Apis";
import moment from "moment";
import { successMsg } from "../../Utils/Utils";
import cookie from "react-cookies";

const Penalities = ({ employeeReducer, mois, id, setPenalities }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, [mois, id]);

  const getData = () => {
    setLoading(true);
    toGetPenalities(id, mois).then((res) => {
      setData(res);
      setPenalities(res.filter((el) => el.status == 0).reduce((a, b) => b.interval + a, 0));
      setLoading(false);
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (el) => moment(el).add("1", "hours").format("YYYY-MM-DD"),
    },
    {
      title: "Penalité",
      dataIndex: "interval",
      key: "interval",
    },
    {
      title: "Status",
      dataIndex: "",
      key: "",
      render: (element) => (
        <>
          {element.status == 0 && "Penalité"}
          {element.status == 1 && "Penalité justifié"}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (el) => (
        <>
          {(cookie.load("id") === "8" || cookie.load("id") == "18") && (
            <Button
              type='link'
              onClick={() => {
                toUpdatePenality(el.id).then((res) => {
                  successMsg("Penality modifié avec succés");
                  getData();
                });
              }}
              icon={<img src='/images/edit.svg'></img>}
            ></Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Card className='smtv--card-background' type='inner' bordered title={<h4 className='text-white'>Penalities</h4>}>
      <Table loading={loading} columns={columns} dataSource={data} />
    </Card>
  );
};

export default Penalities;
