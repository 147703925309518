import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col } from "antd";
import { toGetBonReception, toGetBonsReception, toPrintBR } from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useState, useEffect } from "react";

const BonReceptionModal = ({ bonreception, onClose }) => {
  const [bon, setBon] = useState(null);
  const printBR = () => {
    toPrintBR(bonreception.id);
  };
  useEffect(() => {
    if (null != bonreception) {
      toGetBonReception(bonreception.id).then((res) => {
        console.log(res);
        setBon(res);
      });
    }
  }, [bonreception]);

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={bonreception != null}
      onCancel={onClose}
      title="Details du bon de réception"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={printBR}
        >
          Imprimer
        </Button>
      ]}
    >
      {bonreception && bon && (
        <>
          <h1 style={{ textAlign: "center" }}>Bon de réception</h1>
          <Row>
            <Col className="info-box" md={11}>
              <p>
                <b>Ref</b> : {bon.s__ref}
              </p>
              <p>
                <b>Date</b> : {toConvertDate(bonreception.createdDate)}
              </p>
              <p>
                <b>Chargé fournisseur</b> :{" "}
                {bonreception.createdBy && bonreception.createdBy}
              </p>
            </Col>

            <Col className="info-box" md={12}>
              <h1>Fournisseur</h1>
              <p>
                <b>Nom</b> : {bonreception.providerName}
              </p>
              <p>
                <b>Tel</b> : {bon.provider && bon.provider.s__phone}
              </p>
              <tr>
                <td>
                  <p>
                    <b>Référence BL Fournisseur</b> : {bon.s__blProvider}
                  </p>
                </td>
                <td></td>
                <td>
                  <p style={{ marginLeft: "20px" }}>
                    <b>Date BL Fournisseur</b> : {bon.s__blDate}
                  </p>
                </td>
              </tr>
            </Col>
          </Row>
          <h1 style={{ textAlign: "center", marginTop: "20px" }}>Articles</h1>
          <table className="ant-table" style={{ width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Ref
                </th>
                <th className="ant-table-cell">Désignation</th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Prix Unitaire HT
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Prix Unitaire TTC
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Quantité
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Remise
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Prix Total
                </th>
                <th className="ant-table-cell" style={{ width: "5%" }}>
                  TVA
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {null != bon &&
                bon.items &&
                bon.items.map((item) => (
                  <tr>
                    <td className="ant-table-cell" style={{ width: "8%" }}>
                      {item.article.s__reference}
                    </td>
                    <td className="ant-table-cell">
                      {item.article.s__firstTitle}
                    </td>
                    <td className="ant-table-cell" style={{ width: "12%" }}>
                      {item.d__unitPriceHT.toFixed(3)}
                    </td>
                    <td className="ant-table-cell" style={{ width: "12%" }}>
                      {item.d__unitPriceTTC.toFixed(3)}
                    </td>
                    <td className="ant-table-cell" style={{ width: "10%" }}>
                      {item.d__quantity}
                    </td>
                    <td className="ant-table-cell" style={{ width: "10%" }}>
                      {item.d__discount} %
                    </td>
                    <td className="ant-table-cell" style={{ width: "12%" }}>
                      {item.d__total.toFixed(3)} Dt
                    </td>
                    <td className="ant-table-cell" style={{ width: "5%" }}>
                      {item.d__tva} %
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div style={{ textAlign: "right", marginTop: 20 }}>
            <div
              style={{
                display: "inline-block",
                textAlign: "right"
              }}
            >
              <p>
                <b>Tota HT</b> :{" "}
                {(bonreception.total - bonreception.tva).toFixed(3)} DT
              </p>
              <p>
                <b>TVA</b> : {bonreception.tva.toFixed(3)} DT
              </p>
              <p>
                <b>Remise Totale</b> : {bonreception.discount} %
              </p>
              <p>
                <b>Net à Payer</b> : {bonreception.total.toFixed(3)} DT
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default BonReceptionModal;
