import { Button, Card, Input, Modal, Popconfirm, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getDemandes, refuseDemande, toAddNewDemande, validateDemande } from "./Apis";
import { FileDoneOutlined, CloseOutlined } from "@ant-design/icons";
import cookie from "react-cookies";

const ListeDemandes = ({ id }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [avance, setAvance] = useState(0);

  //congé
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  //crédit
  const [creditValue, setCreditValue] = useState(0);
  const [creditDuree, setCreditDuree] = useState(0);

  useEffect(() => {
    getData();
  }, []);

  const getSumAvances = () => {
    var total = 0;
    if (null == data) {
      return 0;
    }
    for (var i = 0; i < data.length; i++) {
      if (data[i].type == 0 && data[i].status == 0) {
        total += parseInt(data[i].data.montant);
      }
    }

    return total;
  };

  const getData = () => {
    setLoading(true);
    getDemandes(-1).then((l__demandes) => {
      console.log(l__demandes);
      l__demandes.sort((a, b) => (a.date < b.date ? 1 : -1));
      l__demandes.sort((a, b) => (a.type > b.type ? 1 : -1));
      l__demandes.sort((a, b) => (a.status > b.status ? 1 : -1));
      setLoading(false);
      setData(l__demandes.filter((el) => el.status == 0));
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Demandeur",
      dataIndex: "",
      key: "x",
      render: (element) => <>{element.lastname + " " + element.firstname}</>,
    },
    {
      title: "Type",
      dataIndex: "",
      key: "x",
      render: (element) => (
        <>
          {element.type == 0 && "Avance"}
          {element.type == 1 && "Congé"}
          {element.type == 2 && "Crédit"}
        </>
      ),
    },
    {
      title: "Data",
      dataIndex: "",
      key: "x",
      render: (element) => (
        <>
          {element.type == 0 ? (
            <React.Fragment>
              <p>{"Montant: " + element.data.montant}</p>
            </React.Fragment>
          ) : element.type == 1 ? (
            <React.Fragment>
              <p>{"Date de début: " + element.data.dateStart}</p>
              <p>{"Durée: " + element.data.duration}</p>
            </React.Fragment>
          ) : element.type == 2 ? (
            <React.Fragment>
              <p>{"Montant: " + element.data.montant}</p>
              <p>{"Période: " + element.data.period}</p>
            </React.Fragment>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "y",
      render: (element) => (
        <>
          {element.status === 0 && "En attente"}
          {element.status === 1 && <span style={{ color: "#78eb81" }}>Validé </span>}
          {element.status === 2 && <span style={{ color: "#D5001E" }}>Refusé </span>}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (element) => (
        <>
          {(cookie.load("id") === "8" || cookie.load("id") == "18") && element.status == 0 && (
            <React.Fragment>
              <Button
                onClick={(e) => {
                  toValidateDemande(element.demandeId);
                }}
                icon={
                  <FileDoneOutlined
                    style={{
                      cursor: "pointer",
                    }}
                  />
                }
              />
              <Button
                onClick={(e) => {
                  toRefuseDemande(element.demandeId);
                }}
                icon={
                  <CloseOutlined
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                    }}
                  />
                }
              />
            </React.Fragment>
          )}
        </>
      ),
    },
  ];

  const toRefuseDemande = (i__id) => {
    refuseDemande(i__id).then((res) => {
      getData(id);
    });
  };

  const toValidateDemande = (i__id) => {
    validateDemande(i__id).then((res) => {
      getData(id);
    });
  };

  return (
    <>
      <Card
        className='smtv--card-background'
        type='inner'
        bordered
        title={<h4 className='text-white'>Demandes</h4>}
        extra={
          <>
            <div class='form-group'>
              <h4 className='text-white'>Choisir un type de demande:</h4>
              <Select
                className='form-control'
                id='selectType'
                style={{ fontSize: "20px" }}
                onChange={(e) => {
                  console.log(e);
                  setSelectedIndex(e);
                }}
              >
                <Select.Option value={0}>Avance</Select.Option>
                <Select.Option value={1}>Congés</Select.Option>
                <Select.Option value={2}>Crédit</Select.Option>
              </Select>
            </div>
            {selectedIndex === 0 && (
              <span style={{ float: "right", color: "#fff", fontSize: "1.5rem", fontWeight: "bold" }}>Total avance : {getSumAvances()}</span>
            )}
          </>
        }
      >
        <Table loading={loading} columns={columns} dataSource={data.filter((el) => el.type == selectedIndex)} />
      </Card>
    </>
  );
};

export default ListeDemandes;
