import { Button, Card, Form, InputNumber, Modal, Popconfirm, Table } from "antd";
import { useEffect, useState } from "react";
import { errorMsg } from "../../Utils/Utils";
import { getListOfConges, toDeleteConge } from "./Apis";
import cookie from "react-cookies";

const Conges = ({ mois, id }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [montant, setMontant] = useState(0);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);

    getListOfConges(id).then((res) => {
      setLoading(false);
      setData(res);
    });
  };
  const columns = [
    {
      title: "Date de début",
      dataIndex: "dateStart",
      key: "dateStart",
    },
    {
      title: "Date de fin",
      dataIndex: "dateEnd",
      key: "dateEnd",
    },
    {
      title: "Durée",
      dataIndex: "duration",
      key: "duration",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (el) => (
        <Popconfirm
          title={`Voulez vous vraiment supprimer cet enregistrement?`}
          onConfirm={() => deleteCongé(el.id)}
          okText='Oui'
          cancelText='Non'
          placement='left'
        >
          <Button type='link' icon={<img src='/images/trash.svg' className='auto--icon-list'></img>}></Button>
        </Popconfirm>
      ),
    },
  ];

  const deleteCongé = (id) => {
    toDeleteConge(id).then((res) => {
      getData();
    });
  };

  const onChangeMontant = (e) => {
    setMontant(e);
  };

  const toSaveData = () => {
    if (isNaN(parseFloat(montant))) {
      errorMsg("Merci de mettre un montant supérieur à 0");
    } else {
      //save absence
      setOpen(false);
      setMontant(0);
    }
  };
  return (
    <>
      <Card
        className='smtv--card-background'
        type='inner'
        bordered
        title={<h4 className='text-white'>Conges</h4>}
        extra={
          (cookie.load("id") === "8" || cookie.load("id") == "18") && (
            <Button onClick={() => setOpen(true)} type='link' icon={<img src='/images/add.svg' className='auto--icon-list'></img>}></Button>
          )
        }
      >
        <Table loading={loading} columns={columns} dataSource={data} />
      </Card>
      <Modal title='Ajouter une avance' visible={open} onCancel={() => setOpen(false)} onOk={() => toSaveData()}>
        <Form>
          <Form.Item label='Montant'>
            <InputNumber onChange={(e) => onChangeMontant(e)} placeholder='montant' value={montant} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Conges;
