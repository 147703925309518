import { Divider, Form, Input, Modal, Row, Switch, Table, Col, Select, Button } from "antd";
import { useEffect, useState } from "react";
import { DataExistsException } from "../../../DataExitsException";
import { errorMsg } from "../../../Utils/Utils";
import { toAddClient, toAddNote, toEditClient, toRemoveNote } from "../APIs";
import { CloseOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import "../../../Styles/ClientModal.scss";
const { Option } = Select;

const ClientModal = ({ visible, readonly, clientForm, onCancel, onClientSaved }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [type, setType] = useState(clientForm.s__type);
  const [vehicules, setVehicules] = useState(clientForm.vehicules);
  const [notes, setNotes] = useState([]);
  const [noteValue, setNoteValue] = useState("");
  const [newVehicule, setNewVehicle] = useState({
    s__model: null,
    s__matricule: null,
    s__vin: null,
  });
  const footer = readonly ? { footer: null } : {};

  const closeModal = () => {
    setSaving(false);
    onCancel();
  };

  useEffect(() => {
    console.log({ clientForm });
    form.setFieldsValue(clientForm);
    setVehicules(clientForm.vehicules);
    setNotes(clientForm.notes ?? []);
  }, [form, clientForm]);

  const saveClient = async () => {
    setSaving(true);
    const client = form.getFieldsValue();
    client.values = selectedValues;
    client.vehicules = vehicules;
    try {
      let newClient;
      if (clientForm.id == null) newClient = await toAddClient(client);
      else {
        client.id = clientForm.id;
        newClient = await toEditClient(client);
      }
      onClientSaved(newClient);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("Le client existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    } finally {
      setSaving(false);
    }
  };

  const onTypeChange = (value) => {
    setType(value);
    form.setFieldsValue({ s__type: value !== "AUTRE" ? value : null });
  };

  const addVehicule = () => {
    setVehicules((vehicules || []).concat(newVehicule));
    setNewVehicle({ s__model: null, s__matricule: null, s__vin: null });
  };

  const removeVehicule = (i) => {
    setVehicules(vehicules.filter((_, index) => index !== i));
  };

  const updateVehicule = (e) => {
    setNewVehicle({ ...newVehicule, [e.target.name]: e.target.value });
  };

  const updateNote = (e) => {
    setNoteValue(e.target.value);
  };

  const plusNote = () => {
    if (notes.filter((el) => el.id == -1).length > 1) return;
    setNotes([...notes, { s__note: "", id: -1 }]);
  };

  const removeNote = (id) => {
    toRemoveNote(id).then((res) => {
      setNotes(notes.filter((el) => el.id != id));
    });
  };
  const addNote = () => {
    toAddNote(clientForm.id, noteValue).then((res) => {
      const list = [...notes];
      list.forEach((el) => {
        if (el.id == -1) {
          el.id = res;
        }
      });
      setNotes(list);
    });
  };
  return (
    <Modal
      className={"smtv-modal modal-large" + (readonly ? " readonly" : "")}
      title={clientForm.id == null ? "Ajouter un nouveau client" : "Détails client"}
      visible={visible}
      onOk={() => form.submit()}
      confirmLoading={saving}
      onCancel={closeModal}
      {...footer}
    >
      <Form form={form} layout='vertical' name='basic' onFinish={saveClient}>
        <Row gutter={24}>
          <Col md={12} sm={24}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label='Code'
                  rules={[{ required: true, message: "Champ obligatoire" }]}
                  name='s__code'
                  style={{ marginBottom: type === "AUTRE" ? 0 : 24 }}
                >
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={"Type"} style={{ marginBottom: type === "AUTRE" ? 0 : 24 }}>
                  <Select defaultValue={type} onChange={onTypeChange} disabled={readonly}>
                    <Option value='PARTICULIER'>Particulier</Option>
                    <Option value='SOCIETE'>Sociète</Option>
                    <Option value='GARAGE'>Garage</Option>
                    <Option value='AUTRE'>Autre</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} style={{ display: type === "AUTRE" ? "flex" : "none" }}>
              <Col span={12} />
              <Col span={12}>
                <Form.Item name='s__type'>
                  <Input placeholder='Saisissez le type de client' disabled={readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label='Nom' name='s__name' rules={[{ required: true, message: "Champ obligatoire" }]}>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Telephone' name='s__phoneNumber' rules={[{ required: true, message: "Champs obligatoire" }]}>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label='Email' name='s__email'>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="D'ou vient" name='s__source'>
                  <Select disabled={readonly}>
                    <Option value='PASSAGER'>Passager</Option>
                    <Option value='FACEBOOK'>Facebook</Option>
                    <Option value='RECOMMENDATION'>Recommendation</Option>
                    <Option value='AUTRE'>Autre</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label='Code TVA'
                  name='s__codeTva'
                  rules={[
                    {
                      required: type === "SOCIETE",
                      message: "Champ obligatoire",
                    },
                  ]}
                >
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  label="Registre de commerce"
                  name="s__register"
                  rules={[
                    {
                      required: type === "SOCIETE",
                      message: "Champ obligatoire",
                    },
                  ]}
                >
                  <Input disabled={readonly} />
                </Form.Item>
              </Col> */}
            </Row>
            <Row>
              <Col span={24}>
                <Divider>Infos supplémentaires</Divider>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label='Seuil' name='d__threshold'>
                      <Input disabled={readonly} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Délai de paiement' name='d__paymentDelay'>
                      <Input disabled={readonly} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Réduction' name='d__discount'>
                      <Input disabled={readonly} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={12} sm={24}>
            <Divider>Addresse</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label='Region' name='s__region'>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Ville' name='s__city'>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label='Addresse' name='s__address'>
              <Input disabled={readonly} />
            </Form.Item>
            <Divider>Contact du responsable</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label='Nom et Prenom' name='s__respName'>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Numéro telephone' name='s__respPhone'>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider>Spécifications pour le client</Divider>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Form.Item label='Tarif 2 / Tarif 1' style={{ textAlign: "center" }} name='b__price1'>
                    <Switch
                      disabled={readonly}
                      defaultChecked={clientForm.b__price1}
                      onChange={(value) => form.setFieldsValue({ b__price1: value })}
                    />
                  </Form.Item>
                  <Form.Item label='Afficher Référence' style={{ textAlign: "center" }} name='b__ref'>
                    <Switch disabled={readonly} defaultChecked={clientForm.b__ref} onChange={(value) => form.setFieldsValue({ b__ref: value })} />
                  </Form.Item>
                  <Form.Item label='Afficher Prix' style={{ textAlign: "center" }} name='b__displayPrix'>
                    <Switch
                      disabled={readonly}
                      defaultChecked={clientForm.b__displayPrix}
                      onChange={(value) => form.setFieldsValue({ b__displayPrix: value })}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item label='TVA' style={{ textAlign: "center" }} name='b__tva'>
            <Switch disabled={readonly} defaultChecked={clientForm.b__tva} onChange={(value) => form.setFieldsValue({ b__tva: value })} />
          </Form.Item>
          <Form.Item label='Timbre' style={{ textAlign: "center" }} name='b__stamp'>
            <Switch disabled={readonly} defaultChecked={clientForm.b__stamp} onChange={(value) => form.setFieldsValue({ b__stamp: value })} />
          </Form.Item>
          <Form.Item label='SMS' style={{ textAlign: "center" }} name='b__sms'>
            <Switch disabled={readonly} defaultChecked={clientForm.b__sms} onChange={(value) => form.setFieldsValue({ b__sms: value })} />
          </Form.Item>
          <Form.Item label='P.Gros' style={{ textAlign: "center" }} name='b__wholesaler'>
            <Switch
              disabled={readonly}
              defaultChecked={clientForm.b__wholesaler}
              onChange={(value) => form.setFieldsValue({ b__wholesaler: value })}
            />
          </Form.Item>
          <Form.Item label='Facture/Mois' style={{ textAlign: "center" }} name='b__billPerMonth'>
            <Switch
              disabled={readonly}
              defaultChecked={clientForm.b__billPerMonth}
              onChange={(value) => form.setFieldsValue({ b__billPerMonth: value })}
            />
          </Form.Item>

          <Form.Item label='Facture/Semaine' style={{ textAlign: "center" }} name='b__billPerWeek'>
            <Switch
              disabled={readonly}
              defaultChecked={clientForm.b__billPerWeek}
              onChange={(value) => form.setFieldsValue({ b__billPerWeek: value })}
            />
          </Form.Item>
          <Form.Item label='Inclure dans le bonus' style={{ textAlign: "center" }} name='bonus'>
            <Switch disabled={readonly} defaultChecked={clientForm.bonus} onChange={(value) => form.setFieldsValue({ bonus: value })} />
          </Form.Item>

          <Form.Item label='Bloque le bonus' style={{ textAlign: "center" }} name='b__blockBonus'>
            <Switch
              disabled={readonly}
              defaultChecked={clientForm.b__blockBonus}
              onChange={(value) => form.setFieldsValue({ b__blockBonus: value })}
            />
          </Form.Item>
          <Form.Item label='Note obligatoire' style={{ textAlign: "center" }} name='b__note'>
            <Switch disabled={readonly} defaultChecked={clientForm.b__note} onChange={(value) => form.setFieldsValue({ b__note: value })} />
          </Form.Item>

          <Form.Item label='Valeur de caisse obligatoire' style={{ textAlign: "center" }} name='b__caisse'>
            <Switch disabled={readonly} defaultChecked={clientForm.b__caisse} onChange={(value) => form.setFieldsValue({ b__caisse: value })} />
          </Form.Item>

          <Form.Item label='Remise obligatoire' style={{ textAlign: "center" }} name='b__discount'>
            <Switch disabled={readonly} defaultChecked={clientForm.b__discount} onChange={(value) => form.setFieldsValue({ b__discount: value })} />
          </Form.Item>
        </div>
        <Divider>Notes</Divider>
        <Row>
          <Col span={24}>
            <table className='ant-table' style={{ width: "100%" }}>
              <thead className='ant-table-thead'>
                <tr>
                  <th className='ant-table-cell'>Note</th>
                  <th className='ant-table-cell'>
                    <Button icon={<PlusOutlined />} onClick={() => plusNote()} />
                  </th>
                </tr>
              </thead>
              <tbody className='ant-table-tbody'>
                {notes.map((note) => (
                  <tr>
                    <td className='ant-table-cell'>{note.id != -1 ? <span>{note.s__note}</span> : <Input name='s__note' onChange={updateNote} />}</td>
                    <td className='ant-table-cell'>
                      <Button
                        icon={note.id === -1 ? <SaveOutlined /> : <DeleteOutlined />}
                        onClick={() => (note.id === -1 ? addNote() : removeNote(note.id))}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Divider>Vehicules</Divider>
        <Row>
          <Col span={24}>
            <table className='ant-table' style={{ width: "100%" }}>
              <thead className='ant-table-thead'>
                <tr>
                  <th className='ant-table-cell'>Modèle</th>
                  <th className='ant-table-cell'>Matricule</th>
                  <th className='ant-table-cell'>VIN</th>
                  <th className='ant-table-cell'></th>
                </tr>
              </thead>
              <tbody className='ant-table-tbody'>
                {!readonly && (
                  <tr>
                    <td className='ant-table-cell'>
                      <Input name='s__model' onChange={updateVehicule} />
                    </td>
                    <td className='ant-table-cell'>
                      <Input name='s__matricule' onChange={updateVehicule} />
                    </td>
                    <td className='ant-table-cell'>
                      <Input name='s__vin' onChange={updateVehicule} />
                    </td>
                    <td className='ant-table-cell'>
                      <Button icon={<PlusOutlined />} onClick={() => addVehicule()} />
                    </td>
                  </tr>
                )}
                {vehicules &&
                  vehicules.map((vehicule, i) => (
                    <tr>
                      <td className='ant-table-cell'>{vehicule.s__model}</td>
                      <td className='ant-table-cell'>{vehicule.s__matricule}</td>
                      <td className='ant-table-cell'>{vehicule.s__vin}</td>
                      <td className='ant-table-cell'>{!readonly && <Button icon={<CloseOutlined />} onClick={() => removeVehicule(i)} />}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default ClientModal;
