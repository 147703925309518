import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col, Skeleton } from "antd";
import { toPrintFacture } from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useEffect, useState } from "react";
import { toGetFacture } from "../APIs";
import LoadingScreen from "react-loading-screen";
import GaugeChart from "react-gauge-chart";

const FactureModal = ({ id, onClose }) => {
  const [facture, setFacture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [marge, setMarge] = useState(-1);

  useEffect(() => {
    if (null != id) {
      setLoading(true);
      toGetFacture(id).then((res) => {
        setFacture(res);
        var margeTotal = 0;

        setLoading(false);
        var prix_achat = 0;
        if (null != res) {
          res.bons__livraison.map((bl) => {
            prix_achat += bl.items.reduce(
              (a, b) => a + b.article.l__prixAchatHT * b.d__quantity,
              0
            );
          });
          margeTotal = Math.round(
            ((res.d__totalHT - prix_achat) / prix_achat) * 100
          );
          if (margeTotal > 0) {
            setMarge(parseInt(margeTotal));
          } else {
            setMarge(0);
          }
        }
      });
    }
  }, [id]);
  const printFacture = () => {
    toPrintFacture(id, true, true, false);
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={facture != null}
      onCancel={onClose}
      title="Details de la facture"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={printFacture}
        >
          Imprimer
        </Button>
      ]}
    >
      <LoadingScreen
        loading={loading}
        bgColor="#f5f8fa"
        spinnerColor="#B9D9EB"
        textColor="#2D2926"
      >
        {facture && (
          <>
            <h1 style={{ textAlign: "center" }}>Facture</h1>
            <Row>
              <Col className="info-box" md={11}>
                <p>
                  <b>Ref</b> : {facture.s__ref}
                </p>
                <p>
                  <b>Date</b> : {toConvertDate(facture.dt__createdDate)}
                </p>
                <p>
                  <b>Chargé client</b> :{" "}
                  {facture.l__createdBy && facture.l__createdBy.nom}
                </p>
              </Col>
              <Col className="info-box" md={11}>
                <h1>Client</h1>
                <p>
                  <b>Nom</b> : {facture.client && facture.client.s__name}
                </p>
                <p>
                  <b>Tel</b> : {facture.client && facture.client.s__phoneNumber}
                </p>
              </Col>
            </Row>
            <Row
              justify="center"
              //style={{ width: "200px", textAlign: "center" }}
            >
              <Col span="8">
                {marge != -1 && (
                  <>
                    <span style={{ marginLeft: "10%" }}>{marge + " %"}</span>
                    <br />
                    <GaugeChart
                      id="gauge-chart5"
                      nrOfLevels={100}
                      arcsLength={[0.1, 0.25, 0.65]}
                      colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                      percent={marge / 100}
                      textColor={"transparent"}
                      arcPadding={0.01}
                      style={{ height: "50%", width: "50%" }}
                    ></GaugeChart>
                  </>
                )}
              </Col>
            </Row>
            <h2>Bons de livraison</h2>
            {facture.bons__livraison.map((bl) => (
              <>
                <h4>Bon de livraison : {bl.s__ref}</h4>
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Ref
                      </th>
                      <th className="ant-table-cell">Désignation</th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Prix Unitaire HT
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Prix Unitaire TTC
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Quantité
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Remise
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Prix Total
                      </th>
                      <th className="ant-table-cell" style={{ width: "5%" }}>
                        TVA
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {bl.items &&
                      bl.items.map((item) => (
                        <tr>
                          <td
                            className="ant-table-cell"
                            style={{ width: "8%" }}
                          >
                            {item.article.s__reference}
                          </td>
                          <td className="ant-table-cell">
                            {item.article.s__firstTitle}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__unitPriceHT.toFixed(3)}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__unitPriceTTC.toFixed(3)}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "10%" }}
                          >
                            {item.d__quantity}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "10%" }}
                          >
                            {parseFloat(item.d__discount).toFixed(2)} %
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__total.toFixed(2)} Dt
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "6%" }}
                          >
                            {item.d__tva} %
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ))}
            <div style={{ textAlign: "right", marginTop: 20 }}>
              <div
                style={{
                  display: "inline-block",
                  textAlign: "right"
                }}
              >
                <p>
                  <b>Tota HT</b> : {facture.d__totalHT.toFixed(3)} DT
                </p>
                <p>
                  <b>TVA</b> :{" "}
                  {((facture.d__tva / facture.d__totalHT) * 100).toFixed(2)} %
                </p>
                <p>
                  <b>Remise Totale</b> :{" "}
                  {parseFloat(facture.d__discount).toFixed(2)} %
                </p>
                <p>
                  <b>Timbre Fiscal</b> :{" "}
                  {facture.d__totalFacture.toFixed(3) -
                    facture.d__total.toFixed(3)}{" "}
                  DT
                </p>
                <p>
                  <b>Net à Payer</b> : {facture.d__totalFacture.toFixed(3)} DT
                </p>
              </div>
            </div>
          </>
        )}
      </LoadingScreen>
    </Modal>
  );
};

export default FactureModal;
