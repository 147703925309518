import {
  Button,
  Col,
  Row,
  Typography,
  Form,
  Descriptions,
  Input,
  Select,
  InputNumber,
  Collapse,
  Checkbox,
  Switch,
  DatePicker,
  Modal
} from "antd";
import { useState } from "react";
const Option = { Select };
const ArticleStat = ({ visible }) => {
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [form] = Form.useForm();

  return (
    <Modal width="90%" title="Statistiques articles" visible={visible}>
      <Form
        form={form}
        layout="vertical"
        //onValuesChange={(e) => onValuesChange(e)}
      >
        <Row gutter="16">
          <Col span="4">
            <Form.Item label="Type" name="type">
              {form.getFieldValue("type")}
              <Select defaultValue="1">
                <Option value="1">Vente</Option>
                <Option value="2">Achat</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <Select
                showSearch
                //  onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue={"0"}
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && <DatePicker style={{ width: "100%" }} />}
            {displayDateRange && <DatePicker.RangePicker />}
          </Col>
          {form.getFieldValue("type") == "1" && (
            <>
              <Col span="3">
                <Form.Item label="Stock" name="stock">
                  <Select defaultValue="Tout">
                    <Option value="Tout">Tout</Option>

                    <Option value="=">Egal</Option>

                    <Option value=">">Supérieur</Option>
                    <Option value="<">Inférieur</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="2">
                <Form.Item label name="stockValue">
                  <InputNumber />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span="3">
            <Form.Item label="Prix" name="prix">
              <Select defaultValue="Tout">
                <Option value="Tout">Tout</Option>

                <Option value="=">Egal</Option>

                <Option value=">">Supérieur</Option>
                <Option value="<">Inférieur</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label name="prixValue">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>{" "}
    </Modal>
  );
};

export default ArticleStat;
