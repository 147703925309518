import { Button, Descriptions, Typography, Skeleton, Row, Col, Modal, Input, Form, Popconfirm, Select, DatePicker, InputNumber, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/fr";
import DataTable from "react-data-table-component";
import { CheckOutlined } from "@ant-design/icons";
import BonLivraisonModal from "../BL/BonLivraisonModal";
import FactureModal from "../Factures/FactureModal";
import {
  updatePayBl,
  toDeleteReglementClient,
  toFilterReglement,
  toGetBL,
  toGetClientsListIds,
  toValidateReglement,
  toGetFactureWithReglements,
  payFactureUpdate,
  toCashReglement,
} from "../APIs";
import { toConvertDate } from "../../../Utils/Utils";
import Search from "../../../Utils/Search";
import { BiTrash } from "react-icons/bi";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import cookie from "react-cookies";
import ReglementBLList from "./ReglementBLList";
import ReglementFacture from "./ReglementFacture";
import { toGetUsers } from "../../Compte/Api";
const { Option } = Select;

moment.updateLocale("fr", localization);

const ReglementsListClient = ({ AppStore, ...props }) => {
  var warningModal = false;
  const [blToInspect, setBlToInspect] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);

  const [form] = Form.useForm();
  const [reglements, setReglements] = useState([]);
  const [reglementsAll, setReglementsAll] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [clients, setClients] = useState([]);
  const [date, setDate] = useState([null, null]);
  const [id, setId] = useState(-1);
  const [selectedReglement, setSelectedReglement] = useState(-1);

  const fields = [
    { name: "Référence", field: "s__ref", type: "varchar" },
    { name: "Montant", field: "montant", type: "number" },
    { name: "Date ", field: "date", type: "date" },
  ];
  const [isBLModalVisible, setisBLModalVisible] = useState(false);
  const [isFactureModalVisible, setisFactureModalVisible] = useState(false);
  const [remiseChecked, setRemiseChecked] = useState(false);

  /**
   * filter la liste
   */
  const [type, setType] = useState("99");
  const [displayDate, setDisplayDate] = useState(false);

  const [selectedDate, setSelectedDate] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(cookie.load("role") == "Super Admin" ? "0" : cookie.load("id"));
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [facture, setFacture] = useState(null);
  const [blToPay, setBlToPay] = useState(true);
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [text, settext] = useState("");
  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    // fetchQuotes(0, {
    //   paied: selectedMethode,
    //   client: selectedClient,
    //   user: selectedUser,
    //   date: option,
    //   dateValue: selectedDateValue,
    //   ref: searchValue,
    // });
  };

  const onChangeUsers = (option) => {
    var l__id = "0";
    if (option != "0") {
      l__id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(l__id);
    toGetData(page, perPage, id, l__id, selectedDate);
  };

  /**
   *
   */

  const onSearch = (e, type) => {
    setSearchValue(e);
    setType(type);
    toFilterReglement(e, 0, perPage, type, id).then((res) => {
      setReglements(res.data);
      setTotalRows(res.total);
      setLoading(false);
      setShow(false);
    });
  };

  const columns = [
    {
      name: "Référence",
      selector: "s__ref",
      sortable: true,
      grow: 1,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 1,
    },
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
      grow: 1,
    },

    {
      name: "Facture/BL",
      selector: "ref",
      sortable: true,
      format: (bl) => (
        <>
          {bl.blId != -1 ? (
            <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setBlToInspect(bl.blId)}>
              {bl.ref}
            </Typography.Link>
          ) : (
            <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setFactureToInspect(bl.factureId)}>
              {bl.ref}
            </Typography.Link>
          )}
        </>
      ),
      grow: 1,
    },

    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1,
      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Type",
      selector: "ref",
      format: (bl) => getReglementType(bl),
      grow: 1,
    },

    {
      name: "Détails",
      selector: "s__ref",
      grow: 8,
      format: (reglement) => (
        <Descriptions column={6} bordered size="small" layout={"horizental"}>
          {reglement.type == 1 || reglement.type == 2 ? (
            <>
              <Descriptions.Item label="Num" className="item-label">
                {reglement.data.num}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" className="item-label">
                {reglement.data.nom}
              </Descriptions.Item>
              <Descriptions.Item label="Date" className="item-label">
                {toConvertDate(reglement.data.date)}
              </Descriptions.Item>
              <Descriptions.Item label="Banque" className="item-label">
                {reglement.data.banque}
              </Descriptions.Item>
              <Descriptions.Item label="Montant" className="item-label">
                {reglement.data.montant}
              </Descriptions.Item>
              <Descriptions.Item label="Encaissement" className="item-label">
                {reglement.cached ? (
                  "Encaissé"
                ) : (
                  <Button
                    type="link"
                    onClick={() =>
                      toCashReglement(reglement.id).then((res) => {
                        toGetData(0, perPage, id, selectedUser, selectedDate);
                      })
                    }
                    icon={<CheckOutlined />}
                  ></Button>
                )}
              </Descriptions.Item>
            </>
          ) : (
            <Descriptions.Item label="Montant" className="item-label">
              {reglement.data.montant}
            </Descriptions.Item>
          )}
        </Descriptions>
      ),
    },

    {
      selector: "s__ref",
      right: true,
      grow: 1,

      format: (reglement) =>
        cookie.load("removeReglement") == "true" && (
          <Popconfirm
            title={`Voulez vous vraiment supprimer ce réglement?`}
            onConfirm={() => deleteReglement(reglement.id)}
            okText="Oui"
            placement="left"
            cancelText="Non"
          >
            <Button type="link" icon={<BiTrash />} />
          </Popconfirm>
        ),
    },
  ];

  const columnsCaisse = [
    {
      name: "Référence",
      selector: "s__ref",
      sortable: true,
      grow: 1,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 1,
    },
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
      grow: 1,
    },

    {
      name: "Facture/BL",
      selector: "ref",
      sortable: true,
      format: (bl) => (
        <>
          {bl.blId != -1 ? (
            <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setBlToInspect(bl.blId)}>
              {bl.ref}
            </Typography.Link>
          ) : (
            <Typography.Link level={5} style={{ cursor: "pointer" }} onClick={() => setFactureToInspect(bl.factureId)}>
              {bl.ref}
            </Typography.Link>
          )}
        </>
      ),
      grow: 1,
    },
    {
      name: "Caisse",
      selector: "caisse",
      sortable: true,
      grow: 0.5,

      format: (bl) => (
        <div>
          {null != bl.caisse ? (
            <b>{bl.caisse}</b>
          ) : (
            <>
              <Button onClick={() => openModal(bl, true)} type="primary" size="small">
                Valider
              </Button>
            </>
          )}
        </div>
      ),
    },

    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1,
      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Type",
      selector: "ref",
      format: (bl) => getReglementType(bl),
      grow: 1,
    },

    {
      name: "Détails",
      selector: "s__ref",
      grow: 8,
      format: (reglement) => (
        <Descriptions column={6} bordered size="small" layout={"vertical"}>
          {reglement.type == 1 || reglement.type == 2 ? (
            <>
              <Descriptions.Item label="Num" className="item-label">
                {reglement.data.num}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" className="item-label">
                {reglement.data.nom}
              </Descriptions.Item>
              <Descriptions.Item label="Date" className="item-label">
                {toConvertDate(reglement.data.date)}
              </Descriptions.Item>
              <Descriptions.Item label="Banque" className="item-label">
                {reglement.data.banque}
              </Descriptions.Item>
              <Descriptions.Item label="Montant" className="item-label">
                {reglement.data.montant}
              </Descriptions.Item>
              <Descriptions.Item label="Encaissement" className="item-label">
                {reglement.cached ? (
                  "Encaissé"
                ) : (
                  <Button
                    type="link"
                    onClick={() =>
                      toCashReglement(reglement.id).then((res) => {
                        toGetData(0, perPage, id, selectedDate);
                      })
                    }
                    icon={<CheckOutlined />}
                  ></Button>
                )}
              </Descriptions.Item>
            </>
          ) : (
            <Descriptions.Item label="Montant" className="item-label">
              {reglement.data.montant}
            </Descriptions.Item>
          )}
        </Descriptions>
      ),
    },

    {
      selector: "s__ref",
      right: true,
      grow: 1,

      format: (reglement) =>
        cookie.load("removeReglement") == "true" && (
          <Popconfirm
            title={`Voulez vous vraiment supprimer ce réglement?`}
            onConfirm={() => deleteReglement(reglement.id)}
            okText="Oui"
            placement="left"
            cancelText="Non"
          >
            <Button type="link" icon={<BiTrash />} />
          </Popconfirm>
        ),
    },
  ];

  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(10);
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);
    });
    toGetData(0, perPage, -1, selectedUser, selectedDate);
    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const toUpdateData = (values) => {
    setReglements(values);
    setFilterModalVisible(false);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    toGetData(page - 1, newPerPage, id, selectedUser, selectedDate);

    setPerPage(newPerPage);
    setLoading(false);
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
        break;
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 6:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const toGetData = (page, newPerPage, id, userId, date) => {
    setLoading(true);
    setShow(true);
    setPage(page);
    toFilterReglement(searchValue, page, newPerPage, type, id, userId, date).then((res) => {
      setReglements(res.data);
      setTotalRows(res.total);
      setLoading(false);
      setShow(false);
    });
    return;
  };

  const deleteReglement = (i__id) => {
    setLoading(true);
    toDeleteReglementClient(i__id).then(() => {
      toGetData(0, perPage, id, selectedUser, selectedDate);
    });
  };

  const handlePageChange = (page) => {
    toGetData(page - 1, perPage, id, selectedUser, selectedDate);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const openModal = (bl, l__open) => {
    setIsOpen(l__open);
    if (l__open) {
      setSelectedReglement(bl.id);
      form.setFieldsValue({ disabled: bl.blId != -1 , value: bl.blId != -1 ? bl.caisseValue :  bl.data.montant, date: moment(bl.createdDate) });
    } else {
      setSelectedReglement(-1);
      form.setFieldsValue({ value: 0 });
    }
  };

  const onChangeClient = (option) => {
    if (option == "0") {
      setId(-1);
      toGetData(0, perPage, -1, selectedUser, selectedDate);
      return;
    }

    const l__id = clients.filter((el) => el.name == option)[0].id;
    setId(l__id);
    toGetData(0, perPage, l__id, selectedUser, selectedDate);
  };

  const validateCaisse = () => {
    form.validateFields().then((values) => {
      const l__id = users.findIndex((e) => e.username == values.driver) !== -1 ? users[users.findIndex((e) => e.username == values.driver)].id : -1;

      toValidateReglement(values.value, values.date.format("YYYY-MM-DD"), selectedReglement, l__id).then((res) => {
        toGetData(page, perPage, id, selectedUser, selectedDate);
        openModal(-1, false);
      });
    });
  };

  const toUpdateTotal = (id) => {
    const bl = { ...blToPay };
    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    bl.discount = (bl.total - totalPayé).toFixed(3);
    setBlToPay(bl);
    validate(id, bl.reglements);
  };

  const validate = (i__id, reglements) => {
    const bl = { ...blToPay };
    bl.reglements = reglements;
    setBlToPay(bl);
    setAdminModalVisible(false);

    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    if (parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) && !isAdminModalVisible && !remiseChecked && bl.client.d__threshold == 0) {
      settext("Le BL n'est pas totalement payé!");

      setAdminModalVisible(true);
      return;
    }

    if (parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) && remiseChecked && !warningModal) {
      warningModal = true;
      Modal.warning({
        okButtonProps: { style: { display: "none" } },

        content: (
          <div>
            <p>
              Voulez vous vraiment ajouter le reste(
              {(bl.total - totalPayé).toFixed(3)} )comme étant une remise!
            </p>
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <Button type="primary" onClick={() => toUpdateTotal(i__id)}>
                Valo.i__typeer
              </Button>
              <Button
                style={{ marginLeft: "2vh" }}
                danger
                onClick={() => {
                  Modal.destroyAll();
                  warningModal = false;
                }}
              >
                Annuler
              </Button>
            </div>
          </div>
        ),
      });
      return;
    }
    Modal.destroyAll();
    warningModal = false;
    updatePayBl(blToPay.id, remiseChecked, bl.reglements, i__id).then((res) => {
      toGetData(0, perPage, id, selectedUser, selectedDate);
      setisBLModalVisible(false);
    });
  };

  const toUpdate = (reglements) => {
    validate(-1, reglements);
  };
  const openModalPay = () => {
    openModal({ id: -1 }, false);
    var el = reglements.filter((e) => e.id == selectedReglement)[0].blId;
    if (el == -1) {
      toGetFactureWithReglements(reglements.filter((e) => e.id == selectedReglement)[0].factureId).then((res) => {
        setFacture(res);
        setisFactureModalVisible(true);
      });
      return;
    }
    toGetBL(el).then((data) => {
      var bl = JSON.parse(data.bonlivraison);
      var regl = [];
      data.reglements.forEach((element) => {
        regl.push(element.data);
      });
      const blToUpdate = {
        id: bl.id,
        total: bl.d__total,
        discount: bl.d__discount,
        items: [],
        client: bl.client,
        reglements: regl,
        ref: bl.s__ref,
      };
      for (let item of bl.items) {
        blToUpdate.items.push({
          ...item,
          ref: item.article.s__reference,
          titre: item.article.s__secondTitle,
          quantity: item.d__quantity,
          uPriceHt: item.d__unitPriceHT,
          uPriceTTC: item.d__unitPriceTTC,
          total: item.d__total,
          discount: item.d__discount,
          prix_achat: item.article.l__prixAchatTTC,
          prix_unitaire: item.article.l__prixVenteHT,
          prix_unitaireTTC: item.article.l__prixVenteTTC,
          prix_unitaire_gros: item.article.l__prixVenteGrosHT,
          prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
          tva: item.article.tva.title,
        });
      }
      setBlToPay(blToUpdate);

      setisBLModalVisible(true);
    });
  };

  const toPayeFacture = (reglements) => {
    payFactureUpdate(facture.id, reglements).then(() => {
      setisFactureModalVisible(false);
      toGetData(0, perPage, -1, selectedUser, selectedDate);
    });
  };
  return (
    <div id="vente">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="3">
            <Typography.Title level={5}>LISTE DES RÉGLEMENTS</Typography.Title>
          </Col>
          <Col span="4" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,client/mf affiché">
              <Input.Search onSearch={(e) => onSearch(e, type)} placeholder="Recherche par référence, client, total..." enterButton />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Type">
              <Select showSearch onSelect={(e) => onSearch(searchValue, e)} style={{ width: "90%" }} defaultValue="99">
                <Option value="99">Tout</Option>

                <Option value="0">Espèces</Option>
                <Option value="1">Chèques</Option>
                <Option value="2">Traite</Option>
                <Option value="3">Carte bancaire</Option>
                <Option value="4">Virement bancaire</Option>
                <Option value="5">Retenu à la source</Option>
                <Option value="6">Contre remboursement</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Client">
              <Select showSearch onSelect={onChangeClient} style={{ width: "80%" }} defaultValue="0">
                <Option value="0">Tout</Option>

                {clients.map((c) => (
                  <Option value={c.name}>{c.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            {users.length > 0 && (
              <Form.Item label="Utilisateurs">
                <Select
                  showSearch
                  onSelect={onChangeUsers}
                  style={{ width: "80%" }}
                  defaultValue={
                    cookie.load("role") == "Super Admin" ? "0" : users.length > 0 && users.filter((u) => u.id == cookie.load("id"))[0].username
                  }
                >
                  <Option value="0">Tout</Option>

                  {users
                    .filter((a) => a.active)
                    .map((u) => (
                      <Option value={u.username}>{u.username}</Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <DatePicker.RangePicker
                onCalendarChange={(e, date) => {
                  toGetData(page, perPage, id, selectedUser, date);
                  setDate(date[0], date[1]);
                }}
                format={"YYYY-MM-DD"}
              />
            </Form.Item>
          </Col>
          <Col span="4" style={{ textAlign: "right" }}>
            {/* <Tooltip
              title="Pour imprimer le résultat de la recherche"
              color="#58c5cc"
              placement="leftTop"
            >
              <Button
                type="link"
                onClick={(e) => toPrintReglements(searchValue, type, id)}
                icon={
                  <img
                    src="/images/print.svg"
                    className="auto--icon-list"
                  ></img>
                }
              ></Button>
            </Tooltip> */}
          </Col>
          {/* <Col span="4">
            <Form.Item label="Date">
              <Select
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  // fetchBLS(0, {
                  //   paied: selectedMethode,
                  //   client: selectedClient,
                  //   user: selectedUser,
                  //   date: selectedDate,
                  //   dateValue: date,
                  //   ref: searchValue,
                  // });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  // fetchBLS(0, {
                  //   paied: selectedMethode,
                  //   client: selectedClient,
                  //   user: selectedUser,
                  //   date: selectedDate,
                  //   dateValue: date,
                  //   ref: searchValue,
                  // });
                }}
              />
            )}
          </Col>

          */}
          {/* <Col span="4"> 
            <Form.Item label="Utilisateurs">
              <Select
                showSearch
                onSelect={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
      </Form>{" "}
      <DataTable
        customStyles={customStyles}
        columns={cookie.load("caisse") == "true" ? columnsCaisse : columns}
        data={reglements}
        pagination
        selectableRows
        noHeader
        striped
        selectableRowsHighlight
        fixedHeader
        persistTableHead
        noDataComponent={<span>Pas de réglements trouvés</span>}
        contextMessage={{
          singular: "élément",
          plural: "éléments",
          message: "séléctionné(s)",
        }}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationServer
        paginationTotalRows={totalRows}
      />
      <Skeleton active loading={loading} />
      <Modal className="modal-small" title={"Filtrer"} visible={filterModalVisible} footer={[]} onCancel={(e) => setFilterModalVisible(false)}>
        <Search toUpdateData={toUpdateData} fields={fields} list={reglementsAll} />
      </Modal>
      <Modal
        title={"Caisse"}
        visible={isOpen}
        onOk={() => validateCaisse()}
        footer={[
          <Button type="primary" onClick={() => validateCaisse()}>
            Valider
          </Button>,
          <Button
            type="dashed"
            onClick={() => {
              openModalPay();
            }}
          >
            Modifier
          </Button>,
          <Button type="default" onClick={() => openModal({ id: -1 }, false)}>
            Annuler
          </Button>,
        ]}
        onCancel={(e) => openModal({ id: -1 }, false)}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="value" label="Combien vous avez reçu pour ce règlement?" rules={[{ required: true, message: "Champ obligatoire" }]}>
            <Input type="number" disabled={form.getFieldValue("disabled") ?? false} style={{ width: "100%" }}></Input>
          </Form.Item>
          <Form.Item name="date" label="Date" rules={[{ required: true, message: "Champ obligatoire" }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item label="Reçu par" name="driver">
            <Select showSearch>
              {users
                .filter((x) => x.role === "chauffeur" && x.active)
                .map((user) => (
                  <Select.Option value={user.username}>{user.username}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal className="modal-large" visible={isBLModalVisible} onCancel={() => setisBLModalVisible(false)} footer={[]}>
        <ReglementBLList bl={blToPay} validate={toUpdate} setRemiseChecked={(checked) => setRemiseChecked(checked)} />
      </Modal>
      <Modal className="modal-large" visible={isFactureModalVisible} onCancel={() => setisFactureModalVisible(false)}>
        <ReglementFacture toPayeFacture={toPayeFacture} facture={facture} />
      </Modal>
      <BonLivraisonModal entete={true} id={blToInspect} onClose={() => setBlToInspect(null)} />
      <FactureModal id={null != factureToInspect && factureToInspect} onClose={() => setFactureToInspect(null)} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(null, mapDispatchToProps)(ReglementsListClient);
