import { Col, Row, Button, Typography, Popconfirm, Modal, Switch, Skeleton, Tabs } from "antd";

import { useState, useEffect } from "react";
import { toDeleteUser, toGetUsers, toDeactivateUser, toRegeneratePwd } from "./Api";
import { BiPencil, BiTrash } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { FileTextOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";

import moment from "moment";
import Profile from "./Profile";
import UserModal from "./UserModal";
import { successMsg, toConvertDateTime } from "../../Utils/Utils";
import history from "../../Utils/History";
import cookie from "react-cookies";
const Users = () => {
  const l__user = {
    nom: "",
    prenom: "",
    active: "",
    username: "",
    phone: "",
    cin: "",
    email: "",
    role: "",
    changeDate: false,
    options: [],
  };
  const [users, setUsers] = useState([]);
  const [displayModalVisible, setDisplayModalVisible] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [user, setUser] = useState(l__user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cookie.load("role") != "Super Admin") history.push("/");

    toGetUsers().then((res) => {
      setUsers(res);
      setLoading(false);
    });
  }, []);

  const confirmDelete = (user) => {
    toDeleteUser(user.id).then(() => {
      let newUsers = users.filter((u) => u.id != user.id);
      setUsers(newUsers);
    });
  };

  const toDisplayUserDetail = (user) => {
    setDisplayModalVisible(true);
    setUser(user);
  };

  const cancelDetailModal = () => {
    setDisplayModalVisible(false);
  };

  const cancelCreateModal = (userData) => {
    if (null != userData) {
      if (null != user.id) {
        const userIndex = users.findIndex((u) => u.id == userData.id);
        const newUsers = [...users];
        newUsers[userIndex] = { ...users[userIndex], ...userData };
        setUsers(newUsers);
        successMsg("User modifié avec succés");
      } else {
        setUsers([...users, userData]);
        successMsg("User ajouté avec succés");
      }
    }
    setUser(l__user);
    setUserModalVisible(false);
  };

  const editUser = (user) => {
    setUserModalVisible(true);

    setUser(user);
  };

  const onChange = (user) => {
    toDeactivateUser(user.id).then((res) => {
      const userIndex = users.findIndex((u) => u.id == user.id);
      const newUsers = [...users];
      user.active = !user.active;
      newUsers[userIndex] = { ...users[userIndex], ...user };
      setUsers(newUsers);
      successMsg("User modifié avec succés");
    });
  };

  const onGeneratePwd = (id) => {
    toRegeneratePwd(id).then((res) => {
      successMsg("Mot de passe a été regénéré");
    });
  };

  return (
    <>
      <Row>
        <Col span='12'>
          <Typography.Title level='2'>Utilisateurs</Typography.Title>
        </Col>
        <Col span='12' style={{ textAlign: "right" }}>
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={(e) => {
              setUser(l__user);
              setUserModalVisible(true);
            }}
          >
            Ajouter un utilisateur
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span='24'>
          <Tabs>
            <Tabs.TabPane tab='Liste des Utilisateurs actifs' key='1'>
              <table className='ant-table' style={{ width: "100%" }}>
                <thead className='ant-table-thead'>
                  <tr>
                    <th className='ant-table-cell'>Date création</th>
                    <th className='ant-table-cell'>Nom</th>
                    <th className='ant-table-cell'>Username</th>
                    <th className='ant-table-cell'>Téléphone</th>
                    <th className='ant-table-cell'>Rôle</th>
                    <th className='ant-table-cell'>Date de dernière connexion</th>
                    <th className='ant-table-cell'></th>
                    <th className='ant-table-cell'></th>
                  </tr>
                </thead>
                <Skeleton active loading={loading} />

                <tbody className='ant-table-tbody'>
                  {users &&
                    users
                      .filter((el) => el.active)
                      .map((user, i) => (
                        <tr key={i} className={i % 2 != 0 && "auto--background-grey"}>
                          <td className='ant-table-cell'>{moment(user.createdDate).format("D MMMM YYYY")}</td>
                          <td className='ant-table-cell'>{user.nom}</td>
                          <td className='ant-table-cell'>{user.username}</td>
                          <td className='ant-table-cell'>{user.phone}</td>
                          <td className='ant-table-cell'>{user.role}</td>
                          <td className='ant-table-cell'>{null == user.lastLogin ? "non connecté" : toConvertDateTime(user.lastLogin)}</td>
                          <td className='ant-table-cell'>
                            <Switch checked={user.active} onChange={(e) => onChange(user)} />
                          </td>
                          <td className='ant-table-cell'>
                            <Button type='link' icon={<FileTextOutlined />} onClick={(e) => toDisplayUserDetail(user)}></Button>

                            <Button icon={<BiPencil />} onClick={() => editUser(user)} />
                            <Popconfirm
                              title={`Voulez vous regénérer le mot de passe de ${user.nom}?`}
                              onConfirm={() => onGeneratePwd(user.id)}
                              okText='Oui'
                              cancelText='Non'
                            >
                              <Button icon={<GrPowerReset />} />
                            </Popconfirm>

                            {/* <Popconfirm
                        title={`Voulez vous vraiment supprimer ${user.nom}?`}
                        onConfirm={() => confirmDelete(user)}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <Button icon={<BiTrash />} />
                      </Popconfirm> */}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Liste des Utilisateurs archivés' key='2'>
              <table className='ant-table' style={{ width: "100%" }}>
                <thead className='ant-table-thead'>
                  <tr>
                    <th className='ant-table-cell'>Date création</th>
                    <th className='ant-table-cell'>Nom</th>
                    <th className='ant-table-cell'>Username</th>
                    <th className='ant-table-cell'>Téléphone</th>
                    <th className='ant-table-cell'>Rôle</th>
                    <th className='ant-table-cell'>Date de dernière connexion</th>
                    <th className='ant-table-cell'></th>
                    <th className='ant-table-cell'></th>
                  </tr>
                </thead>
                <Skeleton active loading={loading} />

                <tbody className='ant-table-tbody'>
                  {users &&
                    users
                      .filter((el) => !el.active)
                      .map((user, i) => (
                        <tr key={i} className={i % 2 != 0 && "auto--background-grey"}>
                          <td className='ant-table-cell'>{moment(user.createdDate).format("D MMMM YYYY")}</td>
                          <td className='ant-table-cell'>{user.nom}</td>
                          <td className='ant-table-cell'>{user.username}</td>
                          <td className='ant-table-cell'>{user.phone}</td>
                          <td className='ant-table-cell'>{user.role}</td>
                          <td className='ant-table-cell'>{null == user.lastLogin ? "non connecté" : toConvertDateTime(user.lastLogin)}</td>
                          <td className='ant-table-cell'>
                            <Switch checked={user.active} onChange={(e) => onChange(user)} />
                          </td>
                          <td className='ant-table-cell'>
                            <Button type='link' icon={<FileTextOutlined />} onClick={(e) => toDisplayUserDetail(user)}></Button>

                            <Button icon={<BiPencil />} onClick={() => editUser(user)} />
                            <Popconfirm
                              title={`Voulez vous regénérer le mot de passe de ${user.nom}?`}
                              onConfirm={() => onGeneratePwd(user.id)}
                              okText='Oui'
                              cancelText='Non'
                            >
                              <Button icon={<GrPowerReset />} />
                            </Popconfirm>

                            {/* <Popconfirm
                        title={`Voulez vous vraiment supprimer ${user.nom}?`}
                        onConfirm={() => confirmDelete(user)}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <Button icon={<BiTrash />} />
                      </Popconfirm> */}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      <Modal
        className='smtv-modal modal-large'
        title='User detail'
        visible={displayModalVisible}
        maskClosable={false}
        onCancel={(e) => cancelDetailModal()}
        footer={[
          <Button key='back' onClick={(e) => cancelDetailModal()}>
            Annuler
          </Button>,
        ]}
      >
        <Profile user={user} />
      </Modal>

      <UserModal cancelCreateModal={cancelCreateModal} userModalVisible={userModalVisible} user={user} />
    </>
  );
};

export default Users;
