import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
  Input
} from "antd";
import { useState, useEffect, useRef } from "react";
import { toGetProviderList, toDeleteProvider } from "../APIs";
import { PlusOutlined } from "@ant-design/icons";

import { BiPencil, BiTrash, BiHistory } from "react-icons/bi";
import moment from "moment";
import localization from "moment/locale/fr";
import "../../../Styles/TwoTables.scss";
import {
  InfoCircleTwoTone,
  FilterOutlined,
  CloseOutlined
} from "@ant-design/icons";

import Search from "../../../Utils/Search";
import ProviderModal from "./ProviderModal";
import Audit from "../../../Components/Audit";
import ls from "localstorage-ttl";
import ProviderDetail from "./ProviderDetail";
import { customFilter } from "../../../Utils/Utils";

moment.updateLocale("fr", localization);

const Providers = (props) => {
  const [loading, setLoading] = useState(true);
  const [toEditProvider, setToEdit] = useState(true);

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [detailFournisseurVisible, setDetailFournisseurVisible] = useState(
    false
  );
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [auditModalVisible, setAuditModalVisible] = useState(false);
  const [providers, setProviders] = useState([]);
  const [providersAll, setProvidersAll] = useState([]);
  const [providerSelected, setSelectedProvider] = useState({ name: "" });
  const [data, setData] = useState({ id: -1, tbl: "tb_provider" });
  const [searchValue, setSearchValue] = useState("");

  const fields = [
    { name: "Nom", field: "s__nom", type: "varchar" },
    { name: "Téléphone", field: "s__phone", type: "varchar" },
    { name: "Email", field: "s__email", type: "varchar" },
    { name: "Registre de commerce", field: "s__register", type: "varchar" },
    { name: "Matricule Fiscale", field: "s__matricule", type: "varchar" },
    { name: "Fax", field: "s__fax", type: "varchar" },
    { name: "Date de création", field: "createdDate", type: "date" }
  ];

  const toUpdateData = (values) => {
    setProviders(values);
    setFilterModalVisible(false);
  };

  const toOpenModal = (id) => {
    let l__data = data;
    l__data.id = id;
    setData(l__data);
    setAuditModalVisible(true);
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
      setProvidersAll(ls.get("providers"));
      setLoading(false);
    }
    toGetProviderList().then((res) => {
      setProviders(res);
      setProvidersAll(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteProvider(id).then((res) => {
      let newProviders = providers.filter((e) => e.id != id);
      setProviders(newProviders);
    });
  };

  const toEdit = (id, provider, toEdit) => {
    if (toEdit) {
      setDetailFournisseurVisible(true);
    } else {
      setCreateModalVisible(true);
    }
    setToEdit(toEdit);
    setSelectedProvider(provider);
  };
  const toAdd = () => {
    setToEdit(false);
    setSelectedProvider({
      id: -1,
      s__email: "",
      s__fax: "",
      s__matricule: "",
      s__nom: "",
      s__nomInCharge: "",
      s__phone: "",
      s__phoneInCharge: "",
      s__register: ""
    });
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const closeCreateModal = (provider) => {
    if (providerSelected.id == -1) {
      if (provider) {
        setProviders([provider, ...providersAll]);
        setProvidersAll([provider, ...providersAll]);
      }
      exitCreateModal();
    } else {
      const providerIndex = providers.findIndex((p) => p.id === provider.id);
      const newProviders = [...providersAll];
      newProviders[providerIndex] = { ...provider };
      setProviders(newProviders);
      setProvidersAll(newProviders);
      exitCreateModal();
    }
  };
  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const updatedList = customFilter(e.target.value, providersAll, [
      "s__email",
      "s__fax",
      "s__matricule",
      "s__nom",
      "s__phone",
      "s__register"
    ]);
    setProviders(updatedList);
  };
  return (
    <div id="achat">
      <>
        <Row className="auto--custom-header">
          <Col span="6">
            <Typography.Title level={3}>
              Liste des fournisseurs
            </Typography.Title>
          </Col>
          <Col span="6">
            <Input.Search
              onInput={onSearch}
              value={searchValue}
              placeholder="Recherche par nom, matricule, téléphone..."
              enterButton
            />
          </Col>
          <Col span="10" style={{ textAlign: "right" }}>
            <Button
              icon={<FilterOutlined />}
              onClick={(e) => setFilterModalVisible(true)}
              type="primary"
            >
              Filtrer
            </Button>
            <Button
              icon={<PlusOutlined />}
              style={{ marginLeft: "2px" }}
              onClick={(e) => toAdd()}
              type="primary"
            >
              Ajouter un Fournisseur
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            overflowY: "hidden"
          }}
        >
          <Col span="24">
            <div className="table-wrapper auto--overflow">
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Téléphone</th>
                    <th className="ant-table-cell">Registre de commerce</th>
                    <th className="ant-table-cell">Crée par</th>
                    <th className="ant-table-cell"></th>
                  </tr>
                </thead>
                <Skeleton active loading={loading} />

                <tbody className="ant-table-tbody ">
                  {null != providers &&
                    providers.length > 0 &&
                    providers.map((Provider, i) => (
                      <tr key={i}>
                        <td className="ant-table-cell">{Provider.s__nom}</td>
                        <td className="ant-table-cell">{Provider.s__phone}</td>
                        <td className="ant-table-cell">
                          {Provider.s__register}
                        </td>
                        <td className="ant-table-cell">
                          {Provider.l__createdBy}
                        </td>
                        <td
                          className="ant-table-cell"
                          style={{ float: "right" }}
                        >
                          <Button
                            icon={<InfoCircleTwoTone />}
                            onClick={() => toEdit(Provider.id, Provider, true)}
                          />
                          <Button
                            onClick={() => toEdit(Provider.id, Provider, false)}
                            icon={<BiPencil />}
                          />
                          <Button
                            icon={<BiHistory />}
                            onClick={() => toOpenModal(Provider.id)}
                          />
                          <Popconfirm
                            title={`Voulez vous vraiment supprimer ${Provider.s__nom}?`}
                            onConfirm={(e) => toDelete(Provider.id)}
                            okText="Oui"
                            cancelText="Non"
                          >
                            <Button
                              disabled={Provider.locked}
                              icon={<BiTrash />}
                            />
                          </Popconfirm>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Col>

          {/* <Col span="1" className="auto--icons-right">
            <div>
              <Button icon={<InfoCircleTwoTone />} />

              <Button icon={<BiPencil />} />

              <Button icon={<BiHistory />} />
              <Popconfirm okText="Oui" cancelText="Non">
                <Button icon={<BiTrash />} />
              </Popconfirm>
            </div>
          </Col> */}
        </Row>
      </>

      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={auditModalVisible}
        onCancel={(e) => setAuditModalVisible(false)}
      >
        <Audit data={data} />
      </Modal>

      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={providersAll}
        />
      </Modal>

      <ProviderDetail
        setDetailFournisseurVisible={setDetailFournisseurVisible}
        visible={detailFournisseurVisible}
        provider={providerSelected}
      ></ProviderDetail>

      <ProviderModal
        readonly={toEditProvider}
        providerForm={providerSelected}
        onProviderSaved={closeCreateModal}
        exitCreateModal={exitCreateModal}
        visible={createModalVisible}
      />
    </div>
  );
};

export default Providers;
