import { Menu, Row, Col, Button, Typography, Popconfirm, Form, DatePicker, Badge } from "antd";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../Actions/AuthActions";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import localization from "moment/locale/fr";
import Modal from "antd/lib/modal/Modal";
import Tecdoc from "../Utils/Tecdoc";
import { toGetListDelivery, toGetNewAuthorizations, toRemoveDelivery } from "../Pages/Users/APIs.js";
import { DeleteOutlined } from "@ant-design/icons";
import { toConvertDate } from "../Utils/Utils";
moment.updateLocale("fr", localization);

const { SubMenu } = Menu;

const Navigation = ({ AppStore, ...props }) => {
  const [current, setCurrent] = useState("Achat");
  const [selectedMenu, setSelectedMenu] = useState(null != localStorage.getItem("index") ? localStorage.getItem("index") : 0);
  const [date, setDate] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [time, setTime] = useState("");
  const [number, setNumber] = useState(0);
  const [listDelivery, setListDelivery] = useState([]);

  useEffect(() => {
    setInterval(() => {
      setTime(moment().format("LL HH:mm:ss"));
    }, 1000);

    setInterval(() => {
      toGetNewAuthorizations().then((res) => {
        setNumber(res);
      });
    }, 20000);

    getData(new Date());

    window.onkeydown = (evt) => {
      switch (evt.keyCode) {
        //ESC
        case 27:
          evt.preventDefault();
          console.log("esc");
          break;
        //F1
        case 112:
          setIsModalVisible(true);
          evt.preventDefault();
          console.log("f1");
          break;
        default:
          return;
      }
    };
  }, [AppStore.first]);
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const getData = (date) => {
    toGetListDelivery(moment(date).format("YYYY-MM-DD")).then(({ list, caisses }) => {
      setListDelivery(list);
    });
  };

  const setMode = (i) => {
    setSelectedMenu(i);
    AppStore.setFirst(i);
  };

  return (
    <div>
      <Row className='auto--background-grey' style={{ margin: 0 }}>
        <Col xl={3} md={3} xs={0}>
          <span style={{ marginLeft: "1vh", color: "#432c79" }}>
            <b>{cookie.load("nom")}</b>
          </span>
        </Col>
        <Col xl={16} md={16} xs={24}>
          <Menu
            onClick={handleClick}
            style={{
              textAlign: "right",
              marginRight: "25vh",
              backgroundColor: "#f0f0f0",
            }}
            selectedKeys={[current]}
            mode='horizontal'
            theme='light'
          >
            <Menu.Item
              danger={true}
              className={AppStore.first == 0 && "auto--menu-achat-background"}
              key='Achat'
              onClick={(e) => {
                setMode(0);
              }}
            >
              <Link to='/achat'> Achat</Link>
            </Menu.Item>
            <Menu.Item
              key='Vente'
              className={AppStore.first == 1 && "auto--menu-vente-background"}
              onClick={(e) => {
                setMode(1);
              }}
            >
              <Link to='/vente'>Vente</Link>
            </Menu.Item>
            <Menu.Item
              key='Objectfs'
              onClick={(e) => {
                setMode(2);
              }}
            >
              <Link to='/'>Objectifs & stats</Link>
            </Menu.Item>
            <SubMenu key='compte' title='Compte'>
              <Menu.Item
                key='profile'
                onClick={(e) => {
                  setMode(2);
                }}
              >
                <Link to='/compte/profile'>Mon profile</Link>
              </Menu.Item>
              {cookie.load("role") == "Super Admin" && (
                <SubMenu key='gestion' title='Gestion'>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='entities'
                  >
                    <Link to='/compte/entity'>entités</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='tva'
                  >
                    <Link to='/Gestions/tva'>TVA</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='emplacement'
                  >
                    <Link to='/Gestions/emplacement'>emplacement</Link>
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='category'
                  >
                    <Link to='/Gestions/category'>catégories</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='unite'
                  >
                    <Link to='/Gestions/unite'>unité</Link>
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='marque'
                  >
                    <Link to='/Gestions/marque'>marque</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key='avoir'
                  >
                    <Link to='/Gestions/avoir'>Facture Avoir</Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key='users'
                >
                  <Link to='/compte/users'>Utilisateurs</Link>
                </Menu.Item>
              )}
              {(cookie.load("id") === "8" || cookie.load("id") == "18" || cookie.load("id") == "1") && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key='employees'
                >
                  <Link to='/employees'>Gestion employees</Link>
                </Menu.Item>
              )}
              {/* {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key='demandes'
                >
                  <Link to='/demandes'>Demandes</Link>
                </Menu.Item>
              )} */}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                    setIsVisible(true);
                    getData(new Date());
                  }}
                  key='livraisons'
                >
                  <span>Livraisons</span>
                </Menu.Item>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key='Alertes'
                >
                  <Link to='/Alertes'>Alertes</Link>
                </Menu.Item>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key='parameters'
                >
                  <Link to='/Gestions/preference'>Préférences</Link>
                </Menu.Item>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key='corbeille'
                >
                  <Link to='/Gestions/corbeille'>Corbeille</Link>
                </Menu.Item>
              )}
              <Menu.Item onClick={props.logout} key='Logout'>
                Déconnexion
              </Menu.Item>
            </SubMenu>
            {cookie.load("role") == "Super Admin" && (
              <Menu.Item
                key='Notifications'
                className={AppStore.first == 3 && "auto--menu-vente-background"}
                onClick={(e) => {
                  setMode(3);
                }}
              >
                <Badge count={number}>
                  <Link to='/notifications/list'>Notifications</Link>
                </Badge>
              </Menu.Item>
            )}

            <Button
              type='link'
              onClick={() => setIsModalVisible(true)}
              icon={<img src='/images/detailArticle.svg' style={{ height: "90%" }} />}
            ></Button>
          </Menu>
        </Col>
        <Col xl={3} md={3} xs={0} style={{ textAlign: "right" }}>
          <span>
            <b>{time}</b>
          </span>
        </Col>
      </Row>

      <Modal title='La liste des livraisons' className='smtv-modal' width='60%' onCancel={() => setIsVisible(false)} visible={isVisible}>
        <Row>
          <Row className='auto--custom-header'>
            <Col span='24'>
              <Form.Item label='Date'>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, date) => {
                    getData(date);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <table className='ant-table' style={{ width: "100%" }}>
            <thead className='ant-table-thead'>
              <tr>
                <th className='ant-table-cell'>Date</th>
                <th className='ant-table-cell'>Référence BL/BR</th>
                <th className='ant-table-cell'>Chauffeur</th>
                <th className='ant-table-cell'>Crée par</th>
                <th className='ant-table-cell'></th>
              </tr>
            </thead>
            <tbody className='ant-table-tbody'>
              {listDelivery.map((el) => (
                <tr>
                  <td className='ant-table-cell'>{toConvertDate(el.createdDate)}</td>
                  <td className='ant-table-cell'>
                    {el.blId != -1 ? (
                      <Typography.Link level={5} style={{ cursor: "pointer" }}>
                        BL: {el.bl}
                      </Typography.Link>
                    ) : (
                      <Typography.Link level={5}>BR: {el.br}</Typography.Link>
                    )}
                  </td>
                  <td className='ant-table-cell'>
                    <b>{el.driver}</b>
                  </td>
                  <td className='ant-table-cell'>{el.createdBy}</td>
                  <td>
                    <Popconfirm
                      title={`Voulez vous vraiment supprimer cette livraison?`}
                      onConfirm={() =>
                        toRemoveDelivery(el.id).then((res) => {
                          getData(date);
                        })
                      }
                      okText='Oui'
                      cancelText='Non'
                    >
                      <Button style={{ marginRight: 10 }} icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </Modal>

      <Modal
        title='Liste des articles'
        visible={isModalVisible}
        className='smtv-modal'
        maskClosable={false}
        onCancel={(e) => setIsModalVisible(false)}
        footer={[]}
        width='180vh'
      >
        {isModalVisible && <Tecdoc displayTitle={true} addItem={null} reference={""} achat={false} />}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Navigation);
