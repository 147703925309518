import {
  Tabs,
  DatePicker,
  Descriptions,
  Typography,
  Select,
  Skeleton,
  Row,
  Col
} from "antd";
import { useEffect, useState } from "react";
import {
  toFetchAlertsStocks,
  toGetAlertsReglementClients,
  tofetchClients,
  tofetchClientsSeuil
} from "./Api";
import moment from "moment";
import { numberFormatCurrency, toConvertDate } from "../../Utils/Utils";
import DataTable from "react-data-table-component";
import BonLivraisonModal from "../Vente/BL/BonLivraisonModal";
import FactureModal from "../Vente/Factures/FactureModal";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Alerts = () => {
  const [clients, setClients] = useState([]);
  const [clientsSeuil, setClientsSeuil] = useState([]);
  const [blToInspect, setBlToInspect] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [regClients, setRegClients] = useState([]);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    toGetAlertsReglements(
      moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
      moment(new Date(), "YYYY-MM-DD")
    );
    toGetAlertsStock(
      moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
      moment(new Date(), "YYYY-MM-DD")
    );

    toGetAlertsClients(
      moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
      moment(new Date(), "YYYY-MM-DD")
    );
    tofetchClientsSeuil().then((res) => {
      setClientsSeuil(res);
    });
  }, []);
  const toGetAlertsClients = (start, end) => {
    tofetchClients(start, end).then((res) => {
      setClients(res);
      console.log("here",res)

    });
  };

  const toGetAlertsReglements = (start, end) => {
    setIsLoading(true);
    toGetAlertsReglementClients(start, end).then((res) => {
      setRegClients(res);
      setIsLoading(false);
    });
  };

  const toGetAlertsStock = (start, end) => {
    setIsLoading(true);

    toFetchAlertsStocks(start, end).then((res) => {
      setArticles(res);
      setIsLoading(false);
    });
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";

      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 6:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem"
      }
    }
  };

  const columnsStock = [
    {
      name: "Titre",
      selector: "s__secondTitle",
      grow: 2
    },
    {
      name: "Référence",
      selector: "s__reference"
    },
    {
      name: "Marque",
      selector: "marqueTitle"
    },
    {
      name: "Catégorie",
      selector: "categoryTitle",
      sortable: true
    },
    {
      name: "Prix de vente TTC",
      selector: "displayableName",
      sortable: true,
      format: (article) => `${article.l__prixVenteTTC.toFixed(3)} DT`
    }
  ];

  const columns = [
    {
      name: "Référence",
      selector: "s__ref",
      sortable: true,
      grow: 1
    },
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
      grow: 1
    },

    {
      name: "Facture/BL",
      selector: "ref",
      sortable: true,
      format: (bl) => (
        <>
          {bl.blId != -1 ? (
            <Typography.Link
              level={5}
              style={{ cursor: "pointer" }}
              onClick={() => setBlToInspect(bl.blId)}
            >
              {bl.ref}
            </Typography.Link>
          ) : (
            <Typography.Link
              level={5}
              style={{ cursor: "pointer" }}
              onClick={() => setFactureToInspect(bl.factureId)}
            >
              {bl.ref}
            </Typography.Link>
          )}
        </>
      ),
      grow: 1
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.5,

      format: (row) => `${toConvertDate(row.createdDate)}`
    },
    {
      name: "Type",
      selector: "ref",
      format: (bl) => getReglementType(bl)
    },

    {
      name: "Détails",
      selector: "s__ref",
      grow: 6,
      format: (reglement) => (
        <Descriptions
          column={5}
          bordered
          size="small"
          layout={
            reglement.type == 1 || reglement.type == 2
              ? "vertical"
              : "horizontal"
          }
        >
          {reglement.type == 1 || reglement.type == 2 ? (
            <>
              <Descriptions.Item label="Num" className="item-label">
                {reglement.data.num}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" className="item-label">
                {reglement.data.nom}
              </Descriptions.Item>
              <Descriptions.Item label="Date" className="item-label">
                {toConvertDate(reglement.data.date)}
              </Descriptions.Item>
              <Descriptions.Item label="Banque" className="item-label">
                {reglement.data.banque}
              </Descriptions.Item>
              <Descriptions.Item label="Montant" className="item-label">
                {reglement.data.montant}
              </Descriptions.Item>
            </>
          ) : (
            <Descriptions.Item label="Montant" className="item-label">
              {reglement.data.montant}
            </Descriptions.Item>
          )}
        </Descriptions>
      )
    }
  ];

  return (
    <>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Réglements Clients" key="1">
          <div style={{ textAlign: "center" }}>
            <Typography level={3}>Date:</Typography>
            <RangePicker
              onCalendarChange={(e, date) =>
                toGetAlertsReglements(date[0], date[1])
              }
              defaultValue={[
                moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
                moment(new Date(), "YYYY-MM-DD")
              ]}
              format={"YYYY-MM-DD"}
            />
          </div>

          <Skeleton loading={isLoading} />

          <DataTable
            style={{ marginTop: "1vh" }}
            customStyles={customStyles}
            columns={columns}
            data={regClients}
            pagination
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            persistTableHead
            noDataComponent={<span>Pas de réglements trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)"
            }}
          />
        </TabPane>
        <TabPane tab="Stock zéro" key="2">
          <div style={{ textAlign: "center" }}>
            <Typography level={3}>Date:</Typography>
            <RangePicker
              onCalendarChange={(e, date) => toGetAlertsStock(date[0], date[1])}
              defaultValue={[
                moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
                moment(new Date(), "YYYY-MM-DD")
              ]}
              format={"YYYY-MM-DD"}
            />
          </div>
          <Skeleton loading={isLoading} />

          <DataTable
            style={{ marginTop: "1vh" }}
            customStyles={customStyles}
            columns={columnsStock}
            data={articles}
            pagination
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            persistTableHead
            noDataComponent={<span>Pas d'articles trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)"
            }}
          />
        </TabPane>
        <TabPane tab="Clients qui n'ont pas acheté" key="3">
          <div style={{ textAlign: "center" }}>
            <Typography level={3}>Date:</Typography>
            <RangePicker
              onCalendarChange={(e, date) =>
                toGetAlertsClients(date[0], date[1])
              }
              defaultValue={[
                moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
                moment(new Date(), "YYYY-MM-DD")
              ]}
              format={"YYYY-MM-DD"}
            />
          </div>
          <Row>
            <Col span="24">
              <div className="table-wrapper auto--overflow">
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Date création</th>
                      <th className="ant-table-cell">Nom</th>
                      <th className="ant-table-cell">Date dernière vente</th>
                      <th className="ant-table-cell">Crédit</th>
                      <th className="ant-table-cell">Téléphone</th>
                      <th className="ant-table-cell"></th>
                    </tr>
                  </thead>

                  <tbody className="ant-table-tbody">
                    {clients &&
                      clients.map((client, i) => (
                        <tr
                          key={i}
                          style={{
                            backgroundColor: client.client.b__billPerMonth
                              ? "#69c0ff"
                              : "white"
                          }}
                        >
                          <td className="ant-table-cell">
                            {moment(client.client.created__date).format("D MMMM YYYY")}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ cursor: "pointer" }}
                            style={{ cursor: "pointer" }}
                          >
                            {client.client.s__name}
                          </td>
                          <td className="ant-table-cell">{client.bl ? toConvertDate(client.bl.createdDate) : "-"  }</td>
                          <td className="ant-table-cell">
                            {numberFormatCurrency(client.client.unpaied)}
                          </td>
                          <td className="ant-table-cell">
                            {client.client.s__phoneNumber }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Clients qui ont dépassé le seuil">
          <Row>
            <Col span="24">
            <div className="table-wrapper auto--overflow">
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Date création</th>
                      <th className="ant-table-cell">Nom</th>
                      <th className="ant-table-cell">Date dernière vente</th>
                      <th className="ant-table-cell">Crédit</th>
                      <th className="ant-table-cell">Téléphone</th>
                      <th className="ant-table-cell"></th>
                    </tr>
                  </thead>

                  <tbody className="ant-table-tbody">
                    {clients &&
                      clients.map((client, i) => (
                        <tr
                          key={i}
                          style={{
                            backgroundColor: client.client.b__billPerMonth
                              ? "#69c0ff"
                              : "white"
                          }}
                        >
                          <td className="ant-table-cell">
                            {moment(client.client.created__date).format("D MMMM YYYY")}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ cursor: "pointer" }}
                            style={{ cursor: "pointer" }}
                          >
                            {client.client.s__name}
                          </td>
                          <td className="ant-table-cell">{client.bl ? toConvertDate(client.bl.createdDate) : "-"  }</td>
                          <td className="ant-table-cell">
                            {numberFormatCurrency(client.client.unpaied)}
                          </td>
                          <td className="ant-table-cell">
                            {client.client.s__phoneNumber }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
             </Col>
          </Row>
        </TabPane>
      </Tabs>
      <BonLivraisonModal
        entete={true}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
      <FactureModal
        id={null != factureToInspect && factureToInspect}
        onClose={() => setFactureToInspect(null)}
      />
    </>
  );
};

export default Alerts;
