import { Col, Row, Typography } from "antd";
import React, { useEffect } from "react";

import "../../../Styles/TwoTables.scss";

import history from "../../../Utils/History";
import cookie from "react-cookies";

const PagePurshaseOrder = ({ boncommande, AppStore, ...props }) => {
  useEffect(() => {
    AppStore.setFirst(0);
    AppStore.setSecond(2);
  }, []);
  return (
    <>
      <Row className="auto--custom-header">
        <Col xl={12} md={12} xs={24}>
          <Typography.Title level={6}>BON DE RÉCEPTION</Typography.Title>
        </Col>
      </Row>
      <Row style={{ padding: "2vh", backgroundColor: "#fff" }}>
        <Col xl={4} md={4} xs={0}></Col>
        <Col
          xl={7}
          md={7}
          xs={24}
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/achat/reception/creer")}
        >
          <div
            className="auto--menu-achat-background"
            style={{ height: "40vh" }}
          >
            <img
              src="/images/plus.svg"
              style={{ height: "50%", marginTop: "10vh", marginLeft: "35%" }}
            />
            <br />
          </div>
        </Col>
        <Col xl={1} md={1} xs={0}></Col>
        <Col
          xl={7}
          md={7}
          xs={24}
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/achat/reception/list")}
        >
          <div className="auto--background-grey" style={{ height: "40vh" }}>
            <img
              src="/images/detail.svg"
              style={{ height: "50%", marginTop: "10vh", marginLeft: "35%" }}
            />
          </div>
        </Col>
      </Row>

      <Row
        style={{ padding: "2vh", backgroundColor: "#fff", fontSize: "1.5em" }}
      >
        <Col xl={4} md={4} xs={0}></Col>
        <Col
          xl={7}
          md={7}
          xs={24}
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => history.push("/achat/PurshaseOrders/creer")}
        >
          <span
            style={{
              color: "#00aeef",
              textTransform: "uppercase",
              textAlign: "center"
            }}
          >
            Créer un bon de réception
          </span>
        </Col>
        <Col xl={1} md={1} xs={0}></Col>
        <Col
          xl={7}
          md={7}
          xs={24}
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => history.push("/achat/PurshaseOrders/list")}
        >
          <span style={{ color: "#231f20", textTransform: "uppercase" }}>
            Liste des bon de réception
          </span>
        </Col>
      </Row>
    </>
  );
};

export default PagePurshaseOrder;
