import React, { useEffect, useRef, useState } from "react";
import Search from "../Utils/Search";
import { useRouteMatch, Switch } from "react-router-dom";
import PrivateRoute from "../Components/PrivateRoute";
import Clients from "../Pages/Vente/Clients/Clients";
import Emplacment from "./Gestions/Emplacment";
import Marque from "./Gestions/Marque";
import TVA from "./Gestions/TVA";
import Unite from "./Gestions/Unite";
import Category from "./Gestions/Category";
import Articles from "./Gestions/Articles";
import Dashboard from "./Dashboard";
import Quote from "./Vente/Quotes/Quote";
import BonLivraison from "./Vente/BL/BonLivraison";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/fr_FR";
import QuotesList from "./Vente/Quotes/QuotesList";
import Profile from "./Compte/Profile";
import Users from "./Compte/Users";
import Entity from "./Compte/Entity";
import ListeBonLivraison from "./Vente/BL/ListeBonLivraison";
import Providers from "../Pages/Achat/Fournisseurs/Providers";
import { connect } from "react-redux";
import { clearItems } from "../Actions/ItemsSelectionAtions";
import PurshaseOrders from "./Achat/PurshaseOrder/PurshaseOrders";
import PurshaseOrdersList from "./Achat/PurshaseOrder/PurshaseOrdersList";
import FacturesList from "./Vente/Factures/FacturesList";
import FacturesReceptionList from "./Achat/Factures/FacturesReceptionList";
import ListeBonReception from "./Achat/BonDeReception/ListeBonReception";
import PageBonReception from "./Achat/BonDeReception/PageBonReception";
import BonReception from "./Achat/BonDeReception/BonReception";
import Parameters from "./Users/Parameters";
import ReglementsListClient from "./Vente/Reglement/ReglementsListClient";
import ReglementsList from "./Achat/ReglementFournisseur/ReglementsList";
import Achat from "./Achat/Achat";
import Vente from "./Vente/Vente";
import PagePurshaseOrder from "./Achat/PurshaseOrder/PagePurshaseOrder";
import QuotePage from "./Vente/Quotes/QuotePage";
import BlPage from "./Vente/BL/BlPage";
import Objectif from "./Users/Objectifs";
import StatsUser from "./Users/StatsUser";

import ListeInventaires from "./Achat/Inventaire/ListeInventaires";
import Inventaire from "./Achat/Inventaire/Inventaire";
import InventairePage from "./Achat/Inventaire/InventairePage";

import ListeBonSortie from "./Achat/Inventaire/BonSortie/ListeBonSortie";
import BonSortie from "./Achat/Inventaire/BonSortie/BonSortie";
import Caisse from "./Users/Caisse";
import Corbeille from "./Vente/BL/Corbeille";
import BonEntree from "./Achat/Inventaire/BonEntree/BonEntree";
import ListeBonEntrees from "./Achat/Inventaire/BonEntree/ListeBonEntrees";
import CA from "./Users/CA";
import Alerts from "./Gestions/Alerts";
import FactureAvoir from "./Gestions/FactureAvoir";
import Notifications from "./Gestions/Notifications";
import DevisEnligne from "./Vente/DevisEnligne/DevisEnligne";
import ListEmployees from "./Employees/ListEmployees";
import FicheEmployee from "./Employees/FicheEmployee";
import ListeDemandes from "./Employees/ListDemandes";

const Admin = ({ AppStore, location, ...props }) => {
  const { path } = useRouteMatch();
  const oldLocation = useRef(null);

  useEffect(() => {
    if (
      (oldLocation.current && oldLocation.current.pathname === "/vente/devis/creer") ||
      (oldLocation.current && oldLocation.current.pathname === "/achat/reception/creer") ||
      (oldLocation.current && oldLocation.current.pathname === "/vente/bl/creer") ||
      (oldLocation.current && oldLocation.current.pathname === "/achat/reception/creer") ||
      (oldLocation.current && oldLocation.current.pathname === "/achat/PurshaseOrders/creer") ||
      (oldLocation.current && oldLocation.current.pathname === "/achat/PurshaseOrders/modifier") ||
      (oldLocation.current && oldLocation.current.pathname.includes("/achat/bl/modifier"))
    ) {
      props.clearITems();
    }
    // if (
    //   location.pathname === "/vente/devis/creer" ||
    //   location.pathname === "/achat/reception/creer" ||
    //   location.pathname === "/vente/bl/creer" ||
    //   location.pathname === "/achat/reception/creer" ||
    //   location.pathname === "/achat/PurshaseOrders/creer" ||
    //   location.pathname === "/achat/PurshaseOrders/modifier"
    // ) {
    //   props.clearITems();
    // }
    oldLocation.current = location;
  }, [location]);

  return (
    <Switch>
      <ConfigProvider locale={locale}>
        <PrivateRoute exact path={path}>
          <Dashboard AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={"/search"}>
          <Search AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={"/achat"}>
          <Achat AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={"/vente"}>
          <Vente AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}vente/devis/creer`} onLeave={props.clearItems}>
          <Quote AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}vente/devis/modifier/:id`} onLeave={props.clearItems}>
          <Quote AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}vente/bl/creer`} onLeave={props.clearItems}>
          <BonLivraison AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}vente/reglements`}>
          <ReglementsListClient AppStore={AppStore} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}vente/bl/list`}>
          <ListeBonLivraison AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}vente/bl`}>
          <BlPage AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}vente/bl/modifier/:id`} onLeave={props.clearItems}>
          <BonLivraison AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}vente/factures`}>
          <FacturesList AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}vente/devis/list`}>
          <QuotesList AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}vente/devisenligne`}>
          <DevisEnligne AppStore={AppStore} />
        </PrivateRoute>

        <PrivateRoute exact path={`${path}vente/devis`}>
          <QuotePage AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}clients`}>
          <Clients AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}providers`}>
          <Providers AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/emplacement`}>
          <Emplacment display={true} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/marque`}>
          <Marque AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/avoir`}>
          <FactureAvoir AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Alertes`}>
          <Alerts AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/TVA`}>
          <TVA AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/unite`}>
          <Unite AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/category`}>
          <Category AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}produits`}>
          <Articles AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/preference`}>
          <Parameters AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}Gestions/corbeille`}>
          <Corbeille AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}achat/factures`}>
          <FacturesReceptionList AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}compte/profile`}>
          <Profile user={null} AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}compte/entity`}>
          <Entity AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}compte/users`}>
          <Users AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}employees`}>
          <ListEmployees AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}ficheEmployee`} onLeave={props.clearItems}>
          <FicheEmployee AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}demandes`} onLeave={props.clearItems}>
          <ListeDemandes AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}achat/PurshaseOrders/creer`} onLeave={props.clearItems}>
          <PurshaseOrders AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}achat/purshaseOrder/modifier/:id`} onLeave={props.clearItems}>
          <PurshaseOrders AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/PurshaseOrders/list`}>
          <PurshaseOrdersList AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/PurshaseOrders`}>
          <PagePurshaseOrder AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}achat/reception/creer`} onLeave={props.clearItems}>
          <BonReception AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}achat/reception/modifier/:id`} onLeave={props.clearItems}>
          <BonReception AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute path={`${path}achat/reglements`}>
          <ReglementsList AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/reception/list`}>
          <ListeBonReception AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/reception`}>
          <PageBonReception AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}objectif`}>
          <Objectif AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/inventory/list`}>
          <ListeInventaires AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/inventory/creer`}>
          <Inventaire AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/inventory`}>
          <InventairePage AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/be/list`}>
          <ListeBonEntrees AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/be/creer`}>
          <BonEntree AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/bs/list`}>
          <ListeBonSortie AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}achat/bs/creer`}>
          <BonSortie AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}caisse`}>
          <Caisse AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}stats`}>
          <StatsUser AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}ca`}>
          <CA AppStore={AppStore} />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}notifications/list`}>
          <Notifications AppStore={AppStore} />
        </PrivateRoute>
      </ConfigProvider>
    </Switch>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearITems: () => dispatch(clearItems()),
  };
};

export default connect(null, mapDispatchToProps)(Admin);
