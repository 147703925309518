import {
  Button,
  Modal,
  Popconfirm,
  Row,
  Col,
  Typography,
  Input,
  Form,
  Select
} from "antd";
import { useEffect, useState } from "react";

import {
  toGetInventories,
  toDeleteInventory,
  toSynchronizeInventory
} from "../APIs";
import {
  PlusOutlined,
  PrinterOutlined,
  EditOutlined,
  InfoCircleTwoTone,
  SyncOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { customFilter, successMsg, toConvertDate } from "../../../Utils/Utils";
import { connect } from "react-redux";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import Search from "../../../Utils/Search";
import DataTable from "react-data-table-component";

import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import InventoryModal from "./InventoryModal";
import history from "../../../Utils/History";

const { Option } = Select;
const fields = [
  { name: "Référence", field: "ref", type: "varchar" },
  { name: "Total", field: "total", type: "number" },
  { name: "Fournisseur", field: "providerName", type: "varchar" },
  { name: "Remise", field: "discount", type: "number" },
  { name: "Nombre de produits", field: "numberArticle", type: "number" },
  { name: "Facture", field: "facture", type: "boolean" },
  { name: "Payé", field: "paied", type: "boolean" },
  { name: "Date de création", field: "createdDate", type: "date" }
];

const ListeInventaire = ({ AppStore, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [orders, setInventories] = useState([]);
  const [ordersAll, setInventoriesAll] = useState([]);
  const [brToInspect, setBrToInspect] = useState(null);

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const confirmDelete = (id) => {
    toDeleteInventory(id).then(() => {
      actualise();
    });
  };

  useEffect(() => {
    AppStore.setFirst(0);
    AppStore.setSecond(13);
    if (AppStore.entityId == -1) {
      history.push("/achat/inventory");
    }
    setLoading(true);

    actualise();
  }, []);

  const toSynchronize = (order, article) => {
    Modal.destroyAll();

    toSynchronizeInventory(order, AppStore.entityId, article).then((res) => {
      actualise();
      successMsg("Synchronisation effectuée avec succès");
    });
  };
  const openModal = (order) => {
    Modal.warning({
      okButtonProps: { style: { display: "none" } },

      content: (
        <div>
          <p>Voulez-vous faire l'inventaire par article ou par emplacement?</p>
          <div style={{ textAlign: "center", marginTop: "2vh" }}>
            <Button type="primary" onClick={() => toSynchronize(order, false)}>
              Emplacement
            </Button>
            <Button
              style={{ marginLeft: "2vh" }}
              warning
              onClick={() => toSynchronize(order, true)}
            >
              Article
            </Button>
          </div>
        </div>
      )
    });
  };

  const actualise = () => {
    setShow(true);
    toGetInventories(AppStore.entityId).then((data) => {
      setInventories(data);
      setInventoriesAll(data);
      setLoading(false);

      setShow(false);
    });
  };

  const toUpdateData = (values) => {
    setInventories(values);
    setSearchValue("");
    setFilterModalVisible(false);
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const updatedList = customFilter(e.target.value, ordersAll, [
      "username",
      "ref",
      "emplacement"
    ]);
    setInventories(updatedList);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem"
      }
    }
  };

  const columns = [
    {
      name: "Référence",
      selector: "ref",
      sortable: true,
      format: (br) => (
        <span style={{ cursor: "pointer" }} onClick={() => setBrToInspect(br)}>
          {br.ref}
        </span>
      )
    },
    {
      name: "Utilisateur",
      selector: "username",
      sortable: true
    },

    {
      name: "Emplacement",
      selector: "emplacement",
      sortable: true
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      format: (row) => `${toConvertDate(row.date)}`
    },

    {
      name: "Actions",
      selector: "id",

      grow: 0.75,

      format: (order) => (
        <td className="ant-table-cell" style={{ float: "right" }}>
          <Button
            icon={<InfoCircleTwoTone />}
            style={{ marginRight: 1 }}
            onClick={() => setBrToInspect(order)}
          ></Button>
          {!order.valid && (
            <Button
              icon={<SyncOutlined />}
              style={{ marginRight: 1 }}
              onClick={() => openModal(order)}
            ></Button>
          )}

          <Popconfirm
            title={`Voulez vous vraiment supprimer cet inventaire?`}
            onConfirm={() => confirmDelete(order.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button style={{ marginRight: 10 }} icon={<DeleteOutlined />} />
          </Popconfirm>
        </td>
      )
    }
  ];

  return (
    <div id="achat">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>LISTE DES INVENTAIRES</Typography.Title>
          </Col>
          <Col span="14"></Col>
          <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Input.Search
              onInput={onSearch}
              value={searchValue}
              placeholder="Recherche par référence, client, total..."
              enterButton
            />
          </Col>
          <Col span="2" style={{ textAlign: "right" }}>
            <Link key="20" to="/achat/inventory/creer">
              <Button
                type="link"
                size="large"
                icon={<img src="/images/plusVente.svg" />}
              ></Button>
            </Link>
          </Col>
        </Row>
      </Form>{" "}
      <Row>
        <Col span="24">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={orders}
            pagination
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            //onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de bon d'inventaire trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)"
            }}
          />
        </Col>
      </Row>
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search toUpdateData={toUpdateData} fields={fields} list={ordersAll} />
      </Modal>
      <InventoryModal
        inventory={brToInspect}
        onClose={(e) => setBrToInspect(null)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBRToUpdate: (PurshaseOrder) => dispatch(setItemsState(PurshaseOrder))
  };
};

export default connect(null, mapDispatchToProps)(ListeInventaire);
