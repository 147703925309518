import { makeAutoObservable, observable, toJS } from "mobx";

class AppStore {
  entityId = -1;
  first = -1;
  second = -1;
  entityName = "";
  reload = false;
  constructor() {
    makeAutoObservable(this, {
      second: observable,
      first: observable,
      entityId: observable,
      entityName: observable,
      reload: observable,
    });
  }

  setFirst(first) {
    this.first = first;
  }
  setSecond(second) {
    this.second = second;
  }

  setReload(reload) {
    this.reload = reload;
  }

  setEntityId(entityId) {
    this.entityId = entityId;
  }

  setEntityName(name) {
    this.entityName = name;
  }
}

export default new AppStore();
